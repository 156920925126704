import axios from "axios";
import { core } from "../app/services";

interface IgetUploadURL {
  contentType: string;
  ext: string | undefined;
  isPublic: boolean;
}

const uploadFile = async (file: File) => {
  const { name, type } = file;
  const payload: IgetUploadURL = {
    contentType: type,
    ext: name.split(".").pop(),
    isPublic: true,
  };

  try {
    // get signed url to upload
    const response = await core.getUploadURL(payload);

    try {
      // upload the file
      await axios.put(response.uploadURL, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
      return response.Key;
    } catch (error) {
      throw new Error("error al subir imagen");
    }
  } catch (error) {
    throw new Error("Error al obtener firma de s3");
  }
};

const getFileURL = async (fileName: string) => {
  try {
    const response = await core.getFileURL(fileName);
    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

const s3 = {
  uploadFile,
  getFileURL,
};

export default s3;
