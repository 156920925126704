import React from "react";
import { Container, Row, Col, FormControl, InputGroup } from "react-bootstrap";

// redux
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "../../redux/app";

// services
import { core } from "../../services";

// components
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import ModalLoadingState from "../../components/ModalLoadingState";

type T_ticketStadistics = {
  total: string;
  totalRechazado: string;
  totalPendiente: string;
  totalValidada: string;
  totalImporte: string;
  totalImporteRechazado: string;
  totalImportePendiente: string;
  totalImporteValidada: string;
};

const ShoopingRewards = () => {
  // states
  const [stadistic, setStadistic] = React.useState<T_ticketStadistics>({
    total: "",
    totalRechazado: "",
    totalPendiente: "",
    totalValidada: "",
    totalImporte: "",
    totalImporteRechazado: "",
    totalImportePendiente: "",
    totalImporteValidada: "",
  });

  // hooks
  const dispatch = useDispatch();

  // functions

  // fetch all tickets
  React.useEffect(() => {
    const fetchTicketStadistics = async () => {
      try {
        dispatch(startLoading());
        const response: T_ticketStadistics =
          await core.tickets.getTicketStadistics();
        setStadistic(response);
      } catch (error) {
        setStadistic({
          total: "",
          totalRechazado: "",
          totalPendiente: "",
          totalValidada: "",
          totalImporte: "",
          totalImporteRechazado: "",
          totalImportePendiente: "",
          totalImporteValidada: "",
        });
      } finally {
        dispatch(endLoading());
      }
    };

    fetchTicketStadistics();
  }, []);

  return (
    <>
      <ModalLoadingState />
      <div
        className="d-flex flex-column flex-center bgi-no-repeat bgi-size-cover bgi-position-center rounded-top px-9 pt-20 pb-10"
        style={{
          backgroundImage: `url('${toAbsoluteUrl(
            "/media/misc/pattern-1.jpg"
          )}')`,
        }}
      >
        <h1
          className="text-white fw-bold mb-3"
          style={{
            fontSize: "3.5rem",
          }}
        >
          Estadisticas
        </h1>
      </div>

      <Container className="pt-10">
        <h3>Tickets</h3>

        <Row>
          <Col xs={6}>
            <InputGroup>
              <InputGroup.Text>Total tickets</InputGroup.Text>
              <FormControl value={stadistic.total} disabled />
            </InputGroup>
          </Col>
          <Col xs={6}>
            <InputGroup>
              <InputGroup.Text>Total tickets rechazados</InputGroup.Text>
              <FormControl value={stadistic.totalRechazado} disabled />
            </InputGroup>
          </Col>
          <Col xs={6}>
            <InputGroup>
              <InputGroup.Text>Total tickets validados</InputGroup.Text>
              <FormControl value={stadistic.totalValidada} disabled />
            </InputGroup>
          </Col>
          <Col xs={6}>
            <InputGroup>
              <InputGroup.Text>Total tickets pendientes</InputGroup.Text>
              <FormControl value={stadistic.totalPendiente} disabled />
            </InputGroup>
          </Col>

          <Col xs={6}>
            <InputGroup>
              <InputGroup.Text>Total importe tickets validados</InputGroup.Text>
              <FormControl value={stadistic.totalImporteValidada} disabled />
            </InputGroup>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ShoopingRewards;
