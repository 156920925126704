import React from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  postConcepto,
  fetchGetConceptos,
  putConceptoDePago,
} from "../../../redux/payments/conceptoDePago";

import { Modal, Button } from "react-bootstrap";

// util
import swal from "sweetalert";

// components
import ConceptoDePagoForm from "../forms/ConceptoDePago";

interface Iprops {
  isOpen: boolean;
  typeCreateEdit: string;
  handleClose: () => void;
}

const CreateEditConceptoDePago: React.FC<Iprops> = ({
  isOpen,
  typeCreateEdit,
  handleClose,
}) => {
  const [nombre, setNombre] = React.useState("");
  const [descripcion, setDescripcion] = React.useState("");
  const [slug, setSlug] = React.useState("");

  const dispatch = useDispatch();
  const { selectedConcept } = useSelector((state: any) => state.conceptoDePago);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case "nombre":
        setNombre(value);
        // lower case no space slug
        setSlug(value.toLowerCase().replace(/\s/g, "_"));
        break;
      case "descripcion":
        setDescripcion(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    switch (typeCreateEdit) {
      case "Crear":
        dispatch(postConcepto({ nombre, descripcion, slug }));
        swal("¡Listo!", "Concepto de pago creado con éxito.", "success").then(
          () => {
            handleClose();
            dispatch(fetchGetConceptos());
          }
        );
        break;
      case "Editar":
        dispatch(
          putConceptoDePago({
            idConcepto: selectedConcept.id,
            nombre,
            descripcion,
            slug,
          })
        );
        swal("¡Listo!", "Concepto de pago editado con éxito.", "success").then(
          () => {
            dispatch(fetchGetConceptos());
            handleClose();
          }
        );
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    if (typeCreateEdit === "Crear") {
      setNombre("");
      setDescripcion("");
      setSlug("");
    }
    if (typeCreateEdit === "Editar") {
      if (selectedConcept) {
        setNombre(selectedConcept.nombre);
        setDescripcion(selectedConcept.descripcion);
        setSlug(selectedConcept.slug);
      }
    }
  }, [selectedConcept, typeCreateEdit]);

  return (
    <Modal show={isOpen} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Concepto de Pago</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ConceptoDePagoForm
          data={{
            nombre,
            descripcion,
            slug,
          }}
          onChange={handleChange}
          typeCreateEdit={typeCreateEdit}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateEditConceptoDePago;
