/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useIntl } from "react-intl";
import { KTSVG } from "../../../helpers";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuMain() {
  const intl = useIntl();

  return (
    <>
      <AsideMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/art/art002.svg"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      />

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Aplicaciones
          </span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to="/app/reservas"
        title="Reservas"
        icon="/media/icons/duotune/general/gen025.svg"
      >
        <AsideMenuItem
          to="/app/reservas/main"
          title="Main"
          fontIcon="bi-layers"
          hasBullet
        />
        <AsideMenuItem
          to="/app/reservas/hotel"
          title="Hotel"
          fontIcon="bi-layers"
          hasBullet
        />
        <AsideMenuItem
          to="/app/reservas/crucero"
          title="Crucero"
          fontIcon="bi-layers"
          hasBullet
        />
        <AsideMenuItem
          to="/app/reservas/travel-experience"
          title="Travel experience"
          fontIcon="bi-layers"
          hasBullet
        />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        icon="/media/icons/duotune/ecommerce/ecm004.svg"
        to="/lhcshop"
        title="LHC Shop"
      />

      <AsideMenuItemWithSub
        to="/option"
        title="Shopping Rewards"
        icon="/media/icons/duotune/coding/cod001.svg"
      >
        <AsideMenuItem
          to="/option/shoppingRewards/paises"
          title={"Paises"}
          hasBullet
        />
        <AsideMenuItem
          to="/option/shoopingRewards/marcas"
          title={"Marcas"}
          hasBullet
        />
        <AsideMenuItem
          to="/option/shoopingRewards/tickets"
          title={"Tickets"}
          hasBullet
        />
        <AsideMenuItem
          to="/option/shoppingRewards"
          title={"Estadisticas"}
          hasBullet
        />
      </AsideMenuItemWithSub>

      <div className="menu-item">
        <div className="menu-content">
          <div className="separator mx-1 my-4"></div>
        </div>
      </div>
      <AsideMenuItemWithSub
        to="/billing"
        icon="/media/icons/duotune/finance/fin010.svg"
        title="Transacciones"
      >
        <AsideMenuItem
          to="/billing"
          icon="/media/icons/duotune/finance/fin008.svg"
          title={"Transacciones"}
          fontIcon="bi-app-indicator"
        />
        <AsideMenuItem
          to="/billing/pagos"
          icon="/media/icons/duotune/files/fil004.svg"
          title={"Registro de pagos"}
          fontIcon="bi-app-indicator"
        />
      </AsideMenuItemWithSub>
      {/* ACADEMY */}
      <AsideMenuItemWithSub
        to="/academy"
        title="Academia LHC"
        icon="/media/icons/duotune/ecommerce/ecm004.svg"
      >
        <AsideMenuItem
          to="/academy"
          title={"Modulos"}
          fontIcon="bi-app-indicator"
          hasBullet
        />
      </AsideMenuItemWithSub>

      <div className="menu-item">
        <div className="menu-content">
          <div className="separator mx-1 my-4"></div>
        </div>
      </div>

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Configuraciones
          </span>
        </div>
      </div>

      {/* OPTIONS */}
      <AsideMenuItemWithSub
        to="/option"
        title="Configuración"
        icon="/media/icons/duotune/coding/cod001.svg"
      >
        <AsideMenuItem
          to="/option"
          title={"Datos"}
          fontIcon="bi-app-indicator"
          hasBullet
        />
        <AsideMenuItem
          to="/option/addPoints"
          title={"Agregar puntos"}
          fontIcon="bi-app-indicator"
          hasBullet
        />
        <AsideMenuItem
          to="/option/payment-concept"
          title={"Conceptos de pagos"}
          fontIcon="bi-app-indicator"
          hasBullet
        />
        <AsideMenuItem
          to="/option/categories"
          title={"Categorias"}
          fontIcon="bi-app-indicator"
          hasBullet
        />
      </AsideMenuItemWithSub>

      {/* PUSH NOTIFICATION */}
      <AsideMenuItemWithSub
        to="/push-notification"
        title="Push notification"
        icon="/media/icons/duotune/coding/cod001.svg"
      >
        <AsideMenuItem
          to="/push-notification/user-list"
          title={"Lista de usuarios registrados"}
          fontIcon="bi-app-indicator"
          hasBullet
        />
        {/* <AsideMenuItem
          to="/option/push-notification"
          title={"Crear topic"}
          fontIcon="bi-app-indicator"
          hasBullet
        /> */}
        <AsideMenuItem
          to="/push-notification/send"
          title={"Enviar Push notification"}
          fontIcon="bi-app-indicator"
          hasBullet
        />
        <AsideMenuItem
          to="/push-notification/logs"
          title={"Historial"}
          fontIcon="bi-app-indicator"
          hasBullet
        />
      </AsideMenuItemWithSub>
    </>
  );
}
