import React from "react";

// redux
import { useSelector, useDispatch } from "react-redux";
import { fetchBillings } from "../../redux/payments/billing";

import { KTCard, KTCardBody } from "../../../_metronic/helpers";

// utils
import * as fns from "date-fns";

// components
import BillingLog from "../../module/billing/components/modal/BillingLog";
import BillingTable from "../../module/billing/components/table/BillingTable";

const Billing = () => {
  // states
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState([]);

  // inits
  const dispatch = useDispatch();
  const { billings } = useSelector((state: any) => state.billing);

  const data: Array<any> = React.useMemo(() => {
    // get all billing item names unique
    const billingItemNamesUnique = [
      ...new Set(billings.map((billing: any) => billing.item_name)),
    ];

    // save the billing by item name
    const listOfBillings: Array<any> = [];
    billingItemNamesUnique.forEach((itemName: any) => {
      const record: Array<any> = billings.filter(
        (billing: any) => billing.item_name === itemName
      );

      // order by date
      const orderedRecord = record.sort((a: any, b: any) => {
        return fns.compareDesc(new Date(a.Created), new Date(b.Created));
      });

      listOfBillings.push({
        itemName: itemName,
        total: record.length,
        record: orderedRecord,
      });
    });

    return listOfBillings;
  }, [billings]);

  // functions
  const handleOpen = (item: any) => {
    setOpen(true);
    setSelected(item);
  };
  const handleClose = () => {
    setOpen(false);
    setSelected([]);
  };

  React.useEffect(() => {
    dispatch(fetchBillings());
  }, []);

  return (
    <>
      <BillingLog isOpen={open} handleClose={handleClose} data={selected} />

      <h1>Transacciones</h1>

      <KTCard>
        <KTCardBody>
          <BillingTable data={data.reverse()} handleSelectRow={handleOpen} />
        </KTCardBody>
      </KTCard>
    </>
  );
};

export default Billing;
