import React from "react";

const LHCSHOP = () => {
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <iframe
        title="shop"
        src="https://lhcshops.com/wp-admin"
        style={{
          width: "100%",
          height: "100vh",
          background: "#eee",
        }}
      />
    </div>
  );
};
export default LHCSHOP;
