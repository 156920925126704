import { createSlice } from "@reduxjs/toolkit";
import { booking, core } from "../services";
import * as fns from "date-fns";

export const FETCH_METHOD = {
  API: "API",
  DB: "DB",
};

const initialState = {
  loading: false,
  db: {
    amountOfBooking: 0,
    amountOfBookingHotel: 0,
    amountOfBookingFlight: 0,
    amountOfBookingTravelExperiences: 0,
    amountOfBookingCrusiers: 0,
  },
  api: {
    amountOfBooking: 0,
    amountOfBookingHotel: 0,
    amountOfBookingFlight: 0,
    amountOfBookingTravelExperiences: 0,
    amountOfBookingCrusiers: 0,
  },
  maxAmountPuntosAcumulados: 125,
  lastDataBaseUpdate: "",
  fetchMethod: FETCH_METHOD.DB,
};

const getAmountOfBooking = (bookings: any, type: string) => {
  const fillteredBookings = bookings.filter(
    (booking: any) => booking.data.datosReserva.tipoReserva === type
  );
  return fillteredBookings.length;
};

const optionsSlice = createSlice({
  name: "options",
  initialState,
  reducers: {
    start: (state) => {
      state.loading = true;
    },
    success: (state, action) => {
      const { lastDataBaseUpdate, db, api } = action.payload;

      state.db = db;
      state.api = api;
      state.lastDataBaseUpdate = lastDataBaseUpdate;
      state.loading = false;
    },
    end: (state) => {
      state.loading = false;
    },
    changeMaxAmountPuntosAcumulados: (state, action) => {
      state.maxAmountPuntosAcumulados = action.payload;
    },
    changeFetchMethod: (state, action) => {
      state.fetchMethod = action.payload;
    },
  },
});

export const {
  start,
  success,
  end,
  changeFetchMethod,
  changeMaxAmountPuntosAcumulados,
} = optionsSlice.actions;
export default optionsSlice.reducer;

export const setBookingData: any = (method: string) => {
  return async (dispatch: any) => {
    dispatch(start());
    try {
      const DB: Array<any> = await booking.getBookingDB("", "", "", "", "");
      const API: Array<any> = await booking.getBookingAPI("", "", "", "", "");
      const lastUpdateDB = await core.getLastDBUpdate();

      const { logTime, actionType, bookingAmount } = lastUpdateDB;
      const logTimeDate = new Date(logTime);
      const logTimeString = fns.format(logTimeDate, "dd/MM/yyyy HH:mm:ss");
      const lastDataBaseUpdate = `${logTimeString} - ${actionType} - ${bookingAmount}`;

      // DB
      const db = {
        amountOfBooking: DB.length,
        amountOfBookingHotel: getAmountOfBooking(DB, "Hotel"),
        amountOfBookingFlight: getAmountOfBooking(DB, "Vol"),
        amountOfBookingTravelExperiences: getAmountOfBooking(
          DB,
          "Travel Experiences"
        ),
        amountOfBookingCrusiers: getAmountOfBooking(DB, "Crucero"),
      };

      // API
      const api = {
        amountOfBooking: API.length,
        amountOfBookingHotel: getAmountOfBooking(API, "Hotel"),
        amountOfBookingFlight: getAmountOfBooking(API, "Vol"),
        amountOfBookingTravelExperiences: getAmountOfBooking(
          API,
          "Travel Experiences"
        ),
        amountOfBookingCrusiers: getAmountOfBooking(API, "Crucero"),
      };

      const data = {
        db,
        api,
        lastDataBaseUpdate,
      };

      dispatch(success(data));
    } catch (error) {
      dispatch(end());
      console.log(error);
      throw new Error("Error al obtener los datos de la base de datos");
    }
  };
};
