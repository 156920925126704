import React from "react";

// react-bootstrap
import {
  InputGroup,
  Form,
  DropdownButton,
  Dropdown,
  Button,
} from "react-bootstrap";

// interface
import { ITypeUser, IUserFind, IUserData } from "./components/interface";

// redux
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "../../../../redux/app";

// services
import { core } from "../../../../services";

// util
import swal from "sweetalert";

// components
import UserDataModal from "./components/UserDataModal";
import ModalLoadingState from "../../../../components/ModalLoadingState";

const typeArray: Array<ITypeUser> = ["id", "email"];

const FindClient = () => {
  // states
  const [findUser, setFindUser] = React.useState<IUserFind>({
    type: "id",
    value: "",
  });
  const [userData, setUserData] = React.useState<IUserData>();
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  // hooks
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleGetUserData = async () => {
    try {
      dispatch(startLoading());
      const response = await core.getCliente(findUser);
      setUserData(response);
      handleOpenModal();
    } catch (error: any) {
      swal("Error", "Hubo un detalle al buscar el usuario", "error");
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <>
      <ModalLoadingState />
      <UserDataModal
        data={userData}
        open={openModal}
        closeModal={handleCloseModal}
      />
      <div className="pt-10">
        <h3>Buscar Usuario</h3>
        <div className="separator border-3 mb-10"></div>
        <InputGroup>
          <InputGroup.Text>ID/Correo</InputGroup.Text>
          <DropdownButton
            variant="secondary"
            title={findUser.type}
            id="input-group-dropdown-1"
          >
            {typeArray.map((item: ITypeUser) => (
              <Dropdown.Item
                key={item}
                onClick={() => {
                  setFindUser({
                    ...findUser,
                    type: item,
                  });
                }}
              >
                {item}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <Form.Control
            value={findUser.value}
            onChange={(e) => {
              setFindUser({
                ...findUser,
                value: e.target.value,
              });
            }}
          />
          <Button disabled={findUser.value === ""} onClick={handleGetUserData}>
            Adquirir datos del usuario
          </Button>
        </InputGroup>
      </div>
    </>
  );
};

export default FindClient;
