import React from "react";

import { Button } from "react-bootstrap";

// react table
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import { RegistroDePagoColumns } from "./columns/_columns";
import DefaultColumnFilter from "./columns/DefaultColumnFilter";
import LoadingState from "../../../../components/LoadingState";
import TablePagination from "../../../../components/table/TablePagination";

// utils
import * as fns from "date-fns";

// components
import ReadRegistroDePago from "../modal/ReadRegistroDePago";

interface Iprops {
  tableData: any;
  isLoading: boolean;
}

const RegistroDePagosTable: React.FC<Iprops> = ({ tableData, isLoading }) => {
  // states
  const [isOpenRead, setIsOpenRead] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});

  // functions
  const handleOpenRead = (data: any) => {
    console.log(data);
    setIsOpenRead(true);
    setSelectedRow(data);
  };

  const handleCloseRead = () => {
    setIsOpenRead(false);
    setSelectedRow({});
  };

  // table hooks
  const columns = React.useMemo(() => RegistroDePagoColumns, []);
  const defaultColumn: any = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const tablehooks = (hooks: any) => {
    hooks.visibleColumns.push((columns: any) => [
      ...columns,
      {
        id: "actions",
        Header: "Acciones",
        Cell: ({ row }: any) => (
          <Button
            variant="success"
            onClick={() => handleOpenRead(row.original)}
          >
            Ver
          </Button>
        ),
      },
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // handlers
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: tableData,
      defaultColumn,
      initialState: { pageIndex: 0 },
    },

    tablehooks,
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <>
      <ReadRegistroDePago
        isOpen={isOpenRead}
        onClose={handleCloseRead}
        data={selectedRow}
      />
      <div className="table-responsive">
        <table
          className="table table-hover table-sm align-middle"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <tr
                className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column: any) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
            {page.length > 0 && isLoading === false ? (
              page.map((row: any, i) => {
                prepareRow(row);

                const currentDate = new Date();
                const fechaLimitePagoDate = new Date(
                  row.original.fechaLimitePago
                );
                const amountOfDays = fns.differenceInDays(
                  fechaLimitePagoDate,
                  currentDate
                );

                const warning = "bg-warning bg-opacity-20";
                const danger = "bg-danger bg-opacity-20";

                const getAlert = () => {
                  if (row.original.status === "ESPERA DE PAGO") {
                    if (amountOfDays > 5 && amountOfDays <= 7) {
                      return warning;
                    } else if (amountOfDays <= 5) {
                      return danger;
                    } else {
                      return "";
                    }
                  }
                  return "";
                };

                return (
                  <tr className={getAlert()} {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={8}>
                  <div className="d-flex text-center w-100 align-content-center justify-content-center">
                    No se encontro ningun registro de pago
                  </div>
                </td>
              </tr>
            )}
          </tbody>

          {pageOptions.length > 0 && (
            <TablePagination
              pageSize={pageSize}
              setPageSize={setPageSize}
              gotoPage={gotoPage}
              previousPage={previousPage}
              canPreviousPage={canPreviousPage}
              pageOptions={pageOptions}
              pageIndex={pageIndex}
              nextPage={nextPage}
              canNextPage={canNextPage}
              pageCount={pageCount}
            />
          )}
        </table>
      </div>
      {isLoading && <LoadingState />}
    </>
  );
};

export default RegistroDePagosTable;
