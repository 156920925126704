import { createSlice } from "@reduxjs/toolkit";

const filtersSlice = createSlice({
  name: "filters",
  initialState: {
    dateRange: {
      start: "",
      end: "",
    },
    bookingType: "",
    bookingID: "",
    bookingUserID: "",
  },
  reducers: {
    setDateRange: (state, action) => {
      state.dateRange = action.payload;
    },
    setBookingType: (state, action) => {
      state.bookingType = action.payload;
    },
    setBookingID: (state, action) => {
      state.bookingID = action.payload;
    },
    setBookingUserID: (state, action) => {
      state.bookingUserID = action.payload;
    },
  },
});

export const { setDateRange, setBookingID, setBookingType, setBookingUserID } =
  filtersSlice.actions;
export default filtersSlice.reducer;
