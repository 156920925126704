import React from "react";
import { Modal, Button } from "react-bootstrap";

// redux
import { useDispatch } from "react-redux";
import { fetchModulosByCursoID } from "../../../../../redux/academy/modulo";
import { academy } from "../../../../../services";

import swal from "sweetalert";

import { Modulo } from "../forms";
import { handleCreateModulo } from "../../../../../../utils/academyHelper";

interface IModulo {
  idModulo: number;
  idCurso: number;
  titulo: string;
  imageURL: string;
  descripcion: string;
  estado: boolean;
  onlyForRango: string;
  onlyForPaquete: string;
  onlyForTipo: string;
}

interface Iprops {
  isOpen: boolean;
  selectedModulo: IModulo;
  typeCall: string;
  handleClose: () => void;
}

const CreateEditModulo: React.FC<Iprops> = ({
  isOpen,
  handleClose,
  typeCall,
  selectedModulo,
}) => {
  const [disableButton, setDisableButton] = React.useState(false);

  // foto
  const [previewFile, setPreviewFile] = React.useState<string>("");
  const [file, setFile] = React.useState<File>();

  const [moduloData, setModuloData] = React.useState<IModulo>({
    idCurso: 0,
    idModulo: 0,
    titulo: "",
    imageURL: "",
    descripcion: "",
    estado: false,
    onlyForRango: "",
    onlyForPaquete: "",
    onlyForTipo: "",
  });

  const dispatch = useDispatch();

  const HandleOnChange = (e: any) => {
    setModuloData({
      ...moduloData,
      [e.target.name]:
        e.target.name === "estado" ? e.target.checked : e.target.value,
    });
  };

  const handleReset = (id: Number) => {
    setModuloData({
      idModulo: 0,
      idCurso: 0,
      titulo: "",
      imageURL: "",
      descripcion: "",
      estado: false,
      onlyForRango: "",
      onlyForPaquete: "",
      onlyForTipo: "",
    });
    handleClose();
    dispatch(fetchModulosByCursoID(id));
  };

  const handleChangeFile = (event: any) => {
    const targetFile = event.target.files[0];
    const previewFile = URL.createObjectURL(targetFile);
    setFile(targetFile);
    setPreviewFile(previewFile);
  };

  const handleOnSubmit = async () => {
    setDisableButton(true);
    switch (typeCall) {
      case "Crear":
        if (moduloData.titulo !== "" && moduloData.descripcion !== "") {
          try {
            // crear modulo
            const response = await handleCreateModulo(moduloData, file);

            swal({
              title: "EXITO!",
              text: `${response.message}`,
              icon: "success",
            }).then(() => {
              handleReset(Number(moduloData.idCurso));
            });
          } catch (error) {
            swal({
              title: "Error",
              text: `Hubo un error al crear el curso`,
              icon: "error",
            });
          } finally {
            setDisableButton(false);
          }
        } else {
          swal({
            title: "Cuidado!",
            text: `Tienes que completar el formulario!`,
            icon: "warning",
          });
        }
        break;
      case "Editar":
        if (moduloData.titulo !== "" && moduloData.descripcion !== "") {
          setDisableButton(true);
          try {
            // edit
            await academy.modulo.updateModulo(
              {
                idcurso: Number(moduloData.idCurso),
                titulo: moduloData.titulo,
                descripcion: moduloData.descripcion,
                url_imagen: moduloData.imageURL,
                estado: moduloData.estado,
                onlyForRango: moduloData.onlyForRango,
                onlyForPaquete: moduloData.onlyForPaquete,
                onlyForTipo: moduloData.onlyForTipo,
              },
              Number(moduloData.idModulo)
            );

            swal({
              title: "EXITO!",
              text: `Se Actualizo el curso`,
              icon: "success",
            }).then(() => {
              // load modulos by curso ID
              handleReset(Number(moduloData.idCurso));
            });
          } catch (error) {
            swal({
              title: "Error",
              text: `Hubo un error al editar el curso`,
              icon: "error",
            });
          } finally {
            setDisableButton(false);
          }
        } else {
          swal({
            title: "Cuidado!",
            text: `Tienes que completar el formulario!`,
            icon: "warning",
          }).then(() => {
            setDisableButton(false);
          });
        }
        break;

      default:
        break;
    }
  };

  React.useEffect(() => {
    setModuloData({
      ...selectedModulo,
      idCurso: selectedModulo.idCurso,
      idModulo: selectedModulo.idModulo,
    });
  }, [selectedModulo]);

  return (
    <Modal show={isOpen} size="lg" onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{typeCall} Tema</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Modulo
          data={moduloData}
          typeCall={typeCall}
          onChange={HandleOnChange}
          // upload photo
          onChangeFile={handleChangeFile}
          previewFile={previewFile}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleOnSubmit} disabled={disableButton}>
          {typeCall}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateEditModulo;
