import { createSlice } from "@reduxjs/toolkit";
import { academy } from "../../services";

const initState = {
  modulos: [],
  modulo: {},
  isLoading: false,
  error: false,
  errorMessage: "",
};

const moduloSlice = createSlice({
  name: "modulos",
  initialState: initState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setModulos: (state, action) => {
      state.modulos = action.payload;
    },
    setModulo: (state, action) => {
      state.modulo = action.payload;
    },
  },
});

export const { setLoading, setError, setErrorMessage, setModulo, setModulos } =
  moduloSlice.actions;
export default moduloSlice.reducer;

// redux thunk
export const fetchModulos: any = () => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    dispatch(setErrorMessage(""));
    try {
      const response = await academy.modulo.getModulos();
      dispatch(setModulos(response.modulos));
    } catch (error: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchModulo: any = (idCurso: number) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    dispatch(setErrorMessage(""));
    try {
      const response = await academy.modulo.getModulo(idCurso);
      dispatch(setModulo(response));
    } catch (error: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchModulosByCursoID: any = (cursoID: number) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    dispatch(setErrorMessage(""));
    try {
      const response = await academy.modulo.getModulosByCursoID(cursoID);
      dispatch(setModulos(response.modulos));
    } catch (error: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
