import React from "react";
import { Dropdown } from "react-bootstrap";

import swal from "sweetalert";

import { useDispatch } from "react-redux";
import { fetchCategorias } from "../../../../../redux/categorias";
import { core } from "../../../../../services";

interface IProps {
  dataTable: Array<any>;
  handleSelectEdit: (row: any) => void;
}

const PaymentConceptTable: React.FC<IProps> = ({
  dataTable,
  handleSelectEdit,
}) => {
  const dispatch = useDispatch();

  const deleteCategoria = async (rowID: number) => {
    try {
      await core.categorias.deleteCategoria(rowID);
      swal("¡Listo!", "Categoria eliminado con éxito.", "success").then(() => {
        dispatch(fetchCategorias());
      });
    } catch (error) {
      swal("Error", "Hubo un error al borrar la categoria", "error");
    } finally {
    }
  };

  const handleDeleteRow = async (rowID: number) => {
    try {
      const value = await swal("¿Estás seguro?", {
        buttons: ["Cancelar", "Aceptar"],
      });

      if (value) {
        await deleteCategoria(rowID);
      }
    } catch (error) {
      swal("Error", "Hubo un error al borrar la categoria", "error");
    }
  };

  return (
    <div className="table-responsive">
      <table className="table table-hover table-row-dashed fs-6 gy-5 table-sm dataTable align-middle">
        <thead>
          <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
            <th>ID</th>
            <th>slug</th>
            <th>Nombre</th>
            <th>Acciones</th>
          </tr>
        </thead>

        <tbody className="text-gray-600 fw-bold">
          {dataTable.map((el: any) => (
            <tr key={el.idcategoria}>
              <td>{el.idcategoria}</td>
              <td>{el.slug}</td>
              <td>{el.nombre}</td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    Acciones
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleSelectEdit(el)}>
                      Editar
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleDeleteRow(Number(el.idcategoria))}
                    >
                      Eliminar
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentConceptTable;
