import React from "react";

import { Form, Container, Row, Col, InputGroup, Modal } from "react-bootstrap";

import PagoCrucero from "../form/PagoCrucero";
import PagoLibre from "../form/PagoLibre";

interface Iprops {
  isOpen: boolean;
  onClose: () => void;
}

const FormularioRegistroModal: React.FC<Iprops> = ({ isOpen, onClose }) => {
  // states
  const [isCrucero, setIsCrucero] = React.useState(false);

  return (
    <Modal size="lg" show={isOpen} onHide={onClose}>
      <Modal.Header closeButton onClick={onClose}>
        <Modal.Title>Registro de pago</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <InputGroup className="mb-3">
                <InputGroup.Text>
                  Es para una reserva de un crucero?
                </InputGroup.Text>
                <Form.Check
                  className="my-auto mx-2"
                  type="switch"
                  id="isCrucero"
                  label={`${isCrucero ? "Si" : "No"}`}
                  checked={isCrucero}
                  onChange={(e) => setIsCrucero(e.target.checked)}
                />
              </InputGroup>
            </Col>
          </Row>

          <>
            {isCrucero ? (
              <PagoCrucero handleClose={onClose} />
            ) : (
              <PagoLibre handleClose={onClose} />
            )}
          </>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default FormularioRegistroModal;
