import React from "react";

interface Iprops {
  data: Array<any>;
}

const BillingLogTable: React.FC<Iprops> = ({ data }) => {
  return (
    <div className="table-responsive">
      <table className="table table-hover table-sm align-middle">
        <thead>
          <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
            <th className="min-w-60px">#</th>
            <th className="min-w-200px">Fecha de creacion</th>
            <th className="min-w-100px">Cantidad</th>
            <th className="min-w-100px">Modena 1</th>
            <th className="min-w-100px">Modena 2</th>
            <th className="min-w-200px">URL</th>
            <th className="min-w-200px">Status</th>
          </tr>
        </thead>

        <tbody className="text-gray-600 fw-bold">
          {data.map((el: any, index: number) => (
            <tr key={el.itemName}>
              <td>{index + 1}</td>
              <td>{el.Created}</td>
              <td>{el.Amount}</td>
              <td>{`${el.currency1}`.toUpperCase()}</td>
              <td>{`${el.currency2}`.toUpperCase()}</td>
              <td>{el.URL}</td>
              <td>{el.Status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BillingLogTable;
