import React from "react";
import { useLocation } from "react-router-dom";

const useGetSearchParams = () => {
  const { search } = useLocation();

  const [searchParams, setSearchParams] = React.useState<any>({});

  const queryParamsToObject = (searchParams: any) => {
    const params: any = new URLSearchParams(searchParams);
    const obj: any = {};
    for (let [key, value] of params.entries()) {
      obj[key] = value;
    }
    return obj;
  };

  React.useEffect(() => {
    const queryStrings = queryParamsToObject(search);
    setSearchParams(queryStrings);
  }, [search]);

  return [searchParams];
};

export default useGetSearchParams;
