import { academy } from "../app/services";
import s3Helper from "./s3Helper";

interface ICurso {
  idCurso?: number;
  titulo: string;
  imageURL: string;
  descripcion: string;
  estado: boolean;
  onlyForRango: string;
  onlyForPaquete: string;
  onlyForTipo: string;
}

interface ICursoPayload {
  titulo: string;
  url_imagen: string;
  descripcion: string;
  estado: boolean;
  onlyForRango: string;
  onlyForPaquete: string;
  onlyForTipo: string;
}

const handleCreateCurso = async (data: ICurso, file?: File) => {
  try {
    let fileName = "";
    if (file) {
      // subir archivo y obtener el key del archivo
      fileName = await s3Helper.uploadFile(file);
    }

    // subir curso
    const payload: ICursoPayload = {
      titulo: data.titulo,
      url_imagen: fileName,
      descripcion: data.descripcion,
      estado: data.estado,
      onlyForPaquete: data.onlyForPaquete,
      onlyForRango: data.onlyForRango,
      onlyForTipo: data.onlyForTipo,
    };

    await academy.curso.createCurso(payload);

    return {
      message: "Se creo un nuevo curso con exito",
      created: true,
    };
  } catch (error) {
    // verificar que error es y enviar mensaje
    return {
      message: "Hubo un error al crear el curso",
      created: false,
    };
  }
};

interface IModulo {
  idModulo: number;
  idCurso: number;
  titulo: string;
  imageURL: string;
  descripcion: string;
  estado: boolean;
  onlyForRango: string;
  onlyForPaquete: string;
  onlyForTipo: string;
}

interface IModuloPayload {
  idcurso: number;
  titulo: string;
  url_imagen: string;
  descripcion: string;
  estado: boolean;
  onlyForRango: string;
  onlyForPaquete: string;
  onlyForTipo: string;
}

const handleCreateModulo = async (data: IModulo, file?: File) => {
  try {
    // subir archivo y obtener el key del archivo
    let fileName = "";
    if (file) {
      // subir archivo y obtener el key del archivo
      fileName = await s3Helper.uploadFile(file);
    }

    // subir curso
    const payload: IModuloPayload = {
      idcurso: data.idCurso,
      titulo: data.titulo,
      url_imagen: fileName,
      descripcion: data.descripcion,
      estado: data.estado,
      onlyForPaquete: data.onlyForPaquete,
      onlyForRango: data.onlyForRango,
      onlyForTipo: data.onlyForTipo,
    };

    await academy.modulo.createModulo(payload);

    return {
      message: "Se creo un nuevo modulo con exito",
      created: true,
    };
  } catch (error) {
    // verificar que error es y enviar mensaje
    return {
      message: "Hubo un error al crear el modulo",
      created: false,
    };
  }
};

interface ITema {
  idTema?: number;
  idModulo: number;
  titulo: string;
  imageURL: string;
  descripcion: string;
  contenido: any;
  estado: boolean;
  onlyForRango: string;
  onlyForPaquete: string;
  onlyForTipo: string;
}

interface ITemaPayload {
  idmodulo: number;
  titulo: string;
  url_imagen: string;
  descripcion: string;
  contenido: any;
  estado: boolean;
  onlyForRango: string;
  onlyForPaquete: string;
  onlyForTipo: string;
}

const handleCreateTema = async (data: ITema, file?: File) => {
  try {
    let fileName = "";
    if (file) {
      // subir archivo y obtener el key del archivo
      fileName = await s3Helper.uploadFile(file);
    }

    const payload: ITemaPayload = {
      idmodulo: Number(data.idModulo),
      titulo: data.titulo,
      url_imagen: fileName,
      descripcion: data.descripcion,
      contenido: data.contenido,
      estado: data.estado,
      onlyForRango: data.onlyForRango,
      onlyForPaquete: data.onlyForPaquete,
      onlyForTipo: data.onlyForTipo,
    };

    await academy.temas.createTema(payload);

    return {
      message: "Se creo un nuevo tema con exito",
      created: true,
    };
  } catch (error) {
    // verificar que error es y enviar mensaje
    return {
      message: "Hubo un error al crear el tema",
      created: false,
    };
  }
};

export { handleCreateCurso, handleCreateModulo, handleCreateTema };
