import React from "react";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setBookingData,
  FETCH_METHOD,
  changeFetchMethod,
} from "../../redux/options";
import { core } from "../../services";

// react-bootstrap
import {
  Container,
  Row,
  Col,
  InputGroup,
  Form,
  DropdownButton,
  Dropdown,
  Button,
  Modal,
} from "react-bootstrap";

// metronic
import { toAbsoluteUrl } from "../../../_metronic/helpers";
// components
import LoadingState from "../../components/LoadingState";
import FindClient from "./modules/findClient/FindClient";
import swal from "sweetalert";

const Option = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  // init
  const dispatch = useDispatch();
  const fetchMethods = Object.values(FETCH_METHOD);
  const { db, api, fetchMethod, lastDataBaseUpdate } = useSelector(
    (state: any) => state.options
  );

  const handleUpdateDB = async () => {
    setIsLoading(true);
    try {
      const result: any = await core.updateBookingDB();
      console.log(result);
      const { newEntries } = result;
      // show swal
      swal({
        title: "Actualización de reservas exitosa",
        text: `Se han actualizado ${newEntries} nuevas reservas`,
        icon: "success",
      }).then(() => {
        // update DB
        dispatch(setBookingData(fetchMethod));
      });
    } catch (error) {
      swal({
        title: "Actualización de reservas fallida",
        text: "No se han actualizado reservas",
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    dispatch(setBookingData(fetchMethod));
  }, []);

  return (
    <div>
      {isLoading && <LoadingState />}
      <div
        className="d-flex flex-column flex-center bgi-no-repeat bgi-size-cover bgi-position-center rounded-top px-9 pt-20 pb-10"
        style={{
          backgroundImage: `url('${toAbsoluteUrl(
            "/media/misc/pattern-1.jpg"
          )}')`,
        }}
      >
        <h1
          className="text-white fw-bold mb-3"
          style={{
            fontSize: "3.5rem",
          }}
        >
          Configuración
        </h1>
      </div>

      <Container>
        <Row>
          <Col xs={6}>
            <div className="pt-10">
              <h3>Datos de {FETCH_METHOD.DB}</h3>
              <div className="separator border-3 mb-10"></div>
              <InputGroup>
                <InputGroup.Text>Reservas totales</InputGroup.Text>
                <Form.Control value={db.amountOfBooking} disabled readOnly />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Hoteles</InputGroup.Text>
                <Form.Control
                  value={db.amountOfBookingHotel}
                  disabled
                  readOnly
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Cruceros</InputGroup.Text>
                <Form.Control
                  value={db.amountOfBookingCrusiers}
                  disabled
                  readOnly
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Travel experiences</InputGroup.Text>
                <Form.Control
                  value={db.amountOfBookingTravelExperiences}
                  disabled
                  readOnly
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Vuelos</InputGroup.Text>
                <Form.Control
                  value={db.amountOfBookingFlight}
                  disabled
                  readOnly
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Última actualización</InputGroup.Text>
                <Form.Control value={lastDataBaseUpdate} disabled readOnly />
              </InputGroup>
            </div>
            <div className="pt-10">
              <h3>Datos de {FETCH_METHOD.API}</h3>
              <div className="separator border-3 mb-10"></div>
              <InputGroup>
                <InputGroup.Text>Reservas totales</InputGroup.Text>
                <Form.Control value={api.amountOfBooking} disabled readOnly />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Hoteles</InputGroup.Text>
                <Form.Control
                  value={api.amountOfBookingHotel}
                  disabled
                  readOnly
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Cruceros</InputGroup.Text>
                <Form.Control
                  value={api.amountOfBookingCrusiers}
                  disabled
                  readOnly
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Travel experiences</InputGroup.Text>
                <Form.Control
                  value={api.amountOfBookingTravelExperiences}
                  disabled
                  readOnly
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Vuelos</InputGroup.Text>
                <Form.Control
                  value={api.amountOfBookingFlight}
                  disabled
                  readOnly
                />
              </InputGroup>
            </div>
          </Col>
          <Col xs={6}>
            <div className="pt-10">
              <h3>Configuracion</h3>
              <div className="separator border-3 mb-10"></div>
              <InputGroup>
                <InputGroup.Text>Metodo de obtención de datos</InputGroup.Text>
                <DropdownButton
                  variant="secondary"
                  title="Metodo"
                  id="input-group-dropdown-1"
                >
                  {fetchMethods.map((item) => (
                    <Dropdown.Item
                      key={item}
                      onClick={() => dispatch(changeFetchMethod(item))}
                    >
                      {item}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                <Form.Control value={fetchMethod} disabled readOnly />
              </InputGroup>
              <Button onClick={handleUpdateDB}>
                Actualizar base de datos.
              </Button>
            </div>

            <FindClient />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Option;
