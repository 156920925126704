import { booking } from "../app/services";
import { FETCH_METHOD } from "./../app/redux/options";

interface Iparams {
  start: string;
  end: string;
  tipoReserva: string;
  codReserva: string;
  idUser: string;
}

export const fetch = async (method: string, params: Iparams) => {
  const {
    start = "",
    end = "",
    tipoReserva = "",
    codReserva = "",
    idUser = "",
  } = params;

  if (method === FETCH_METHOD.DB) {
    const DB: Array<any> = await booking.getBookingDB(
      start,
      end,
      tipoReserva,
      codReserva,
      idUser
    );
    return DB;
  }

  if (method === FETCH_METHOD.API) {
    const API: Array<any> = await booking.getBookingAPI(
      start,
      end,
      tipoReserva,
      codReserva,
      idUser
    );
    return API;
  }
};
