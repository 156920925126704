import React from "react";

import { useDispatch } from "react-redux";
import { getFetchRegistroDePagosByCodReserva } from "../../../../redux/payments/registroDePagos";

import { Modal, Row, Col, InputGroup, Form, Button } from "react-bootstrap";

import { payments } from "../../../../services";
import config from "../../../../../config";
import swal from "sweetalert";
interface Iprops {
  isOpen: boolean;
  onClose: () => void;
  data: any;
}

interface IdataKeys {
  id: number;
  key: string;
  label: string;
  md: number;
  sx: number;
}

const keys: Array<IdataKeys> = [
  { id: 4, key: "codReserva", md: 12, sx: 12, label: "Codigo de reserva" },
  { id: 1, key: "tipo", md: 6, sx: 12, label: "Tipo" },
  { id: 10, key: "status", md: 6, sx: 12, label: "Status" },
  { id: 2, key: "concepto", md: 12, sx: 12, label: "Concepto de compra" },
  { id: 5, key: "fechaCreacion", md: 6, sx: 12, label: "Fecha de creacion" },
  {
    id: 6,
    key: "fechaLimitePago",
    md: 6,
    sx: 12,
    label: "Fecha limite de pago",
  },
  {
    id: 7,
    key: "porcentajePago",
    md: 6,
    sx: 12,
    label: "Porcentaje del pago",
  },
  { id: 8, key: "importePago", md: 6, sx: 12, label: "Importe" },
  { id: 11, key: "buyerEmail", md: 12, sx: 12, label: "Correo del comprador" },

  { id: 9, key: "coinPaymentURL", md: 12, sx: 12, label: "URL" },
  { id: 3, key: "comentario", md: 12, sx: 12, label: "Comentarios" },
];

const ReadRegistroDePago: React.FC<Iprops> = ({ isOpen, onClose, data }) => {
  const dispatch = useDispatch();

  const createItemName = () => {
    if (data.tipo.toLowerCase() === "libre") {
      return `${data.idpayment}_${data.tipo}_${data.concepto}`;
    } else {
      return `${data.idpayment}_${data.tipo}_${data.codReserva}`;
    }
  };

  const handleCreateURL = async () => {
    const toSend = {
      item_name: createItemName(),
      amount: data.importePago,
      ipn: `${config.API.LHC_BACKEND_URL}/payment/setConfirmPayment?idpayment=${data.id}&codReserva=${data.codReserva}`,
      buyer_email: data.buyer_email,
    };

    try {
      const response = await payments.getCoinPaymentUrl(toSend);

      if (response.status === 200) {
        const { url } = response;
        // update the payment
        const updateData = {
          id: data.idpayment,
          url,
        };

        const updateResponse = await payments.updatePaymentRecord(updateData);

        if (updateResponse.updated === true) {
          // reload data
          swal({
            title: "Exito",
            text: "Se ha creado la URL de pago",
            icon: "success",
          }).then(() => {
            dispatch(getFetchRegistroDePagosByCodReserva(data.codReserva));
            onClose();
          });
        }
      }
    } catch (error) {
      swal({
        title: "Error",
        text: "No se ha podido crear la URL de pago",
        icon: "error",
      }).then(() => {
        onClose();
      });
    }
  };

  const handleCopyToClipboard = () => {
    window.navigator.clipboard.writeText(data.coinPaymentURL);
    swal({
      title: "Exito",
      text: "Se ha copiado la URL de pago",
      icon: "success",
    });
  };

  return (
    <>
      <Modal size="lg" show={isOpen} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Registro de pago #{data.idpayment}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {keys.map((item: IdataKeys) => {
              if (item.key === "coinPaymentURL") {
                return (
                  <Col md={item.md} sx={item.sx} key={item.id}>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>{item.label}</InputGroup.Text>
                      <Form.Control
                        readOnly
                        disabled
                        value={data[item.key]}
                        type="text"
                        placeholder={item.label}
                      />
                      <Button
                        variant="success"
                        onClick={() => handleCreateURL()}
                      >
                        Generar URL
                      </Button>
                      {data.coinPaymentURL && (
                        <Button onClick={() => handleCopyToClipboard()}>
                          Copiar URL
                        </Button>
                      )}
                    </InputGroup>
                  </Col>
                );
              }

              if (item.key === "porcentajePago") {
                return (
                  <Col md={item.md} sx={item.sx} key={item.id}>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>{item.label}</InputGroup.Text>
                      <InputGroup.Text>%</InputGroup.Text>
                      <Form.Control
                        readOnly
                        disabled
                        value={Number(data[item.key]).toFixed(2)}
                        type="text"
                        placeholder={item.label}
                      />
                    </InputGroup>
                  </Col>
                );
              }

              if (item.key === "importePago") {
                return (
                  <Col md={item.md} sx={item.sx} key={item.id}>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>{item.label}</InputGroup.Text>
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        readOnly
                        disabled
                        value={Number(data[item.key]).toFixed(2)}
                        type="text"
                        placeholder={item.label}
                      />
                    </InputGroup>
                  </Col>
                );
              }

              if (item.key === "comentario") {
                return (
                  <Col md={item.md} sx={item.sx} key={item.id}>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>{item.label}</InputGroup.Text>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        readOnly
                        disabled
                        value={data[item.key]}
                        placeholder={item.label}
                      />
                    </InputGroup>
                  </Col>
                );
              }

              return (
                <Col md={item.md} sx={item.sx} key={item.id}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>{item.label}</InputGroup.Text>
                    <Form.Control
                      readOnly
                      disabled
                      value={data[item.key]}
                      type="text"
                      placeholder={item.label}
                    />
                  </InputGroup>
                </Col>
              );
            })}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReadRegistroDePago;
