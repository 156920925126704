import { createSlice } from "@reduxjs/toolkit";
import { core } from "../services";

const initState = {
  rangos: [],
  paquetes: [],
  tipos: [],
  marcas: [],
  tickets: [],
  isLoading: false,
  error: false,
  errorMessage: "",
};

const coreSlice = createSlice({
  name: "core",
  initialState: initState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setRangos: (state, action) => {
      state.rangos = action.payload;
    },
    setPaquetes: (state, action) => {
      state.paquetes = action.payload;
    },
    setTipos: (state, action) => {
      state.tipos = action.payload;
    },
    setMarcas: (state, action) => {
      state.marcas = action.payload;
    },
    setTickets: (state, action) => {
      state.tickets = action.payload;
    },
  },
});

export const {
  setLoading,
  setError,
  setErrorMessage,
  setPaquetes,
  setRangos,
  setTipos,
  setMarcas,
  setTickets,
} = coreSlice.actions;
export default coreSlice.reducer;

export const fetchRangos: any = () => async (dispatch: any) => {
  dispatch(setLoading(true));
  dispatch(setError(false));
  dispatch(setErrorMessage(""));
  try {
    const response = await core.getRangos();
    dispatch(setRangos(response));
  } catch (error: any) {
    dispatch(setError(true));
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const fetchPaquetes: any = () => async (dispatch: any) => {
  dispatch(setLoading(true));
  dispatch(setError(false));
  dispatch(setErrorMessage(""));
  try {
    const response = await core.getPaquetes();
    dispatch(setPaquetes(response));
  } catch (error: any) {
    dispatch(setError(true));
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const fetchTipos: any = () => async (dispatch: any) => {
  dispatch(setLoading(true));
  dispatch(setError(false));
  dispatch(setErrorMessage(""));
  try {
    const response = await core.getTipos();
    dispatch(setTipos(response));
  } catch (error: any) {
    dispatch(setError(true));
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const fetchMarcas: any = () => async (dispatch: any) => {
  dispatch(setLoading(true));
  dispatch(setError(false));
  dispatch(setErrorMessage(""));
  try {
    const response = await core.marcas.getMarcas();
    dispatch(setMarcas(response));
  } catch (error: any) {
    console.log(error);
    dispatch(setError(true));
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

type T_ticketsResponse = {
  page: number;
  totalPage: number;
  totalData: number;
  data: Array<any>;
};

export const fetchTickets: any =
  (
    page: number = 1,
    pageSize: number = 10,
    filters?: {
      idUser?: string;
      idticket?: number;
      estado?: string;
      referenciaTicket?: string;
      importeTicket?: number;
      fechaInicio?: string;
      fechaFinal?: string;
    }
  ) =>
  async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    dispatch(setErrorMessage(""));
    try {
      const response: T_ticketsResponse = await core.tickets.getTickets(
        page,
        pageSize,
        {
          idUser: filters?.idUser,
          idticket: filters?.idticket,
          estado: filters?.estado,
          referenciaTicket: filters?.referenciaTicket,
          importeTicket: filters?.importeTicket,
          fechaInicio: filters?.fechaInicio,
          fechaFinal: filters?.fechaFinal,
        }
      );
      dispatch(setTickets(response));
    } catch (error: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
