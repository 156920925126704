import SelectColumnFilter from "./SelectColumnFilter";

const CruceroColumns: Array<any> = [
  {
    Header: "Código reserva",
    accessor: "codReserva",
    sortType: "basic",
  },
  {
    Header: "Cliente ID",
    accessor: "idCliente",
    sortType: "basic",
  },
  {
    Header: "Importe del crucero",
    accessor: "importeCrucero",
    sortType: "basic",
  },
  {
    Header: "Fecha de reserva",
    accessor: "fechaReserva",
    sortType: "basic",
  },
];

const ReservasColumns: Array<any> = [
  {
    Header: "Código reserva",
    accessor: "codReserva",
    sortType: "basic",
  },
  {
    Header: "Cliente ID",
    accessor: "idCliente",
    sortType: "basic",
  },
  {
    Header: "Tipo de reserva",
    accessor: "tipoReserva",
    sortType: "basic",
  },
  {
    Header: "Fecha de reserva",
    accessor: "fechaReserva",
    sortType: "basic",
  },
];

const RegistroDePagoColumns: Array<any> = [
  {
    Header: "ID",
    accessor: "idpayment",
    sortType: "basic",
  },
  {
    Header: "Tipo de pago",
    accessor: "tipo",
    sortType: "basic",
  },
  {
    Header: "Concepto de pago",
    accessor: "concepto",
    sortType: "basic",
  },
  {
    Header: "Fecha de creacion",
    accessor: "fechaCreacion",
    sortType: "basic",
  },
  {
    Header: "Estado",
    accessor: "status",
    sortType: "basic",
  },
];

export { CruceroColumns, RegistroDePagoColumns, ReservasColumns };
