import { createSlice } from "@reduxjs/toolkit";

import { fetch } from "../../../utils/apiHelper";

const initState = {
  travelExperiences: [],
  isLoading: false,
  error: false,
  errorMeesage: "",
  travel: {},
};

const travelExperiencesSlice = createSlice({
  name: "travelExperiences",
  initialState: initState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setData: (state, action) => {
      state.travelExperiences = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMeesage = action.payload;
    },
    setTravel: (state, action) => {
      state.travel = action.payload;
    },
  },
});

export const { setLoading, setData, setError, setErrorMessage, setTravel } =
  travelExperiencesSlice.actions;
export default travelExperiencesSlice.reducer;

interface IstartEndWeek {
  start: string;
  end: string;
}

// async thunk action

export const fetchTravelExperiences: any = (
  fetchMethod: string,
  { start, end }: IstartEndWeek,
  codReserva: string = "",
  idUser: string = ""
) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    dispatch(setErrorMessage(""));
    try {
      const response = await fetch(fetchMethod, {
        start,
        end,
        tipoReserva: "Travel Experiences",
        codReserva,
        idUser,
      });
      dispatch(setData(response));
    } catch (error: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
