import React from "react";

import { useDispatch } from "react-redux";

import { Modal, Button } from "react-bootstrap";

import swal from "sweetalert";

// components
import TicketForm from "../forms/Tickets";
import { handleUpdateTicket } from "../../../../utils/ticketsHelper";

interface Iticket {
  idticket?: number;
  fecha: string;
  imagenTicket: string;
  fechaCreacion: string;
  codigoMarca: number;
  nombreMarca: string;
  codigoPais: number;
  nombrePais: string;
  idUsuario: string;
  importeTicket: number;
  importeRecompensa: number;
  referenciaTicket: string;
  estado: string;
  poblacionEstablecimiento: string;
  direccionEstablecimiento: string;
  codigoPostal: string;
  nombreLegalEmpresa: string;
  numeroLegalEmpresa: string;
  porcentajeRecompensa: number;
  estadoBase?: string;
  fechaTicketBase?: string;
  comentarios?: string;
}

interface Iprops {
  isOpen: boolean;
  typeCall: string;
  selectedTicket: Iticket;
  handleClose: () => void;
  currentPage: number;
  handleRefresh: (page: number, pageSize: number) => void;
}

const CreateEditTickets: React.FC<Iprops> = ({
  isOpen,
  typeCall,
  handleClose,
  selectedTicket,
  currentPage,
  handleRefresh,
}) => {
  const [ticket, setTicket] = React.useState<Iticket>({
    fecha: "",
    imagenTicket: "",
    fechaCreacion: "",
    codigoMarca: 0,
    nombreMarca: "",
    codigoPais: 0,
    nombrePais: "",
    idUsuario: "",
    importeTicket: 0,
    importeRecompensa: 0,
    estado: "",
    referenciaTicket: "",
    poblacionEstablecimiento: "",
    direccionEstablecimiento: "",
    codigoPostal: "",
    nombreLegalEmpresa: "",
    numeroLegalEmpresa: "",
    porcentajeRecompensa: 0,
    estadoBase: "",
    fechaTicketBase: "",
    comentarios: "",
  });
  const [disableButton, setDisableButton] = React.useState<boolean>(false);

  const handleResetTicket = () => {
    setTicket({
      idUsuario: "",
      imagenTicket: "",
      codigoMarca: 0,
      nombreMarca: "",
      codigoPais: 0,
      nombrePais: "",
      fechaCreacion: "",
      importeTicket: 0,
      importeRecompensa: 0,
      fecha: "",
      estado: "",
      referenciaTicket: "",
      poblacionEstablecimiento: "",
      direccionEstablecimiento: "",
      codigoPostal: "",
      nombreLegalEmpresa: "",
      numeroLegalEmpresa: "",
      porcentajeRecompensa: 0,
      estadoBase: "",
      fechaTicketBase: "",
    });
  };

  const handleOnChange = (event: any) => {
    setTicket({
      ...ticket,
      [event.target.name]: event.target.value,
    });
  };

  const handleOnChangeDateTicket = (value: string) => {
    setTicket({
      ...ticket,
      fecha: value,
    });
  };

  const handleOnChangeSelect = (event: any, porcentajeRecompensa?: number) => {
    const targetValue = event.target.value;
    const key = event.target.name;

    if (porcentajeRecompensa !== undefined) {
      const nuevoImporte =
        Number(ticket.importeTicket) * (porcentajeRecompensa / 100);
      setTicket({
        ...ticket,
        [key]: targetValue,
        importeRecompensa:
          targetValue === "validada" ? Number(nuevoImporte.toFixed(2)) : 0,
      });
    }
  };

  const handleOnSubmit = async (stay: boolean = false) => {
    setDisableButton(true);
    switch (typeCall) {
      case "Editar":
        try {
          const response = await handleUpdateTicket(ticket);

          if (response?.updated === true) {
            console.log("Se actualizo");
            if (response?.validated === true) {
              console.log("Esta validado");
              swal(
                "¡Listo!",
                `Ticket validado con éxito, se agrego ${ticket.importeRecompensa} al usuario ${ticket.idUsuario}`,
                "success"
              );
            } else {
              console.log("No esta validado pero actualizado");
              const message = `${response?.message ? response?.message : ""}`;
              swal(
                "¡Listo!",
                `Ticket actualizado con éxito, pero no validado, ${message}`,
                "success"
              );
            }
          } else {
            throw new Error("No se actualizo");
          }
        } catch (error: any) {
          console.log(error);
          swal("Alerta!", `${error.message}`, "warning");
        } finally {
          if (stay === false) {
            handleResetTicket();
            handleClose();
          }
          handleRefresh(currentPage, 10);
          setDisableButton(false);
        }

        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    if (typeCall !== "Crear") {
      setTicket({
        ...selectedTicket,
        estadoBase: selectedTicket.estado,
        fechaTicketBase: selectedTicket.fecha,
      });
    }
  }, [selectedTicket, typeCall]);

  return (
    <Modal show={isOpen} onHide={handleClose}>
      <Modal.Body>
        <TicketForm
          data={ticket}
          typeCall={typeCall}
          onChange={handleOnChange}
          onChangeSelect={handleOnChangeSelect}
          onChangeDateTicket={handleOnChangeDateTicket}
        />
      </Modal.Body>
      <Modal.Footer>
        {typeCall !== "Ver" && (
          <>
            <Button
              onClick={() => {
                handleResetTicket();
                handleClose();
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={disableButton}
              onClick={(e) => {
                handleOnSubmit(false);
              }}
            >
              Guardar y salir
            </Button>
            <Button
              disabled={disableButton}
              onClick={(e) => {
                handleOnSubmit(true);
              }}
            >
              Guardar
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CreateEditTickets;
