import SelectColumnFilter from "./SelectColumnFilter";

const ticketsColumn: any = [
  {
    Header: "ID",
    accessor: "idticket",
    sortType: "basic",
  },
  {
    Header: "ID usuario",
    accessor: "idUsuario",
    sortType: "basic",
  },
  {
    Header: "Referencia",
    accessor: "referenciaTicket",
    sortType: "basic",
  },
  {
    Header: "Importe del ticket",
    accessor: "importeTicket",
    sortType: "basic",
  },
  {
    Header: "Fecha de creacion",
    accessor: "fechaCreacion",
    sortType: "basic",
  },
  {
    Header: "Estado",
    accessor: "estado",
    sortType: "basic",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
];

const marcasColumn: any = [
  {
    Header: "ID",
    accessor: "idmarca",
    sortType: "basic",
  },
  {
    Header: "Marca",
    accessor: "nombreMarca",
    sortType: "basic",
  },
  {
    Header: "Categoria",
    accessor: "categoria",
    sortType: "basic",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  {
    Header: "Estado",
    accessor: "estado",
    sortType: "basic",
    Filter: SelectColumnFilter,
    filter: "includes",
  },
];

export { ticketsColumn, marcasColumn };
