import React from "react";

import { Button } from "react-bootstrap";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGetConceptos,
  setSelectedConcept,
} from "../../../redux/payments/conceptoDePago";

// metronic
import { KTCard, KTCardBody } from "../../../../_metronic/helpers";

// components
import LoadingState from "../../../components/LoadingState";
import CreateEditConceptoDePago from "../../../components/containers/modals/CreateEditConceptoDePago";
import ConceptoDePagoTable from "./components/table/PaymentConceptTable";

const PaymentConcept = () => {
  // states
  const [show, setShow] = React.useState(false);
  const [typeCall, setTypeCall] = React.useState("Crear");
  // redux
  const dispatch = useDispatch();
  const { conceptosDePago, loading } = useSelector(
    (state: any) => state.conceptoDePago
  );

  const handleCreateConcept = () => {
    setTypeCall("Crear");
    setShow(true);
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  const handleSelectEdit = (row: any) => {
    setTypeCall("Editar");
    dispatch(setSelectedConcept(row));
    setShow(true);
  };

  // fetch conceptos de pago
  React.useEffect(() => {
    dispatch(fetchGetConceptos());
  }, [dispatch]);

  return (
    <div>
      <CreateEditConceptoDePago
        isOpen={show}
        typeCreateEdit={typeCall}
        handleClose={handleCloseModal}
      />

      <KTCard>
        <div className="card-header">
          <div className="card-title">Conceptos de pago</div>
          <div className="card-toolbar">
            <Button
              variant="primary"
              onClick={() => {
                handleCreateConcept();
              }}
            >
              Nuevo concepto de pago
            </Button>
          </div>
        </div>
        <KTCardBody className="py-4">
          {loading && <LoadingState />}
          <ConceptoDePagoTable
            dataTable={conceptosDePago}
            handleSelectEdit={handleSelectEdit}
          />
        </KTCardBody>
      </KTCard>
    </div>
  );
};

export default PaymentConcept;
