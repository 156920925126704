import React from "react";
import { Button } from "react-bootstrap";

// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchUserList } from "../../../redux/pushNotification";

// metronic
import { KTCard, KTCardBody } from "../../../../_metronic/helpers";

interface IRegistredUser {
  idlhcusuario: number;
  idusuario: string;
  uuidFirebase: string;
  name: string;
  pais: string;
  userData: string;
}

const ListUser = () => {
  const dispatch = useDispatch();
  const { userList } = useSelector((state: any) => state.pushNotification);

  const data = React.useMemo(() => {
    const newData = userList.map((el: any) => {
      console.log(el.userData);
      return {
        idlhcusuario: el.idlhcusuario,
        idusuario: el.idusuario,
        uuidFirebase: el.uuidFirebase,
        name: `${el.userData.nombre} ${el.userData.apellido}`,
        pais: el.userData.pais,
        userData: el.userData,
      };
    });
    return newData;
  }, [userList]);

  React.useEffect(() => {
    dispatch(fetchUserList());
  }, []);

  return (
    <div>
      <KTCard>
        <div className="card-header">
          <div className="card-title">Lista de usuario registrados</div>
        </div>
        <KTCardBody>
          <div className="table-responsive">
            <table className="table table-hover table-row-dashed fs-6 gy-5 table-sm dataTable align-middle">
              <thead>
                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Pais</th>
                  <th>id usuario</th>
                  <th>uuid</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 fw-bold">
                {data.map((item: IRegistredUser) => (
                  <tr key={item.idlhcusuario}>
                    <td>{item.idlhcusuario}</td>
                    <td>{item.name}</td>
                    <td>{item.pais}</td>
                    <td>{item.idusuario}</td>
                    <td>{item.uuidFirebase}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </KTCardBody>
      </KTCard>
    </div>
  );
};

export default ListUser;
