import React from "react";

// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchLogsList } from "../../../redux/pushNotification";

import { KTCard, KTCardBody } from "../../../../_metronic/helpers";

const NotificationLog = () => {
  const dispatch = useDispatch();
  const { logList } = useSelector((state: any) => state.pushNotification);

  const data = React.useMemo(() => {
    const newData = logList.map((el: any) => {
      return {
        ...el,
        id: el.idnotificationlog,
      };
    });
    return newData;
  }, [logList]);

  React.useEffect(() => {
    dispatch(fetchLogsList());
  }, []);

  return (
    <div>
      <KTCard>
        <div className="card-header">
          <div className="card-title">Lista de usuario registrados</div>
        </div>
        <KTCardBody>
          <div className="table-responsive">
            <table className="table table-hover table-row-dashed fs-6 gy-5 table-sm dataTable align-middle">
              <thead>
                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                  <th>ID</th>
                  <th>titulo</th>
                  <th>contenido</th>
                  <th>descripcion</th>
                  <th>enlace</th>
                  <th>lugar</th>
                  <th>fecha</th>
                  <th>fechaCreacion</th>
                  <th>otros</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 fw-bold">
                {data.map((item: any) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.titulo}</td>
                    <td>{item.contenido}</td>
                    <td>{item.descripcion}</td>
                    <td>{item.enlace}</td>
                    <td>{item.lugar}</td>
                    <td>{item.fecha}</td>
                    <td>{item.fechaCreacion}</td>
                    <td>{item.otros}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </KTCardBody>
      </KTCard>
    </div>
  );
};

export default NotificationLog;
