import React from "react";

// react-bootstrap
import { Modal } from "react-bootstrap";

// components
import SearchReservas from "../tables/SearchReserva";

interface Iprops {
  isOpen: boolean;
  handleClose: () => void;
  handleSelectReserva: (reserva: any) => void;
  tipoReserva: string;
}

const SearchCruceroModal: React.FC<Iprops> = ({
  isOpen,
  handleClose,
  handleSelectReserva,
  tipoReserva,
}) => {
  return (
    <Modal size="xl" show={isOpen} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Codigos de reservas</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SearchReservas
          handleClose={handleClose}
          handleSelect={handleSelectReserva}
          tipoReserva={tipoReserva}
        />
      </Modal.Body>
    </Modal>
  );
};

export default SearchCruceroModal;
