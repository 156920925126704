import { createSlice } from "@reduxjs/toolkit";

import { payments } from "../../services";

const initialState = {
  registroDePagos: [],
  loading: false,
  error: null,
};

const registroDePagosSlice = createSlice({
  name: "registroDePagos",
  initialState,
  reducers: {
    getRegistroDePagosStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    getRegistroDePagosSuccess: (state, action) => {
      state.registroDePagos = action.payload;
      state.loading = false;
      state.error = null;
    },
    getRegistroDePagosFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getRegistroDePagosStart,
  getRegistroDePagosSuccess,
  getRegistroDePagosFailure,
} = registroDePagosSlice.actions;
export default registroDePagosSlice.reducer;

export const getFetchListRegistroDePagos: any = () => async (dispatch: any) => {
  dispatch(getRegistroDePagosStart());
  try {
    const response = await payments.getPaymentRecords();
    dispatch(getRegistroDePagosSuccess(response));
  } catch (error: any) {
    dispatch(getRegistroDePagosFailure(error.message));
  }
};

export const getFetchRegistroDePagosByCodReserva: any = (
  codReserva: string
) => {
  return async (dispatch: any) => {
    dispatch(getRegistroDePagosStart());
    payments
      .getPaymentRecordByCod(codReserva)
      .then((res: any) => {
        dispatch(getRegistroDePagosSuccess(res));
      })
      .catch((err: any) => {
        dispatch(getRegistroDePagosFailure("No hay reservas"));
      });
  };
};
