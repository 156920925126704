import React from "react";
import { Form } from "react-bootstrap";

interface Iprops {
  onChangeFile: (event: any) => void;
  previewFile: string;
}

const UploadFile: React.FC<Iprops> = ({ onChangeFile, previewFile }) => {
  return (
    <>
      <Form.Control type="file" onChange={onChangeFile} />
      {previewFile && (
        <>
          <h2>Vista previa.</h2>
          <div className="separator border-3 mb-5" />
          <img src={previewFile} alt="previewFile" width={"100%"} />
          <div className="separator border-3 mt-5 mb-10" />
        </>
      )}
    </>
  );
};

export default UploadFile;
