import React from "react";

// react-bootstrap
import { Form, Row, Col, InputGroup, Button } from "react-bootstrap";

// utils
import * as fns from "date-fns";
import { payments } from "../../../../services";
import swal from "sweetalert";

// components
import SearchCruceroModal from "../modal/SearchCruceroModal";

interface Iprops {
  handleClose: () => void;
}

const PagoCrucero: React.FC<Iprops> = ({ handleClose }) => {
  // states
  const [openSearchCrucero, setOpenSearchCrucero] = React.useState(false);
  // form state
  const [codReserva, setCodReserva] = React.useState("");
  const [fechaLimite, setFechaLimite] = React.useState(
    fns.format(new Date(), "yyyy-MM-dd")
  );
  const [correoComprador, setCorreoComprador] = React.useState("");
  const [importeDeLaReserva, setImporteDeLaReserva] = React.useState(0);
  const [importe, setImporte] = React.useState(0);
  const [porcentaje, setPorcentaje] = React.useState(0);

  // functions
  const handleSelectCodReserva = (item: any) => {
    const { codReserva, importeCrucero } = item;

    setCodReserva(codReserva);
    setImporteDeLaReserva(Number(importeCrucero));
  };

  const handleOpenCrucero = () => {
    setOpenSearchCrucero(true);
  };
  const handleCloseCrucero = () => {
    setOpenSearchCrucero(false);
  };

  const handleOnChangePercentage = (e: any) => {
    const amount = (e.target.value / 100) * importeDeLaReserva;
    setPorcentaje(e.target.value);
    setImporte(+amount.toFixed(2));
  };

  const handleOnChangeAmount = (e: any) => {
    const percentage = (e.target.value / importeDeLaReserva) * 100;
    setPorcentaje(+percentage.toFixed(2));
    setImporte(e.target.value);
  };

  const handleOnChangeCorreoComprador = (e: any) => {
    setCorreoComprador(e.target.value);
  };

  const handleShowSubmitButton = () => {
    if (codReserva && porcentaje && correoComprador && importe) {
      return true;
    }
    return false;
  };

  const handleSubmit = async () => {
    // fecha de creacion
    const dateNow = new Date();
    const fechaDeCreacion = fns.format(dateNow, "yyyy-MM-dd HH:mm:ss");

    const toSend = {
      codReserva,
      paymentPercentage: porcentaje,
      paymentAmount: importe,
      createdDate: fechaDeCreacion,
      concepto: "",
      limitDate: fechaLimite,
      buyerEmail: correoComprador,
      tipo: "CRUCERO",
    };

    try {
      await payments.createPaymentRecord(toSend);
      // reset state

      swal({
        title: "Pago registrado",
        text: "El pago se ha registrado correctamente",
        icon: "success",
      }).then(() => {
        setCodReserva("");
        setFechaLimite(fns.format(new Date(), "yyyy-MM-dd"));
        setCorreoComprador("");
        setImporteDeLaReserva(0);
        setImporte(0);
        setPorcentaje(0);
        handleClose();
      });
    } catch (error) {
      swal({
        title: "Error",
        text: "Ha ocurrido un error al registrar el pago",
        icon: "error",
      });
    }
  };

  return (
    <>
      <SearchCruceroModal
        isOpen={openSearchCrucero}
        handleClose={handleCloseCrucero}
        handleSelectReserva={handleSelectCodReserva}
        tipoReserva="Crucero"
      />
      <Row>
        <Col xs={12}>
          <InputGroup className="mb-3">
            <InputGroup.Text>Codigo de reserva</InputGroup.Text>
            <InputGroup.Text>#</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Codigo de reserva"
              disabled={true}
              defaultValue={codReserva}
            />
            <Button variant="primary" onClick={handleOpenCrucero}>
              Buscar crucero
            </Button>
          </InputGroup>
        </Col>
        <>
          {codReserva !== "" ? (
            <>
              <Col md={6} xs={12}>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Importe de reserva</InputGroup.Text>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control disabled={true} value={importeDeLaReserva} />
                </InputGroup>
              </Col>
              <Col xs={12}>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Fecha limite de pago</InputGroup.Text>
                  <Form.Control
                    type="date"
                    value={fechaLimite}
                    onChange={(e) => {
                      setFechaLimite(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
              <Col xs={12}>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Correo del comprador</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Correo del comprador"
                    value={correoComprador}
                    onChange={handleOnChangeCorreoComprador}
                  />
                </InputGroup>
              </Col>

              <Col md={6} xs={12}>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Porcentaje de pago</InputGroup.Text>
                  <InputGroup.Text>%</InputGroup.Text>

                  <Form.Control
                    value={porcentaje}
                    placeholder="0"
                    onChange={handleOnChangePercentage}
                  />
                </InputGroup>
              </Col>
              <Col md={6} xs={12}>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Importe a pagar</InputGroup.Text>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    placeholder="0.00"
                    value={importe}
                    onChange={handleOnChangeAmount}
                  />
                </InputGroup>
              </Col>
            </>
          ) : (
            <></>
          )}
        </>
      </Row>
      <Row>
        <Col xs={12}>
          <Button
            variant="primary"
            disabled={!handleShowSubmitButton()}
            onClick={handleSubmit}
          >
            Crear registro de pago
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PagoCrucero;
