import React from "react";

import { Button } from "react-bootstrap";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategorias,
  setSelectedCategoria,
} from "../../../redux/categorias";

// metronic
import { KTCard, KTCardBody } from "../../../../_metronic/helpers";

// components
import LoadingState from "../../../components/LoadingState";
import CreateEditCategoria from "../../../components/containers/modals/CreateEditCategoria";
import CategoriaTable from "./components/table/CategoriaTable";

const PaymentConcept = () => {
  // states
  const [show, setShow] = React.useState(false);
  const [typeCall, setTypeCall] = React.useState("Crear");
  // redux
  const dispatch = useDispatch();
  const { categorias, isLoading } = useSelector(
    (state: any) => state.categorias
  );

  const handleCreateConcept = () => {
    setTypeCall("Crear");
    setShow(true);
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  const handleSelectEdit = (row: any) => {
    setTypeCall("Editar");
    dispatch(setSelectedCategoria(row));
    setShow(true);
  };

  // fetch conceptos de pago
  React.useEffect(() => {
    dispatch(fetchCategorias());
  }, [dispatch]);

  return (
    <div>
      <CreateEditCategoria
        isOpen={show}
        typeCreateEdit={typeCall}
        handleClose={handleCloseModal}
      />

      <KTCard>
        <div className="card-header">
          <div className="card-title">Conceptos de pago</div>
          <div className="card-toolbar">
            <Button
              variant="primary"
              onClick={() => {
                handleCreateConcept();
              }}
            >
              Nueva Categoria
            </Button>
          </div>
        </div>
        <KTCardBody className="py-4">
          {isLoading && <LoadingState />}
          <CategoriaTable
            dataTable={categorias}
            handleSelectEdit={handleSelectEdit}
          />
        </KTCardBody>
      </KTCard>
    </div>
  );
};

export default PaymentConcept;
