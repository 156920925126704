import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Tema } from "../forms";
import TemaRead from "../forms/TemaRead";
import swal from "sweetalert";
import s3 from "../../../../../../utils/s3Helper";
import { academy } from "../../../../../services";
import { useDispatch } from "react-redux";
import { fetchTemasByModuloID } from "../../../../../redux/academy/temas";
import useGetSearchParams from "../../../../../hooks/useGetSearchParams";
import { handleCreateTema } from "../../../../../../utils/academyHelper";

interface ITema {
  idTema: number;
  idModulo: number;
  titulo: string;
  imageURL: string;
  descripcion: string;
  contenido: any;
  estado: boolean;
  onlyForRango: string;
  onlyForPaquete: string;
  onlyForTipo: string;
}

interface IProps {
  isOpen: boolean;
  selectedTema: ITema;
  typeCall: string;
  handleClose: () => void;
}

const CreateEditTema: React.FC<IProps> = ({
  isOpen,
  handleClose,
  selectedTema,
  typeCall,
}) => {
  // edit create
  const [disableButton, setDisableButton] = React.useState<boolean>(false);
  const [temaData, setTemaData] = React.useState<ITema>({
    idTema: 0,
    idModulo: 0,
    titulo: "",
    imageURL: "",
    descripcion: "",
    contenido: {},
    estado: false,
    onlyForRango: "",
    onlyForPaquete: "",
    onlyForTipo: "",
  });
  const [currentIdModulo, setCurrentIdModulo] = React.useState<number>(0);
  // photo
  const [previewFile, setPreviewFile] = React.useState<string>("");
  const [file, setFile] = React.useState<File>();

  // control del contenido
  const [contenidoArray, setContenidoArray] = React.useState<Array<any>>([]);
  const [tempContenido, setTempContenido] = React.useState({
    tipo: "",
    titulo: "",
    link: "",
  });
  const [uploadLoading, setUploadLoading] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState([]);

  // redux
  const dispatch = useDispatch();
  const [searchParams] = useGetSearchParams();

  // handle reset data
  const handleReset = (id: number) => {
    console.log(id);
    setTemaData({
      idTema: 0,
      idModulo: 0,
      titulo: "",
      imageURL: "",
      descripcion: "",
      contenido: {},
      estado: false,
      onlyForRango: "",
      onlyForPaquete: "",
      onlyForTipo: "",
    });
    setPreviewFile("");
    handleClose();
    if (typeCall === "Editar") {
      dispatch(fetchTemasByModuloID(id));
    }
  };

  // handle change data
  const handleOnChange = (e: any) => {
    setTemaData({
      ...temaData,
      [e.target.name]:
        e.target.name === "estado" ? e.target.checked : e.target.value,
    });
  };

  // handle upload photo
  const handleUploadPhoto = (event: any) => {
    // the file
    const targetFile = event.target.files[0];
    // get the url to preview
    const previewFile = URL.createObjectURL(targetFile);
    // set the preview and the current file
    setFile(targetFile);
    setPreviewFile(previewFile);
  };

  // contenido
  const handleResetContenido = () => {
    setTempContenido({
      tipo: "",
      titulo: "",
      link: "",
    });
  };

  const handleAddContenido = () => {
    const getID =
      contenidoArray.length > 0
        ? Number(contenidoArray[contenidoArray.length - 1].id) + 1
        : 0;

    const currentArray = [...contenidoArray];
    currentArray.push({
      id: getID,
      ...tempContenido,
    });
    setContenidoArray(currentArray);
  };

  const handleDeleteContenido = (id: number) => {
    const currentArray = [...contenidoArray];
    const filtered = currentArray.filter((c) => c.id !== id);
    setContenidoArray(filtered);
  };

  const handleOnChangeContenido = (event: any) => {
    setTempContenido({
      ...tempContenido,
      [event.target.name]: event.target.value,
    });
  };

  const handleUploadFileContenido = async (event: any) => {
    try {
      setUploadLoading(true);
      const file: File = files[0];
      const fileName = await s3.uploadFile(file);
      setTempContenido({
        ...tempContenido,
        link: fileName,
      });
    } catch (error: any) {
      throw new Error(error);
    } finally {
      setFiles([]);
      setUploadLoading(false);
    }
  };

  // submit the form
  const handleOnSubmit = async () => {
    setDisableButton(true);
    switch (typeCall) {
      case "Crear":
        if (temaData.titulo !== "" && temaData.descripcion !== "") {
          setDisableButton(true);
          try {
            const payload = {
              idModulo: currentIdModulo,
              titulo: temaData.titulo,
              imageURL: temaData.imageURL,
              descripcion: temaData.descripcion,
              contenido: JSON.stringify({
                data: contenidoArray,
              }),
              estado: temaData.estado,
              onlyForRango: temaData.onlyForRango,
              onlyForPaquete: temaData.onlyForPaquete,
              onlyForTipo: temaData.onlyForTipo,
            };

            const response = await handleCreateTema(payload, file);

            swal({
              title: "EXITO!",
              text: `${response.message}`,
              icon: "success",
            }).then(() => {
              handleReset(Number(currentIdModulo));
            });
          } catch (error) {
            swal({
              title: "Error",
              text: `Hubo un error al crear el tema`,
              icon: "error",
            });
          } finally {
            setDisableButton(false);
          }
        }
        break;

      case "Editar":
        if (temaData.titulo !== "" && temaData.descripcion !== "") {
          try {
            const payload = {
              idmodulo: Number(temaData.idModulo),
              titulo: temaData.titulo,
              url_imagen: temaData.imageURL,
              descripcion: temaData.descripcion,
              contenido: JSON.stringify({
                data: contenidoArray,
              }),
              estado: temaData.estado,
              onlyForRango: temaData.onlyForRango,
              onlyForPaquete: temaData.onlyForPaquete,
              onlyForTipo: temaData.onlyForTipo,
            };

            await academy.temas.updateTema(payload, Number(temaData.idTema));

            swal({
              title: "EXITO!",
              text: `Se actualizo el tema`,
              icon: "success",
            }).then(() => {
              handleReset(Number(temaData.idModulo));
            });
          } catch (error) {
            swal({
              title: "Error",
              text: `Hubo un error al editar el tema`,
              icon: "error",
            });
          } finally {
            setDisableButton(false);
          }
        }
        break;

      default:
        break;
    }
  };

  // get selected data to edit
  React.useEffect(() => {
    if (typeCall === "Editar") {
      setTemaData({
        ...selectedTema,
      });
    }
  }, [selectedTema, typeCall]);

  // get the contenido array
  React.useEffect(() => {
    if (typeCall === "Editar") {
      const haveContenido = Object.keys(selectedTema.contenido).length > 0;
      if (haveContenido) {
        setContenidoArray(selectedTema.contenido.data);
      }
    }
    if (typeCall === "Crear") {
      setContenidoArray([]);
    }
  }, [selectedTema, typeCall]);

  // get the current modulo id
  React.useEffect(() => {
    if (Object.keys(searchParams).length > 0) {
      // moduloid
      const moduloID = searchParams.moduloId;
      setCurrentIdModulo(moduloID);
    }
  }, [searchParams]);

  return (
    <Modal
      show={isOpen}
      size="lg"
      onHide={() => {
        handleReset(Number(currentIdModulo));
      }}
    >
      <Modal.Header>
        <Modal.Title>{typeCall} capsula</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {typeCall === "Ver" ? (
          <TemaRead data={selectedTema} />
        ) : (
          <Tema
            data={temaData}
            typeCall={typeCall}
            onChange={handleOnChange}
            // upload photo
            previewFile={previewFile}
            onChangePhoto={handleUploadPhoto}
            // contenido
            contenidoArray={contenidoArray}
            tempContenido={tempContenido}
            setContenidoArray={setContenidoArray}
            handleResetContenidoTemp={handleResetContenido}
            handleOnChangeContenido={handleOnChangeContenido}
            handleAddContenido={handleAddContenido}
            handleDeleteContenido={handleDeleteContenido}
            handleUploadFileContenido={handleUploadFileContenido}
            files={files}
            setFiles={setFiles}
            uploadLoading={uploadLoading}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            handleReset(Number(currentIdModulo));
          }}
        >
          Cerrar
        </Button>
        {typeCall === "Ver" ? null : (
          <Button onClick={handleOnSubmit} disabled={disableButton}>
            {disableButton ? "Subiendo capsula..." : typeCall}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CreateEditTema;
