import React from "react";
import { Modal, Spinner } from "react-bootstrap";

import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../_metronic/helpers";

const ModalLoadingState = () => {
  const { isLoading } = useSelector((state: any) => state.app);
  const styles = {
    borderRadius: "0.475rem",
    boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
    backgroundColor: "#fff",
    color: "#7e8299",
    fontWeight: "500",
    margin: "0",
    width: "auto",
    padding: "6rem 1rem",
    top: "calc(50% - 2rem)",
    left: "calc(50% - 4rem)",
  };
  return (
    <Modal show={isLoading} size="lg" centered>
      <div
        style={{
          ...styles,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          alt="Logo"
          className="h-100px logo"
          src={toAbsoluteUrl("/media/logos/logo-color.png")}
        />
        <Spinner animation="border" variant="success" />
        <h4 className="ml-18">Cargando...</h4>
      </div>
    </Modal>
  );
};

export default ModalLoadingState;
