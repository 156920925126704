import React from "react";
import { Form, Container, Row, Col } from "react-bootstrap";

interface IProps {
  data: {
    nombre: string;
    slug: string;
  };
  typeCreateEdit?: string;
  onChange?: (e: any) => void;
}

const Categoria: React.FC<IProps> = ({ data, typeCreateEdit, onChange }) => {
  return (
    <>
      <Container>
        <Row>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre"
                name="nombre"
                value={data?.nombre}
                onChange={onChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Slug</Form.Label>
              <Form.Control
                type="text"
                placeholder="Slug"
                name="slug"
                disabled
                value={data?.slug}
              />
            </Form.Group>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Categoria;
