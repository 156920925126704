import React from "react";

// react-bootstrap
import {
  InputGroup,
  Form,
  Container,
  Row,
  Col,
  Button,
  Modal,
} from "react-bootstrap";

interface IpropsModal {
  data: any;
  open: boolean;
  closeModal: () => void;
}
const UserDataModal: React.FC<IpropsModal> = ({ data, open, closeModal }) => {
  const handleData = (data: any) => {
    const keys = Object.keys(data);

    return (
      <>
        <Container>
          <Row>
            {keys.map((el: string) => (
              <Col key={el} xs={6}>
                <InputGroup>
                  <InputGroup.Text>{el}</InputGroup.Text>
                  <Form.Control value={data[el]} disabled />
                </InputGroup>
              </Col>
            ))}
          </Row>
        </Container>
      </>
    );
  };

  return (
    <>
      <Modal size="lg" show={open} onHide={closeModal}>
        <Modal.Header>
          <Modal.Title>Datos del usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>{data && handleData(data)}</Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserDataModal;
