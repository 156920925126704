import React from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchModulo } from "../../../redux/academy/modulo";
import { startLoading, endLoading } from "../../../redux/app";
import { fetchTemasByModuloID } from "../../../redux/academy/temas";

// hooks
import useGetSearchParams from "../../../hooks/useGetSearchParams";

// services
import { academy } from "../../../services";

// components
import { KTCard, KTCardBody, KTSVG } from "../../../../_metronic/helpers";
import AcademyMainHeader from "./AcademyMainHeader";
import AcademyCard from "./AcademyCard";
import LoadingState from "../../../components/LoadingState";
import ModalLoadingState from "../../../components/ModalLoadingState";
import CreateEditTema from "./containers/modals/CreateEditTema";
import swal from "sweetalert";

interface ITema {
  idTema: number;
  idModulo: number;
  titulo: string;
  imageURL: string;
  descripcion: string;
  contenido: any;
  estado: boolean;
  onlyForRango: string;
  onlyForPaquete: string;
  onlyForTipo: string;
}

const AcademyModulo = () => {
  // states
  const [currentIdModulo, setCurrentIdModulo] = React.useState(0);
  // modal
  const [isOpenModal, setOpenModal] = React.useState(false);

  const [typeCall, setTypeCall] = React.useState<string>("");

  const [selectedTema, setSelectedTema] = React.useState<ITema>({
    idTema: 0,
    idModulo: 0,
    titulo: "",
    imageURL: "",
    descripcion: "",
    contenido: {},
    estado: false,
    onlyForRango: "",
    onlyForPaquete: "",
    onlyForTipo: "",
  });

  const dispatch = useDispatch();
  const moduloState = useSelector((state: any) => state.modulos);
  const temaState = useSelector((state: any) => state.temas);

  const datos: Array<any> = React.useMemo(
    () => temaState.temas,
    [temaState.temas]
  );

  // hooks
  const [searchParams]: any = useGetSearchParams();

  // functions
  const handleCreateTema = () => {
    setOpenModal(true);
    // new
    setTypeCall("Crear");
  };
  const handleUpdateTema = () => {
    dispatch(fetchTemasByModuloID(currentIdModulo));
    dispatch(fetchModulo(currentIdModulo));
  };

  const handleEditTema = (temaData: any) => {
    setOpenModal(true);
    // new
    setTypeCall("Editar");
    setSelectedTema({
      idTema: Number(temaData.id),
      idModulo: Number(temaData.idmodulo),
      titulo: temaData.titulo,
      imageURL: temaData.url_imagen,
      descripcion: temaData.descripcion,
      contenido: temaData.contenido,
      estado: temaData.estado,
      onlyForRango: temaData.onlyForRango,
      onlyForPaquete: temaData.onlyForPaquete,
      onlyForTipo: temaData.onlyForTipo,
    });
  };

  const handleDeleteTema = async (temaID: number) => {
    try {
      dispatch(startLoading());
      await academy.temas.deleteTema(temaID);
      swal("Exito!", "Se borro el tema", "success").then(() => {
        handleUpdateTema();
      });
    } catch (error) {
      swal("Error!", "hubo un error al borrar el tema", "error");
    } finally {
      dispatch(endLoading());
    }
  };

  const handleSeeTema = (temaData: any) => {
    setOpenModal(true);
    setTypeCall("Ver");

    setSelectedTema({
      idTema: Number(temaData.id),
      idModulo: Number(temaData.idmodulo),
      titulo: temaData.titulo,
      imageURL: temaData.url_imagen,
      descripcion: temaData.descripcion,
      contenido: temaData.contenido,
      estado: temaData.estado,
      onlyForRango: temaData.onlyForRango,
      onlyForPaquete: temaData.onlyForPaquete,
      onlyForTipo: temaData.onlyForTipo,
    });
  };

  // on close modal reset data
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedTema({
      idTema: 0,
      idModulo: 0,
      titulo: "",
      imageURL: "",
      descripcion: "",
      contenido: {},
      estado: false,
      onlyForRango: "",
      onlyForPaquete: "",
      onlyForTipo: "",
    });
  };

  React.useEffect(() => {
    if (Object.keys(searchParams).length > 0) {
      const moduloID = searchParams.moduloId;
      setCurrentIdModulo(Number(moduloID));
      dispatch(fetchModulo(moduloID));
      dispatch(fetchTemasByModuloID(moduloID));
    }
  }, [searchParams]);

  return (
    <div>
      {/* MODALS */}
      <ModalLoadingState />
      <CreateEditTema
        isOpen={isOpenModal}
        selectedTema={selectedTema}
        handleClose={handleCloseModal}
        typeCall={typeCall}
      />
      {/* MAIN */}
      <KTCard className="shadow-lg">
        <div className="border-0 pt-6 px-6 d-flex align-items-center justify-content-between">
          <div>
            <h1>Academia LHC</h1>
          </div>
          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-light-primary me-3"
              onClick={handleUpdateTema}
            >
              <KTSVG
                path="/media/icons/duotune/general/gen031.svg"
                className="svg-icon-2"
              />
              Actualizar
            </button>
            <button
              type="button"
              className="btn btn-success me-3"
              onClick={handleCreateTema}
            >
              <KTSVG
                path="/media/icons/duotune/files/fil011.svg"
                className="svg-icon-2"
              />
              Crear capsula
            </button>
          </div>
        </div>
        <div className="separator mx-1 my-4"></div>
        <AcademyMainHeader
          info={{
            id: moduloState.modulo.idmodulo,
            titulo: moduloState.modulo.titulo,
            urlImage: moduloState.modulo.url_imagen,
            fechaCreacion: moduloState.modulo.fecha_creacion,
            descripcion: moduloState.modulo.descripcion,
          }}
          loading={moduloState.isLoading}
          title="Tema"
        />
        <KTCardBody className="position-relative">
          <>
            {temaState.isLoading ? (
              <LoadingState />
            ) : (
              <>
                {datos.length > 0 ? (
                  <>
                    <div className={`row g-2`}>
                      {datos.map((el: any) => (
                        <div key={el.idtema} className="col-6 p-2">
                          <AcademyCard
                            type="Tema"
                            data={{
                              id: el.idtema,
                              idmodulo: el.idmodulo,
                              titulo: el.titulo,
                              url_imagen: el.url_imagen,
                              descripcion: el.descripcion,
                              fecha_creacion: el.fecha_creacion,
                              contenido: el.contenido,
                              estado: el.estado,
                              onlyForRango: el.onlyForRango,
                              onlyForPaquete: el.onlyForPaquete,
                              onlyForTipo: el.onlyForTipo,
                            }}
                            academyURL="#"
                            handleEdit={handleEditTema}
                            handleSee={handleSeeTema}
                            canDelete
                            handleDelete={handleDeleteTema}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <>no hay capsulas</>
                )}
              </>
            )}
          </>
        </KTCardBody>
      </KTCard>
    </div>
  );
};

export default AcademyModulo;
