import React from "react";
import { Link } from "react-router-dom";

// react-bootstrap
import { Form, Row, Col, InputGroup, Button } from "react-bootstrap";

// utils
import * as fns from "date-fns";
import { payments } from "../../../../services";
import swal from "sweetalert";

// components
import SelectConcepto from "../SelectConcepto";
import SearchReservas from "../modal/SearchCruceroModal";

interface Iprops {
  handleClose: () => void;
}

const listOfBookingTypes = [
  {
    id: 0,
    value: "Hotel",
    label: "Hotel",
  },
  {
    id: 2,
    value: "Travel Experiences",
    label: "Travel Experiences",
  },
  {
    id: 4,
    value: "Vol",
    label: "Vuelo",
  },
];

const PagoLibre: React.FC<Iprops> = ({ handleClose }) => {
  // states
  const [asociarReserva, setAsociarReserva] = React.useState(false);
  const [codReserva, setCodReserva] = React.useState("");
  const [tipoReserva, setTipoReserva] = React.useState("");
  const [slugConcepto, setSlugConcepto] = React.useState("");
  const [fechaLimite, setFechaLimite] = React.useState(
    fns.format(new Date(), "yyyy-MM-dd")
  );
  const [correoComprador, setCorreoComprador] = React.useState("");
  const [importe, setImporte] = React.useState(0.0);
  const [comentario, setComentario] = React.useState("");

  const [openSearchReserva, setOpenSearchReserva] = React.useState(false);

  // functions
  const handleOnChangeCorreoComprador = (e: any) => {
    setCorreoComprador(e.target.value);
  };

  const handleSelectTipoReserva = (e: any) => {
    setTipoReserva(e.target.value);
  };
  const handleSelectCodReserva = (item: any) => {
    setCodReserva(item.codReserva);
  };

  const handleOnChangeImporte = (e: any) => {
    setImporte(e.target.value);
    // setImporte();
  };

  const handleChangeConcepto = (e: any) => {
    setSlugConcepto(e.target.value);
  };

  const handleOpenSearchReserva = () => {
    setOpenSearchReserva(true);
  };

  const handleCloseSearchReserva = () => {
    setOpenSearchReserva(false);
  };

  const handleOnChangeComenatario = (e: any) => {
    setComentario(e.target.value);
  };

  const handleShowSubmitButton = () => {
    if (asociarReserva) {
      if (
        tipoReserva &&
        codReserva &&
        slugConcepto &&
        importe &&
        correoComprador
      ) {
        return true;
      }
      return false;
    } else {
      if (slugConcepto && importe && correoComprador) {
        return true;
      }
      return false;
    }
  };

  const handleSubmit = async () => {
    // fecha de creacion
    const dateNow = new Date();
    const fechaDeCreacion = fns.format(dateNow, "yyyy-MM-dd HH:mm:ss");

    const toSend = {
      codReserva: "",
      paymentPercentage: 0.0,
      paymentAmount: importe,
      createdDate: fechaDeCreacion,
      concepto: slugConcepto,
      limitDate: fechaLimite,
      buyerEmail: correoComprador,
      tipo: "LIBRE",
      comentario: comentario,
    };

    try {
      await payments.createPaymentRecord(toSend);
      // reset state

      swal({
        title: "Pago registrado",
        text: "El pago se ha registrado correctamente",
        icon: "success",
      }).then(() => {
        setSlugConcepto("");
        setFechaLimite(fns.format(new Date(), "yyyy-MM-dd"));
        setCorreoComprador("");
        setImporte(0.0);
        handleClose();
      });
    } catch (error) {
      swal({
        title: "Error",
        text: "Ha ocurrido un error al registrar el pago",
        icon: "error",
      });
    }
  };

  React.useEffect(() => {
    if (asociarReserva === false) {
      setTipoReserva("");
      setCodReserva("");
    }
  }, [asociarReserva]);

  return (
    <>
      <SearchReservas
        isOpen={openSearchReserva}
        handleClose={handleCloseSearchReserva}
        handleSelectReserva={handleSelectCodReserva}
        tipoReserva={tipoReserva}
      />
      <Row>
        <Col md={6} xs={12}>
          <InputGroup className="mb-3">
            <InputGroup.Text>Es para una reserva?</InputGroup.Text>
            <Form.Check
              className="my-auto mx-2"
              type="switch"
              id="asociarReserva"
              label={`${asociarReserva ? "Si" : "No"}`}
              checked={asociarReserva}
              onChange={(e) => setAsociarReserva(e.target.checked)}
            />
          </InputGroup>
        </Col>
        {asociarReserva && (
          <>
            <Col md={6} xs={12}>
              <InputGroup className="mb-3">
                <InputGroup.Text>Tipo de reserva</InputGroup.Text>
                <Form.Select
                  onChange={handleSelectTipoReserva}
                  value={tipoReserva}
                >
                  <option value="">Seleccione un tipo de reserva</option>
                  {listOfBookingTypes.map((item: any) => (
                    <option key={item.id} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Form.Select>
              </InputGroup>
            </Col>
            {tipoReserva !== "" && (
              <Col xs={12}>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Codigo de reserva</InputGroup.Text>
                  <InputGroup.Text>#</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Codigo de reserva"
                    disabled={true}
                    defaultValue={codReserva}
                  />
                  <Button variant="primary" onClick={handleOpenSearchReserva}>
                    Buscar crucero
                  </Button>
                </InputGroup>
              </Col>
            )}
          </>
        )}

        <Col xs={12}>
          <InputGroup className="mb-3">
            <InputGroup.Text>Concepto del pago</InputGroup.Text>
            <InputGroup.Text>#</InputGroup.Text>
            <SelectConcepto
              value={slugConcepto}
              onChange={handleChangeConcepto}
            />
          </InputGroup>
          <p>
            para agregar mas conceptos de pago ir{" "}
            <Link to="/option/payment-concept">aqui</Link>.
          </p>
        </Col>

        {slugConcepto !== "" && (
          <>
            <Col xs={12}>
              <InputGroup className="mb-3">
                <InputGroup.Text>Correo del comprador</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Correo del comprador"
                  value={correoComprador}
                  onChange={handleOnChangeCorreoComprador}
                />
              </InputGroup>
            </Col>

            <Col md={6} xs={12}>
              <InputGroup className="mb-3">
                <InputGroup.Text>Importe</InputGroup.Text>
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  placeholder="0.00"
                  value={importe}
                  onChange={handleOnChangeImporte}
                />
              </InputGroup>
            </Col>

            <Col md={6} xs={12}>
              <InputGroup className="mb-3">
                <InputGroup.Text>Fecha limite de pago</InputGroup.Text>
                <Form.Control
                  type="date"
                  value={fechaLimite}
                  onChange={(e) => {
                    setFechaLimite(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>

            <Col xs={12}>
              <InputGroup className="mb-3">
                <InputGroup.Text>Comentario</InputGroup.Text>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Comentario"
                  value={comentario}
                  onChange={handleOnChangeComenatario}
                />
              </InputGroup>
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col xs={12}>
          <Button
            variant="primary"
            type="submit"
            disabled={!handleShowSubmitButton()}
            onClick={handleSubmit}
          >
            Crear registro de pago
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PagoLibre;
