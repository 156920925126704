import React from "react";

import { Button, Dropdown } from "react-bootstrap";

// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchMarcas } from "../../redux/core";

// services
import { core } from "../../services";

// utils
import { KTCard, KTCardBody } from "../../../_metronic/helpers";
import swal from "sweetalert";

// components
import LoadingState from "../../components/LoadingState";

import CreateEditMarcas from "../../components/containers/modals/CreateEditMarcas";
import MarcaTable from "./components/tables/MarcaTable";

interface IMarca {
  idmarca: number;
  codigoMarca: string;
  nombreMarca: string;
  descripcionMarca: string;
  paises: Array<string>;
  imagenMarca: string;
  porcentajeRecompensa: number;
  estado: string;
  categoria: string;
}

const Marcas = () => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [typeCall, setTypeCall] = React.useState<string>("Crear");
  const [selectedMarca, setSelectedMarca] = React.useState<IMarca>({
    idmarca: 0,
    codigoMarca: "",
    nombreMarca: "",
    descripcionMarca: "",
    paises: [],
    imagenMarca: "",
    porcentajeRecompensa: 0,
    estado: "",
    categoria: "",
  });

  // redux
  const dispatch = useDispatch();
  const { marcas, isLoading } = useSelector((state: any) => state.core);

  // functions

  const handleRefreshMarcas = () => {
    dispatch(fetchMarcas());
  };

  const handleCreateMarca = () => {
    setShowModal(true);
    setTypeCall("Crear");
  };

  const handleEditMarcar = (marcaData: IMarca) => {
    setShowModal(true);
    setTypeCall("Editar");
    setSelectedMarca(marcaData);
  };

  const handleVerMarca = (marcaData: IMarca) => {
    setShowModal(true);
    setTypeCall("Ver");
    setSelectedMarca(marcaData);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDeleteMarca = async (idMarca: number) => {
    try {
      await core.marcas.deleteMarca(idMarca);
      swal("Success", "Marca se borro correctamente", "success").then(() => {
        dispatch(fetchMarcas());
      });
    } catch (error: any) {
      swal("Error", error.message, "error");
    }
  };

  React.useEffect(() => {
    dispatch(fetchMarcas());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <CreateEditMarcas
        isOpen={showModal}
        typeCall={typeCall}
        selectedMarca={selectedMarca}
        handleClose={handleCloseModal}
      />
      <KTCard>
        <div className="card-header">
          <div className="card-title">Marcas</div>
          <div className="card-toolbar">
            <Button variant="primary" onClick={handleRefreshMarcas}>
              Actualizar
            </Button>
            <Button variant="primary" onClick={handleCreateMarca}>
              Nueva Marca
            </Button>
          </div>
        </div>
        <KTCardBody className="py-4 position-relative">
          {isLoading ? (
            <LoadingState />
          ) : (
            <MarcaTable
              tableData={marcas}
              handleView={handleVerMarca}
              handleEdit={handleEditMarcar}
              handleDelete={handleDeleteMarca}
            />
          )}
        </KTCardBody>
      </KTCard>
    </div>
  );
};

export default Marcas;
