import { createSlice } from "@reduxjs/toolkit";
import { core } from "../services";

const initState = {
  userList: [],
  logList: [],
  isLoading: false,
  error: false,
  errorMessage: "",
};

const pushNotificationSlice = createSlice({
  name: "pushNotification",
  initialState: initState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setUserList: (state, action) => {
      state.userList = action.payload;
    },
    setLogsList: (state, action) => {
      state.logList = action.payload;
    },
  },
});

export const {
  setLoading,
  setError,
  setErrorMessage,
  setUserList,
  setLogsList,
} = pushNotificationSlice.actions;

export default pushNotificationSlice.reducer;

export const fetchUserList: any = () => async (dispatch: any) => {
  dispatch(setLoading(true));
  dispatch(setError(false));
  dispatch(setErrorMessage(""));
  try {
    const response = await core.lhcUser.lhcUserGetAll();
    dispatch(setUserList(response));
  } catch (error: any) {
    dispatch(setError(true));
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const fetchLogsList: any = () => async (dispatch: any) => {
  dispatch(setLoading(true));
  dispatch(setError(false));
  dispatch(setErrorMessage(""));
  try {
    const response = await core.firebase.getNotificationLogs();
    dispatch(setLogsList(response));
  } catch (error: any) {
    dispatch(setError(true));
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};
