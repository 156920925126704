import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { KTCard, KTCardBody, KTSVG } from "../../../_metronic/helpers";
import { fetchCursos } from "../../redux/academy/cursos";

// util

// components
import AcademyCard from "./components/AcademyCard";
import LoadingState from "../../components/LoadingState";
import CreateEditCurso from "./components/containers/modals/CreateEditCurso";

interface ICurso {
  idCurso: number;
  titulo: string;
  imageURL: string;
  descripcion: string;
  estado: boolean;
  onlyForRango: string;
  onlyForPaquete: string;
  onlyForTipo: string;
}

const Academy = () => {
  // states
  const [isOpen, setOpen] = React.useState(false);
  const [editCurso, setEditCurso] = React.useState(false);
  const [selectedCurso, setSelectedCurso] = React.useState<ICurso>({
    idCurso: 0,
    titulo: "",
    imageURL: "",
    descripcion: "",
    estado: false,
    onlyForRango: "",
    onlyForPaquete: "",
    onlyForTipo: "",
  });

  const dispatch = useDispatch();
  const { cursos, isLoading, error, errorMessage } = useSelector(
    (state: any) => state["cursos"]
  );

  const datos: Array<any> = React.useMemo(() => {
    return cursos;
  }, [cursos]);

  const handleClose = () => {
    setOpen(false);
    setEditCurso(false);
    setSelectedCurso({
      idCurso: 0,
      titulo: "",
      imageURL: "",
      descripcion: "",
      estado: false,
      onlyForRango: "",
      onlyForPaquete: "",
      onlyForTipo: "",
    });
  };

  const handleCrearCurso = () => {
    setOpen(true);
  };

  const handleEditCurso = (cursoData: any) => {
    setOpen(true);
    setEditCurso(true);
    setSelectedCurso({
      idCurso: cursoData.id,
      titulo: cursoData.titulo,
      imageURL: cursoData.url_imagen,
      descripcion: cursoData.descripcion,
      estado: cursoData.estado,
      onlyForRango: cursoData.onlyForRango,
      onlyForPaquete: cursoData.onlyForPaquete,
      onlyForTipo: cursoData.onlyForTipo,
    });
  };

  const handleUpdateCursos = () => {
    dispatch(fetchCursos());
  };

  React.useEffect(() => {
    dispatch(fetchCursos());
  }, []);

  return (
    <div>
      <CreateEditCurso
        isOpen={isOpen}
        selectedCurso={{
          ...selectedCurso,
          idCurso: Number(selectedCurso.idCurso),
        }}
        handleClose={handleClose}
        typeCall={editCurso === false ? "Crear" : "Editar"}
      />
      <KTCard>
        <div className="border-0 pt-6 px-6 d-flex align-items-center justify-content-between">
          <div>
            <h1>Academia LHC</h1>
          </div>
          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-light-primary me-3"
              onClick={handleUpdateCursos}
            >
              <KTSVG
                path="/media/icons/duotune/general/gen031.svg"
                className="svg-icon-2"
              />
              Actualizar
            </button>
            <button
              type="button"
              className="btn btn-success me-3"
              onClick={handleCrearCurso}
            >
              <KTSVG
                path="/media/icons/duotune/files/fil011.svg"
                className="svg-icon-2"
              />
              Crear modulo
            </button>
          </div>
        </div>
        <div className="separator mx-1 my-4"></div>
        <KTCardBody className="position-relative">
          {isLoading ? (
            <LoadingState />
          ) : (
            <>
              {datos.length > 0 ? (
                <div className={`row g-2`}>
                  {datos.map((el: any) => (
                    <div key={el.idcurso} className="col-4 p-2">
                      <AcademyCard
                        type="Curso"
                        data={{
                          id: el.idcurso,
                          titulo: el.titulo,
                          url_imagen: el.url_imagen,
                          descripcion: el.descripcion,
                          fecha_creacion: el.fecha_creacion,
                          estado: el.estado,
                          onlyForRango: el.onlyForRango,
                          onlyForPaquete: el.onlyForPaquete,
                          onlyForTipo: el.onlyForTipo,
                        }}
                        handleEdit={handleEditCurso}
                        handleSee={() => {}}
                        academyURL={`/academy/curso?cursoId=${el.idcurso}`}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  {error === true ? <>{errorMessage}</> : <>no hay modulos</>}
                </>
              )}
            </>
          )}
        </KTCardBody>
      </KTCard>
    </div>
  );
};

export default Academy;
