import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { MasterInit } from "../_metronic/layout/MasterInit";
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core";
import { I18nProvider } from "../_metronic/i18n/i18nProvider";

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <Outlet />
          <MasterInit />
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export default App;
