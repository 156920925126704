import React from "react";
import { Form, Pagination } from "react-bootstrap";

const TablePagination: React.FC<any> = (props) => {
  const {
    pageSize,
    setPageSize,
    gotoPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    pageIndex,
    nextPage,
    canNextPage,
    pageCount,
  } = props;

  return (
    <tfoot>
      <tr>
        <td>
          <Form.Select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Form.Select>
        </td>
        <td colSpan={2}>
          <Pagination>
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            {pageOptions.map((option: any, i: number) => (
              <Pagination.Item
                key={i}
                active={pageIndex === i}
                onClick={() => gotoPage(i)}
              >
                {option + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </td>
      </tr>
    </tfoot>
  );
};

export default TablePagination;
