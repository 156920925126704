const LHC_BACKEND_URL = process.env.REACT_APP_LHC_BACKEND_URL;
const LHC_ACADEMY_BACKEND_URL = process.env.REACT_APP_LHC_ACADEMY_URL;
const LHC_CORE_BACKEND_URL = process.env.REACT_APP_LHC_CORE_URL;
const AWS_API_NAME: string =
  process.env.REACT_APP_AWS_API_NAME !== undefined
    ? process.env.REACT_APP_AWS_API_NAME
    : "";

const config = {
  API: {
    NAME: AWS_API_NAME,
    LHC_BACKEND_URL,
    CORE_BACKEND: LHC_CORE_BACKEND_URL,
    ACADEMY_BACKEND: LHC_ACADEMY_BACKEND_URL,
    AUTH_BEARER: process.env.REACT_APP_BEARER_API,
  },
};

export default config;
