import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, ButtonGroup } from "react-bootstrap";

import { toAbsoluteUrl } from "../../../../_metronic/helpers";

interface Iprops {
  data: {
    id: number;
    idcurso?: number;
    idmodulo?: number;
    titulo: string;
    url_imagen: string;
    fecha_creacion: string;
    descripcion: string;
    contenido?: any;
    estado: boolean;
    onlyForRango: string;
    onlyForPaquete: string;
    onlyForTipo: string;
  };
  canDelete?: boolean;
  type: string;
  academyURL?: string;
  handleUpload?: (e: any) => void;
  handleEdit: (data: any) => void;
  handleDelete?: (id: number) => void;
  handleSee: (data: any) => void;
}

const AcademyCard: React.FC<Iprops> = ({
  type,
  data,
  data: {
    titulo,
    url_imagen,
    fecha_creacion,
    descripcion,
    contenido = {},
    estado = false,
    onlyForRango = "",
    onlyForPaquete = "",
    onlyForTipo = "",
  },
  canDelete = false,
  academyURL,
  handleEdit,
  handleSee,
  handleDelete,
}) => {
  const navigate = useNavigate();
  const getImageURL = () => {
    if (url_imagen !== '""' && url_imagen !== "") {
      return url_imagen;
    }
    return `${toAbsoluteUrl("/media/misc/pattern-1.jpg")}`;
  };

  const handleToSee = () => {
    if (type.toLowerCase() === "tema") {
      handleSee(data);
    } else {
      navigate(`${academyURL}`);
    }
  };

  return (
    <div className={`overflow-hidden rounded shadow-lg`}>
      <div
        className=" bgi-no-repeat bgi-size-cover bgi-position-center p-3"
        style={{
          backgroundImage: `url(${getImageURL()})`,
          backgroundColor: "#324ab0",
        }}
      >
        <h4
          className="text-white mb-3"
          style={{
            fontSize: "2rem",
          }}
        >
          {titulo}
        </h4>
        <p className="text-white">{fecha_creacion}</p>
      </div>
      <div className="py-6 px-3">
        <p>{descripcion}</p>
      </div>
      <div className="d-flex">
        <ButtonGroup className="flex-grow-1 ">
          <Button className="rounded-0" variant="success" onClick={handleToSee}>
            Ver
          </Button>
          <Button
            className="rounded-0"
            onClick={() => {
              handleEdit(data);
            }}
          >
            Editar
          </Button>
          {canDelete ? (
            <Button
              variant="danger"
              className="rounded-0"
              onClick={() => {
                if (handleDelete !== undefined) {
                  handleDelete(data.id);
                }
              }}
            >
              Borrar
            </Button>
          ) : null}
        </ButtonGroup>
      </div>
    </div>
  );
};

export default AcademyCard;
