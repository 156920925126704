import React from "react";
import { MenuItem } from "./MenuItem";
import { MenuInnerWithSub } from "./MenuInnerWithSub";
import { useIntl } from "react-intl";

export function MenuInner() {
  const intl = useIntl();
  return (
    <>
      <MenuItem
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        to="/dashboard"
      />
      <MenuInnerWithSub
        title="Apps"
        to="/apps"
        menuPlacement="bottom-start"
        menuTrigger="click"
      >
        {/* PAGES */}
        <MenuItem
          icon="/media/icons/duotune/general/gen051.svg"
          to="/apps/booking/booking"
          title="Booking"
        />
        <MenuItem
          icon="/media/icons/duotune/general/gen025.svg"
          to="/app/travelexperiences/travelExperiences"
          title="Travel Experiences"
        />
        <MenuItem
          icon="/media/icons/duotune/abstract/abs035.svg"
          to="/app/crusiers/crusiers"
          title="Cruceros"
        />
        <MenuItem
          icon="/media/icons/duotune/abstract/abs017.svg"
          to="/app/fly-booking/fly-booking"
          title="Vuelos"
        />
      </MenuInnerWithSub>
    </>
  );
}
