import React from "react";

import { useDispatch } from "react-redux";
import { fetchMarcas } from "../../../redux/core";
import { core } from "../../../services";
import s3 from "../../../../utils/s3Helper";

import { Modal, Button } from "react-bootstrap";

import swal from "sweetalert";

import MarcaForm from "../forms/Marcas";

interface IMarca {
  idmarca?: number;
  codigoMarca: string;
  nombreMarca: string;
  descripcionMarca: string;
  paises: Array<string>;
  imagenMarca: string;
  porcentajeRecompensa: number;
  estado: string;
  categoria: string;
}
interface Iprops {
  isOpen: boolean;
  selectedMarca: IMarca;
  handleClose: () => void;
  typeCall: string;
}

const CreateEditMarcas: React.FC<Iprops> = ({
  selectedMarca,
  isOpen,
  typeCall,
  handleClose,
}) => {
  const [marca, setMarca] = React.useState<IMarca>({
    idmarca: 0,
    nombreMarca: "",
    descripcionMarca: "",
    paises: [],
    imagenMarca: "",
    codigoMarca: "",
    porcentajeRecompensa: 0,
    estado: "inactivo",
    categoria: "",
  });
  const [disableButton, setDisableButton] = React.useState<boolean>(false);

  // foto
  const [previewFile, setPreviewFile] = React.useState<string>("");
  const [file, setFile] = React.useState<File>();

  const dispatch = useDispatch();

  const handleOnChange = (event: any) => {
    setMarca({
      ...marca,
      [event.target.name]: event.target.value,
    });
  };

  const handleOnChangeSelectPais = (data: any) => {
    setMarca({
      ...marca,
      paises: data,
    });
  };

  const resetMarca = () => {
    setMarca({
      nombreMarca: "",
      descripcionMarca: "",
      paises: [],
      imagenMarca: "",
      codigoMarca: "",
      porcentajeRecompensa: 0,
      estado: "inactivo",
      categoria: "",
    });
    setPreviewFile("");
  };

  const handleChangeFile = (event: any) => {
    const targetFile = event.target.files[0];
    const previewFile = URL.createObjectURL(targetFile);
    setFile(targetFile);
    setPreviewFile(previewFile);
  };

  const handleSubmit = async () => {
    setDisableButton(true);
    switch (typeCall) {
      case "Crear":
        if (file) {
          try {
            // upload file and get the fileName from s3
            const fileName = await s3.uploadFile(file);

            // create marca
            const payload: IMarca = {
              ...marca,
              imagenMarca: fileName,
            };

            await core.marcas.createMarca(payload);
            swal("¡Listo!", "Marca creado con éxito.", "success").then(() => {
              resetMarca();
              dispatch(fetchMarcas());
            });
          } catch (error) {
            console.log(error);
          } finally {
            resetMarca();
            handleClose();
            setDisableButton(false);
          }
        } else {
          swal("Warning", "El campo de archivo esta vacio", "warning");
        }
        break;
      case "Editar":
        try {
          await core.marcas.updateMarca(Number(marca.idmarca), marca);
          swal("¡Listo!", "Marca editado con éxito.", "success").then(() => {
            resetMarca();
            dispatch(fetchMarcas());
          });
        } catch (error) {
          console.log(error);
        } finally {
          resetMarca();
          handleClose();
          setDisableButton(false);
        }

        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    if (typeCall !== "Crear") {
      setMarca(selectedMarca);
    }
  }, [selectedMarca, typeCall]);

  return (
    <Modal show={isOpen} onHide={handleClose}>
      <Modal.Body>
        <MarcaForm
          data={marca}
          typeCall={typeCall}
          onChange={handleOnChange}
          onChangeFile={handleChangeFile}
          handleOnChangeSelectPais={handleOnChangeSelectPais}
          previewFile={previewFile}
        />
      </Modal.Body>
      <Modal.Footer>
        {typeCall !== "Ver" && (
          <>
            <Button
              onClick={() => {
                resetMarca();
                handleClose();
              }}
              disabled={disableButton}
            >
              Cancelar
            </Button>
            <Button disabled={disableButton} onClick={handleSubmit}>
              Guardar
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CreateEditMarcas;
