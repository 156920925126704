import React from "react";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

// redux
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../../../redux/auth";
import { GoogleLogin, GoogleLogout } from "react-google-login";

import swal from "sweetalert";

const googleID: any = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const GoogleButtonLogin = () => {
  // redux
  const dispatch = useDispatch();

  const responseGoogle = (response: any) => {
    const { profileObj, tokenId, accessToken } = response;
    const googleData = {
      profileObj,
      token: {
        tokenId,
        accessToken,
      },
    };
    swal("Login Success", "Welcome back!", "success");
    dispatch(login(googleData));
  };

  const responseGoogleFailure = (response: any) => {
    swal("Error", "Login failed", "error");
  };

  return (
    <GoogleLogin
      clientId={googleID}
      buttonText="Login"
      render={(renderProps) => (
        <button
          className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
        >
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/svg/brand-logos/google-icon.svg")}
            className="h-20px me-3"
          />
          Continuar con Google
        </button>
      )}
      onSuccess={responseGoogle}
      onFailure={responseGoogleFailure}
      cookiePolicy={"single_host_origin"}
    />
  );
};

const GoogleButtonLogout = () => {
  // redux
  const dispatch = useDispatch();

  const responseGoogleLogout = () => {
    swal("Logout Success", "See you soon!", "success");
    dispatch(logout());
  };

  const responseGoogleLogoutFailure = () => {
    swal("Error", "Logout failed", "error");
  };

  return (
    <GoogleLogout
      clientId={googleID}
      buttonText="Logout"
      onLogoutSuccess={responseGoogleLogout}
      onFailure={responseGoogleLogoutFailure}
    />
  );
};

const Login = () => {
  // redux
  const { isAuthenticated } = useSelector((state: any) => state.auth);
  return (
    <div className="form w-100">
      {/* begin::Heading */}
      <div className="text-center mb-10">
        <h1 className="text-white mb-3">Iniciar sesion</h1>
      </div>
      {/* begin::Heading */}

      {/* begin::Action */}
      <div className="text-center">
        {/* begin::Google link */}
        {isAuthenticated ? <GoogleButtonLogout /> : <GoogleButtonLogin />}
        {/* end::Google link */}
      </div>
      {/* end::Action */}
    </div>
  );
};

export default Login;
