import React from "react";
import { Dropdown } from "react-bootstrap";

import swal from "sweetalert";

import { useDispatch } from "react-redux";
import {
  deleteByID,
  fetchGetConceptos,
} from "../../../../../redux/payments/conceptoDePago";

interface IProps {
  dataTable: Array<any>;
  handleSelectEdit: (row: any) => void;
}

const PaymentConceptTable: React.FC<IProps> = ({
  dataTable,
  handleSelectEdit,
}) => {
  const dispatch = useDispatch();

  const handleDeleteRow = (rowID: number) => {
    swal("¿Estás seguro?", {
      buttons: ["Cancelar", "Aceptar"],
    }).then((value) => {
      if (value) {
        dispatch(deleteByID(rowID));
        swal(
          "¡Listo!",
          "Concepto de pago eliminado con éxito.",
          "success"
        ).then(() => {
          dispatch(fetchGetConceptos());
        });
      }
    });
  };

  return (
    <div className="table-responsive">
      <table className="table table-hover table-row-dashed fs-6 gy-5 table-sm dataTable align-middle">
        <thead>
          <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
            <th>ID</th>
            <th>slug</th>
            <th>Nombre</th>
            <th className="min-w-200px">Descripcion</th>
            <th>Acciones</th>
          </tr>
        </thead>

        <tbody className="text-gray-600 fw-bold">
          {dataTable.map((el: any) => (
            <tr key={el.id}>
              <td>{el.id}</td>
              <td>{el.slug}</td>
              <td>{el.nombre}</td>
              <td>{el.descripcion}</td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    Acciones
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleSelectEdit(el)}>
                      Editar
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleDeleteRow(Number(el.id))}
                    >
                      Eliminar
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentConceptTable;
