import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { fetchGetConceptos } from "../../../redux/payments/conceptoDePago";

import { Form } from "react-bootstrap";

interface Iprops {
  onChange: (e: any) => void;
  value: string;
}

const SelectConcepto: React.FC<Iprops> = ({ onChange, value }) => {
  const dispatch = useDispatch();
  const { conceptosDePago, loading } = useSelector(
    (state: any) => state.conceptoDePago
  );

  const conceptos = React.useMemo(() => conceptosDePago, [conceptosDePago]);

  React.useEffect(() => {
    dispatch(fetchGetConceptos());
  }, []);

  return (
    <>
      <Form.Select onChange={onChange} value={value}>
        <option value="">Seleccione un concepto</option>
        {conceptos.map((concepto: any) => (
          <option key={concepto.id} value={concepto.slug}>
            {concepto.nombre}
          </option>
        ))}
      </Form.Select>
    </>
  );
};

export default SelectConcepto;
