import React from "react";

import { Form, Container, Row, Col } from "react-bootstrap";

// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchPaises } from "../../../redux/paises";

// utils
import s3Helper from "../../../../utils/s3Helper";

// components
import UploadFile from "../../UploadFile";
import { MultiSelect } from "react-multi-select-component";
import SelectLHCCategorias from "../../SelectLHCCategorias";

interface IMarca {
  idmarca?: number;
  nombreMarca: string;
  descripcionMarca: string;
  paises: Array<any>;
  imagenMarca: string;
  porcentajeRecompensa: number;
  estado: string;
  categoria: string;
}

interface Iprops {
  data: IMarca;
  typeCall: string;
  onChange: (event: any) => void;
  onChangeFile: (event: any) => void;
  handleOnChangeSelectPais: (values: any) => void;
  previewFile: string;
}

const Marcas: React.FC<Iprops> = ({
  data,
  typeCall,
  onChange,
  onChangeFile,
  handleOnChangeSelectPais,
  previewFile,
}) => {
  const [fileURL, setFileURL] = React.useState<string>("");

  const dispatch = useDispatch();
  const { paises } = useSelector((state: any) => state.paises);

  const haveImage = (image: string) => {
    return image !== '""' && image !== "" ? true : false;
  };

  const paisesDatos = React.useMemo(
    () =>
      paises.map((el: any) => {
        return {
          label: `${el.nombrePais} - ${el.codigoPais}`,
          value: el.idpaises,
        };
      }),
    [paises]
  );

  const readOnly = typeCall === "Ver" ? true : false;

  React.useEffect(() => {
    if (typeCall !== "Crear" && haveImage(data.imagenMarca)) {
      const getFileURL = async () => {
        const url = await s3Helper.getFileURL(data.imagenMarca);
        setFileURL(url);
      };
      getFileURL();
    }
  }, [data.imagenMarca, typeCall]);

  React.useEffect(() => {
    if (typeCall !== "Ver") {
      dispatch(fetchPaises());
    }
  }, [typeCall]);

  return (
    <>
      <h3>{typeCall} Marca.</h3>
      <div className="separator border-3 mb-10" />
      <Container>
        <Row>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Nombre de la marca</Form.Label>
              <Form.Control
                type="text"
                placeholder="nombreMarca"
                name="nombreMarca"
                value={data.nombreMarca}
                onChange={onChange}
                readOnly={readOnly}
                disabled={readOnly}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Descripción de la marca</Form.Label>
              <Form.Control
                type="text"
                placeholder="descripcionMarca"
                name="descripcionMarca"
                value={data.descripcionMarca}
                onChange={onChange}
                readOnly={readOnly}
                disabled={readOnly}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            {haveImage(data.imagenMarca) ? (
              <>
                {/* TIENE IMAGEN MOSTRAR IMAGEN */}
                <img src={fileURL} width={"100%"} alt="vista previa" />
              </>
            ) : (
              <>
                <UploadFile
                  onChangeFile={onChangeFile}
                  previewFile={previewFile}
                />
              </>
            )}
          </Col>
          <Col xs={12}>
            {/* MULTI SELECT */}

            <Form.Group className="mb-3">
              <Form.Label>Paises</Form.Label>
              {typeCall === "Ver" ? (
                <div>
                  <Form.Label>
                    {data.paises.map((el: any) => el.label).join(" | ")}
                  </Form.Label>
                </div>
              ) : null}
              {typeCall !== "Ver" ? (
                <MultiSelect
                  options={paisesDatos}
                  value={data.paises}
                  onChange={handleOnChangeSelectPais}
                  labelledBy="Select"
                />
              ) : null}
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Porcentaje de la recompensa</Form.Label>
              <Form.Control
                type="text"
                placeholder="porcentajeRecompensa"
                value={data.porcentajeRecompensa}
                name="porcentajeRecompensa"
                onChange={onChange}
                readOnly={readOnly}
                disabled={readOnly}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Estado</Form.Label>
              {typeCall === "Ver" ? (
                <div>
                  <Form.Label>{data.estado}</Form.Label>
                </div>
              ) : (
                <Form.Select
                  name="estado"
                  value={data.estado}
                  onChange={onChange}
                >
                  <option value="activo">Activo</option>
                  <option value="inactivo">Inactivo</option>
                </Form.Select>
              )}
            </Form.Group>
          </Col>

          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Categoria</Form.Label>
              {typeCall === "Ver" ? (
                <Form.Label>{data.categoria}</Form.Label>
              ) : (
                <SelectLHCCategorias
                  handleOnChange={onChange}
                  value={data.categoria}
                />
              )}
            </Form.Group>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Marcas;
