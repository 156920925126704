import React, { Suspense, FC } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import TopBarProgress from "react-topbar-progress-indicator";

import DashboardPage from "../pages/dashboard/Dashboard";
// options
import OptionPage from "../pages/options/Option";
import OptionPaymentConcept from "../module/options/paymentConcept/PaymentConcept";
import OptionsCategorias from "../module/options/categorias/Categorias";
import AddPoints from "../module/options/addPoints/AddPoints";
// PushNotification
import * as PushNotification from "../module/options/pushNotification/PushNotification";
// shopping rewards
import OptionShoppingRewards from "../module/shoppingRewards";
import OptionShoppingRewardsPaises from "../module/shoppingRewards/Paises";
import OptionShoppingRewardsMarcas from "../module/shoppingRewards/Marcas";
import OptionShoppingRewardsTickets from "../module/shoppingRewards/Tickets";
// payments
import BillingPage from "../pages/billing/Billing";
import PaymentsPage from "../pages/billing/RegistroDePagos";
// academy
import AcademyPage from "../pages/academy/Academy";
import AcademyCursoPage from "../pages/academy/components/AcademyCurso";
import AcademyModuloPage from "../pages/academy/components/AcademyModulo";
// import ShoppingRewardPage from "../pages/shoppingRewards/ShoppingRewards";

// shop
import LHCSHOPPage from "../pages/LhcShop";

const PrivateRoutes = () => {
  const ReservasPages = React.lazy(() => import("../apps/reservas/Reservas"));
  return (
    <Routes>
      <Route path="/" element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="/auth/*" element={<Navigate to="/dashboard" />} />
        <Route path="dashboard" element={<DashboardPage />} />
        {/* OPTIONS */}
        <Route path="option" element={<OptionPage />} />
        <Route
          path="option/payment-concept"
          element={<OptionPaymentConcept />}
        />
        <Route path={"/option/categories"} element={<OptionsCategorias />} />
        <Route path={"/option/addPoints"} element={<AddPoints />} />

        {/* PUSH NOTIFICATION */}
        <Route
          path={"/push-notification/user-list"}
          element={<PushNotification.ListUser />}
        />
        <Route
          path={"/push-notification/send"}
          element={<PushNotification.SendNotification />}
        />
        <Route
          path={"/push-notification/logs"}
          element={<PushNotification.NotificationLog />}
        />

        {/* Shopping rewards */}
        <Route
          path="option/shoppingRewards"
          element={<OptionShoppingRewards />}
        />
        <Route
          path="/option/shoppingRewards/paises"
          element={<OptionShoppingRewardsPaises />}
        />
        <Route
          path="/option/shoopingRewards/marcas"
          element={<OptionShoppingRewardsMarcas />}
        />
        <Route
          path="/option/shoopingRewards/tickets"
          element={<OptionShoppingRewardsTickets />}
        />

        {/* ACADEMY */}
        <Route path="academy" element={<AcademyPage />} />
        <Route path="academy/curso" element={<AcademyCursoPage />} />
        <Route path="academy/modulo" element={<AcademyModuloPage />} />
        <Route path="lhcshop" element={<LHCSHOPPage />} />

        <Route path="billing" element={<BillingPage />} />
        <Route path="billing/pagos" element={<PaymentsPage />} />
        {/* Lazy Modules */}
        <Route
          path="app/reservas/*"
          element={
            <SuspensedView>
              <ReservasPages />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<{ children: React.ReactNode }> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export default PrivateRoutes;
