import React from "react";

import { Modal, Button } from "react-bootstrap";

// metronic
import { KTCard, KTCardBody } from "../../../../../_metronic/helpers";

// components
import BillingLogTable from "../table/BillingLogTable";

interface Iprops {
  isOpen: boolean;
  handleClose: any;
  data: any;
}

const BillingLog: React.FC<Iprops> = ({ isOpen, handleClose, data }) => {
  return (
    <Modal size="lg" show={isOpen} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Historial</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <KTCard>
          <KTCardBody>
            <BillingLogTable data={data} />
          </KTCardBody>
        </KTCard>
      </Modal.Body>
    </Modal>
  );
};

export default BillingLog;
