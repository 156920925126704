import React from "react";

import { Button } from "react-bootstrap";

// redux
import { useDispatch } from "react-redux";
import { getFetchListRegistroDePagos } from "../../redux/payments/registroDePagos";

import { KTCard, KTCardBody } from "../../../_metronic/helpers";
import PaymentRecords from "../../module/paymentRecords/PaymentRecords";

// components
import FormularioRegistroModal from "../../module/paymentRecords/components/modal/FormularioRegistroModal";

// poder crear un registro de pago.
const RegistroDePagos = () => {
  // states
  const [isOpen, setIsOpen] = React.useState(false);

  // redux
  const dispatch = useDispatch();

  // handlers
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => {
    setIsOpen(false);
    dispatch(getFetchListRegistroDePagos());
  };

  return (
    <>
      <FormularioRegistroModal isOpen={isOpen} onClose={handleClose} />

      <h1>Registro de pago</h1>

      <KTCard>
        <KTCardBody>
          <div className="d-flex align-items-center justify-content-between">
            <Button
              onClick={() => {
                dispatch(getFetchListRegistroDePagos());
              }}
            >
              Actualizar
            </Button>
            <button
              className="rounded-circle border-0 bg-primary hoverable fs-1 text text-light px-4"
              onClick={handleOpen}
            >
              +
            </button>
          </div>
          <PaymentRecords />
        </KTCardBody>
      </KTCard>
    </>
  );
};

export default RegistroDePagos;
