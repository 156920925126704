import React from "react";

import { Form } from "react-bootstrap";

// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchTipos } from "../redux/core";

interface Iprops {
  handleOnChange: (e: any) => void;
  value: string;
}

const SelectLHCTipos: React.FC<Iprops> = ({ handleOnChange, value }) => {
  const dispatch = useDispatch();
  const { tipos } = useSelector((state: any) => state.core);
  const datos: Array<any> = React.useMemo(() => tipos, [tipos]);

  React.useEffect(() => {
    dispatch(fetchTipos());
  }, []);

  return (
    <>
      <Form.Select
        id="onlyForTipo"
        name="onlyForTipo"
        onChange={handleOnChange}
        value={value}
      >
        <option value="">Seleccione un tipo</option>
        {datos.map((el: any) => (
          <option key={el.idlhctipo} value={el.titulo}>
            {el.titulo}
          </option>
        ))}
      </Form.Select>
    </>
  );
};

export default SelectLHCTipos;
