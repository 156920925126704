import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

//
import { MultiSelect } from "react-multi-select-component";
import swal from "sweetalert";
import * as fns from "date-fns";

// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchUserList } from "../../../redux/pushNotification";
import { startLoading, endLoading } from "../../../redux/app";

// metronic
import { KTCard, KTCardBody } from "../../../../_metronic/helpers";

// services
import { core } from "../../../services";

// components
import ModalLoadingState from "../../../components/ModalLoadingState";
import { format } from "path";

interface IlhcUser {
  idlhcusuario: number;
  idusuario: string;
  uuidFirebase: string;
  userData: any;
}

const PushNotificationSendNotification = () => {
  // const [lhcUser, setLhcUser] = React.useState<Array<IlhcUser>>([]);
  const [selectedUser, setSelectedUser] = React.useState<Array<any>>([]);
  // const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [form, setForm] = React.useState({
    title: "",
    content: "",
    descripcion: "",
    fecha: "",
    enlace: "",
    lugar: "",
    otro: "",
  });

  const dispatch = useDispatch();
  const pushState = useSelector((state: any) => state.pushNotification);
  const { isLoading } = useSelector((state: any) => state.app);

  const listOfUser = React.useMemo(() => {
    return pushState.userList.map((el: IlhcUser) => {
      return {
        id: el.idusuario,
        label: `${el.userData.nombre} ${el.userData.apellido}`,
        value: el.uuidFirebase,
      };
    });
  }, [pushState.userList]);

  const handleSelectUser = (data: any) => {
    setSelectedUser(data);
  };

  const handleRefreshData = () => {
    dispatch(fetchUserList());
  };

  const handleOnChangeForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    // if (name === "fecha") {
    //   const dateFormat = "yyyy-MM-dd HH:mm:ss";
    //   const formattedDate = fns.format(new Date(value), dateFormat);
    //   setForm((prevFormData) => ({
    //     ...prevFormData,
    //     [name]: formattedDate,
    //   }));
    // } else {
    //   setForm((prevFormData) => ({
    //     ...prevFormData,
    //     [name]: value,
    //   }));
    // }
  };

  const handleSendNotification = async () => {
    if (form.content !== "" && form.title !== "") {
      dispatch(startLoading());
      if (selectedUser.length > 0) {
        const message = {
          title: form.title,
          body: form.content,
        };
        const extra = {
          fecha:
            form.fecha !== ""
              ? fns.format(new Date(form.fecha), "yyyy-MM-dd HH:mm:ss")
              : "",
          descripcion: form.descripcion,
          enlace: form.enlace,
          lugar: form.lugar,
          otro: form.otro,
        };
        try {
          // max arrays
          const totalArrays = selectedUser.length;
          const maxSubArray = 25;
          const subArrays = [];

          for (let i = 0; i < selectedUser.length; i += maxSubArray) {
            const sub = selectedUser.slice(i, i + maxSubArray);
            subArrays.push(sub);
          }

          // send
          for (let i = 0; i < subArrays.length; i++) {
            const listOftokens: Array<string> = subArrays[i].reduce(
              (acc, item) => {
                acc.push(item.value);
                return acc;
              },
              []
            );
            const listOfUserID: Array<string> = subArrays[i].reduce(
              (acc, item) => {
                acc.push(item.id);
                return acc;
              },
              []
            );

            await core.firebase.sendMulticastNotification(
              listOftokens,
              message,
              listOfUserID,
              extra
            );
          }

          swal("Exito!", `Se enviaron: ${totalArrays} mensajes!`, "success");
        } catch (error) {
          console.log(error);
          console.log("oh");
        } finally {
          dispatch(endLoading());
        }
      }
    }
  };

  React.useEffect(() => {
    dispatch(fetchUserList());
  }, []);

  return (
    <div>
      <ModalLoadingState />
      <KTCard>
        <div className="card-header">
          <div className="card-title">Enviar Notificacion</div>
          <div className="card-toolbar"></div>
        </div>
        <KTCardBody>
          <Container>
            <Row>
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Titulo</Form.Label>
                  <Form.Control
                    name="title"
                    type="text"
                    placeholder="Titulo"
                    value={form.title}
                    onChange={handleOnChangeForm}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Contenido</Form.Label>
                  <Form.Control
                    name="content"
                    as="textarea"
                    rows={3}
                    placeholder="contenido ..."
                    value={form.content}
                    onChange={handleOnChangeForm}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Descripcion</Form.Label>
                  <Form.Control
                    name="descripcion"
                    as="textarea"
                    rows={3}
                    placeholder="contenido ..."
                    value={form.descripcion}
                    onChange={handleOnChangeForm}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Fecha</Form.Label>
                  <Form.Control
                    type="date"
                    name="fecha"
                    value={form.fecha}
                    onChange={handleOnChangeForm}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Lugar</Form.Label>
                  <Form.Control
                    name="lugar"
                    placeholder="contenido ..."
                    value={form.lugar}
                    onChange={handleOnChangeForm}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Enlace</Form.Label>
                  <Form.Control
                    name="enlace"
                    placeholder="contenido ..."
                    value={form.enlace}
                    onChange={handleOnChangeForm}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Otro</Form.Label>
                  <Form.Control
                    name="otro"
                    placeholder="contenido ..."
                    value={form.otro}
                    onChange={handleOnChangeForm}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Destino</Form.Label>
                  <div className="d-flex align-items-center flex-grow-1">
                    <MultiSelect
                      className="flex-grow-1 me-2"
                      options={listOfUser}
                      value={selectedUser}
                      labelledBy="Select"
                      onChange={handleSelectUser}
                    />
                    <Button
                      disabled={pushState.isLoading}
                      onClick={handleRefreshData}
                    >
                      Actualizar
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Button disabled={isLoading} onClick={handleSendNotification}>
                  Enviar notificacion
                </Button>
              </Col>
            </Row>
          </Container>
        </KTCardBody>
      </KTCard>
    </div>
  );
};

export default PushNotificationSendNotification;
