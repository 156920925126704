import React from "react";
import { Form } from "react-bootstrap";

const DefaultColumnFilter: React.FC<any> = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length;

  return (
    <>
      <Form.Control
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
      <Form.Text muted>{`Search ${count} records...`}</Form.Text>
    </>
  );
};

export default DefaultColumnFilter;
