import React from "react";

import { Dropdown } from "react-bootstrap";

// react table
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import { marcasColumn } from "./columns/_columns";
import DefaultColumnFilter from "./columns/DefaultColumnFilter";
import CustomColumnHeader from "./columns/CustomColumnHeader";

import TablePagination from "../../../../components/table/TablePagination";

interface IMarca {
  idmarca: number;
  codigoMarca: string;
  nombreMarca: string;
  descripcionMarca: string;
  paises: Array<string>;
  imagenMarca: string;
  porcentajeRecompensa: number;
  estado: string;
  categoria: string;
}

interface IProps {
  tableData: Array<IMarca>;
  handleEdit: (data: IMarca) => void;
  handleView: (data: IMarca) => void;
  handleDelete: (id: number) => void;
}

const MarcaTable: React.FC<IProps> = ({
  tableData,
  handleView,
  handleEdit,
  handleDelete,
}) => {
  // hooks
  const marcasData = React.useMemo(() => tableData, [tableData]);

  // react-table
  const columns = React.useMemo(() => marcasColumn, []);
  const defaultColumn: any = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const tablehooks = (hooks: any) => {
    hooks.visibleColumns.push((columns: any) => [
      ...columns,
      {
        id: "paises",
        Header: "Paises",
        Cell: ({ row }: any) => (
          <>
            {`${row.original.paises
              .map((el: any) => el.label)
              .join(" | ")
              .substr(0, 100)} ...`}
          </>
        ),
      },
      {
        id: "actions",
        Header: "Acciones",
        Cell: ({ row }: any) => (
          <Dropdown>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
              Acciones
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  handleView(row.original);
                }}
              >
                Ver
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  handleEdit(row.original);
                }}
              >
                Editar
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  handleDelete(row.original.idmarca);
                }}
              >
                Eliminar
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // handlers
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: marcasData,
      defaultColumn,
      initialState: { pageIndex: 0 },
    },

    tablehooks,
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <div className="table-responsive">
      <table
        className="table table-hover table-row-dashed fs-6 gy-5 table-sm dataTable align-middle m-10"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column: any) => (
                <CustomColumnHeader
                  {...column.getHeaderProps()}
                  column={column}
                />
              ))}
            </tr>
          ))}
        </thead>

        <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
          {page.length > 0 ? (
            page.map((row: any, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={7}>
                <div className="d-flex text-center w-100 align-content-center justify-content-center">
                  No matching records found
                </div>
              </td>
            </tr>
          )}
        </tbody>
        {pageOptions.length > 0 && (
          <TablePagination
            pageSize={pageSize}
            setPageSize={setPageSize}
            gotoPage={gotoPage}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            pageOptions={pageOptions}
            pageIndex={pageIndex}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
          />
        )}
      </table>
    </div>
  );
};

export default MarcaTable;
