import React, { FC } from "react";
import clsx from "clsx";

interface Iprop {
  column: any;
}

const CustomColumnHeader: FC<Iprop> = ({ column }) => {
  return (
    <th>
      <div
        className={clsx(
          "min-w-200px",
          column.isSorted
            ? column.isSortedDesc
              ? `table-sort-desc`
              : `table-sort-asc`
            : ""
        )}
        {...column.getHeaderProps(column.getSortByToggleProps())}
      >
        {column.render("Header")}
      </div>
      <div className="min-w-200px">
        <div>{column.canFilter ? column.render("Filter") : null}</div>
      </div>
    </th>
  );
};

export default CustomColumnHeader;
