const objHaveKey = (obj: any, key: string) => {
  return Object.keys(obj).includes(key);
};

const getCrucero = (data: any) => {
  const bookingData = data.data;
  const userData = bookingData.userData;
  const nombreCompleto: string = `${userData.nombre} ${userData.apellido1} ${userData.apellido2}`;
  const idCliente: string = data.idCliente;
  const codReserva: string = data.codReserva;
  const fechaReserva: string = bookingData.datosReserva.fechaReserva;

  const newData = {
    codReserva,
    idCliente,
    nombreCompleto,
    importeCrucero: bookingData.datosReserva.importeReserva,
    fechaReserva,
  };

  return newData;
};

const getReservaGeneral = (data: any) => {
  // getting crusiers
  const bookingData = data.data;
  const userData = bookingData.userData;
  const nombreCompleto: string = `${userData.nombre} ${userData.apellido1} ${userData.apellido2}`;
  const idCliente: string = data.idCliente;
  const codReserva: string = data.codReserva;
  const fechaReserva: string = bookingData.datosReserva.fechaReserva;
  const tipoReserva: string = data.data.datosReserva.tipoReserva;

  const newData = {
    codReserva,
    idCliente,
    nombreCompleto,
    fechaReserva,
    tipoReserva,
  };

  return newData;
};

export const createData = (bookingList: Array<any>) => {
  const result = bookingList.reduce((acc: any, item: any) => {
    if (!objHaveKey(item.data, "userData")) {
      return acc;
    }
    // verify if booking state is available
    if (item.data.datosReserva.estado === "Cancelada") {
      return acc;
    }

    if (item.data.datosReserva.tipoReserva !== "Crucero") {
      const reserva = getReservaGeneral(item);
      acc.push(reserva);
      return acc;
    } else {
      const crucero = getCrucero(item);
      acc.push(crucero);
      return acc;
    }
  }, []);

  return result;
};
