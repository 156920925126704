import React from "react";

import { Form } from "react-bootstrap";

// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchCategorias } from "../redux/categorias";

interface Iprops {
  handleOnChange: (e: any) => void;
  value: string;
}

const SelectLHCCategorias: React.FC<Iprops> = ({ handleOnChange, value }) => {
  const dispatch = useDispatch();
  const { categorias } = useSelector((state: any) => state.categorias);
  const datos: Array<any> = React.useMemo(() => categorias, [categorias]);

  React.useEffect(() => {
    dispatch(fetchCategorias());
  }, []);

  return (
    <>
      <Form.Select
        id="categoria"
        name="categoria"
        onChange={handleOnChange}
        value={value}
      >
        <option value="">Seleccione un tipo</option>
        {datos.map((el: any) => (
          <option key={el.idcategoria} value={el.nombre}>
            {el.nombre}
          </option>
        ))}
      </Form.Select>
    </>
  );
};

export default SelectLHCCategorias;
