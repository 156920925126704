import React from "react";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";

// components
import SelectRango from "../../../../../components/SelectLHCRangos";
import SelectPaquete from "../../../../../components/SelectLHCPaquetes";
import SelectTipo from "../../../../../components/SelectLHCTipos";
import UploadFile from "../../../../../components/UploadFile";
import s3 from "../../../../../../utils/s3Helper";

interface IModulo {
  titulo: string;
  imageURL: string;
  descripcion: string;
  estado: boolean;
  onlyForRango: string;
  onlyForPaquete: string;
  onlyForTipo: string;
}
interface Iprops {
  data: IModulo;
  typeCall: string;
  onChange: (e: any) => void;
  onChangeFile: (e: any) => void;
  previewFile: string;
}

const Modulo: React.FC<Iprops> = ({
  data,
  onChange,
  onChangeFile,
  previewFile,
  typeCall,
}) => {
  // states
  const [fileURL, setFileURL] = React.useState<string>("");

  const haveImage = (image: string) =>
    image !== '""' && image !== "" ? true : false;

  React.useEffect(() => {
    if (typeCall !== "Crear") {
      const getFileURL = async () => {
        const url = await s3.getFileURL(data.imageURL);
        setFileURL(url);
      };
      getFileURL();
    }
  }, [typeCall, data.imageURL]);
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <InputGroup className="mb-3">
            <InputGroup.Text>Esta activo?</InputGroup.Text>
            <Form.Check
              className="my-auto mx-2"
              type="switch"
              id="estado"
              name="estado"
              label={`${data.estado ? "Si" : "No"}`}
              checked={data.estado}
              onChange={onChange}
            />
          </InputGroup>
        </Col>
        <Col xs={12}>
          <Form.Group className="mb-3">
            <Form.Label>Titulo</Form.Label>
            <Form.Control
              type="text"
              placeholder="Titulo del modulo"
              name="titulo"
              value={data.titulo}
              onChange={onChange}
              required
            />
          </Form.Group>
        </Col>

        {/* UPLOAD FILE */}
        <Col xs={12}>
          {haveImage(data.imageURL) ? (
            <>
              {/* TIENE IMAGEN MOSTRAR IMAGEN */}
              <img src={fileURL} width={"100%"} alt="vista previa" />
            </>
          ) : (
            <>
              <UploadFile
                onChangeFile={onChangeFile}
                previewFile={previewFile}
              />
            </>
          )}
        </Col>

        <Col xs={12}>
          <Form.Group className="mb-3">
            <Form.Label>Descripcion</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Descripcion del modulo"
              name="descripcion"
              value={data.descripcion}
              onChange={onChange}
              required
            />
          </Form.Group>
        </Col>
        <h3>Restricciones</h3>
        <div className="separator mx-1 my-4"></div>
        <Col xs={12}>
          <Form.Group className="mb-3">
            <Form.Label>Restricciones por rango</Form.Label>
            <SelectRango handleOnChange={onChange} value={data.onlyForRango} />
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group className="mb-3">
            <Form.Label>Restricciones por paquete</Form.Label>
            <SelectPaquete
              handleOnChange={onChange}
              value={data.onlyForPaquete}
            />
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group className="mb-3">
            <Form.Label>Restricciones por tipo</Form.Label>
            <SelectTipo handleOnChange={onChange} value={data.onlyForTipo} />
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

export default Modulo;
