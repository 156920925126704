import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import s3 from "../../../../utils/s3Helper";
import { areIntervalsOverlappingWithOptions } from "date-fns/esm/fp";

interface Iprops {
  title: string;
  loading: boolean;
  info: {
    id: string;
    urlImage: string;
    titulo: string;
    descripcion: string;
    fechaCreacion: string;
  };
}

const AcademyMainHeader: React.FC<Iprops> = ({ title, info }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="d-flex flex-center bg-dark pt-3 position-relative">
        <Button
          className="position-absolute"
          style={{
            top: "50%",
            left: 5,
            transform: "translateY(-50%)",
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          Atras
        </Button>
        <h4
          className="fw-bold text-white mb-3"
          style={{
            fontSize: "3.5rem",
          }}
        >
          {title}
        </h4>
      </div>
      <div
        className="d-flex flex-column flex-center bgi-no-repeat bgi-size-cover bgi-position-center px-9 pt-10 pb-10"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/misc/pattern-1.jpg")})`,
          backgroundColor: "#324ab0",
        }}
      >
        <h4
          className="text-white fw-bold mb-3"
          style={{
            fontSize: "3.5rem",
          }}
        >
          {info.titulo}
        </h4>

        <h5 className="text-white mb-3">{info.descripcion}</h5>
      </div>
      <div className="bg-dark d-flex flex-center pt-2">
        <p className="text-white">{info.fechaCreacion}</p>
      </div>
    </>
  );
};

export default AcademyMainHeader;
