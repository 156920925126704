import React from "react";
import { Modal, Button } from "react-bootstrap";

// redux
import { useDispatch } from "react-redux";
import { fetchCursos } from "../../../../../redux/academy/cursos";
import { academy } from "../../../../../services";
import swal from "sweetalert";

import { Curso } from "../forms";
import { handleCreateCurso } from "../../../../../../utils/academyHelper";

interface ICurso {
  idCurso: number;
  titulo: string;
  imageURL: string;
  descripcion: string;
  estado: boolean;
  onlyForRango: string;
  onlyForPaquete: string;
  onlyForTipo: string;
}

interface Iprops {
  isOpen: boolean;
  selectedCurso: ICurso;
  typeCall: string;
  handleClose: () => void;
}

const CreateEditCurso: React.FC<Iprops> = ({
  isOpen,
  handleClose,
  typeCall,
  selectedCurso,
}) => {
  // states
  const [disableButton, setDisableButton] = React.useState(false);

  // foto
  const [previewFile, setPreviewFile] = React.useState<string>("");
  const [file, setFile] = React.useState<File>();

  const [cursoData, setCursoData] = React.useState<ICurso>({
    idCurso: 0,
    titulo: "",
    imageURL: "",
    descripcion: "",
    estado: false,
    onlyForRango: "",
    onlyForPaquete: "",
    onlyForTipo: "",
  });

  const dispatch = useDispatch();

  // reset data
  const handleReset = () => {
    setCursoData({
      idCurso: 0,
      titulo: "",
      imageURL: "",
      descripcion: "",
      estado: false,
      onlyForRango: "",
      onlyForPaquete: "",
      onlyForTipo: "",
    });
    setPreviewFile("");
  };

  const HandleOnChange = (e: any) => {
    setCursoData({
      ...cursoData,
      [e.target.name]:
        e.target.name === "estado" ? e.target.checked : e.target.value,
    });
  };

  const handleChangeFile = (event: any) => {
    const targetFile = event.target.files[0];
    const previewFile = URL.createObjectURL(targetFile);
    setFile(targetFile);
    setPreviewFile(previewFile);
  };

  const handleOnSubmit = async () => {
    setDisableButton(true);
    switch (typeCall) {
      case "Crear":
        if (cursoData.titulo !== "" && cursoData.descripcion !== "") {
          try {
            // create curso
            const response = await handleCreateCurso(cursoData, file);

            if (response.created === false) {
              throw new Error();
            }

            swal({
              title: "EXITO!",
              text: `${response.message}`,
              icon: "success",
            }).then(() => {
              dispatch(fetchCursos());
              handleReset();
              handleClose();
            });
          } catch (error) {
            swal({
              title: "Error",
              text: `Hubo un error al crear el curso`,
              icon: "error",
            });
          } finally {
            setDisableButton(false);
          }
        } else {
          swal({
            title: "Cuidado!",
            text: `Tienes que completar el formulario!`,
            icon: "warning",
          });
        }
        break;
      case "Editar":
        if (cursoData.titulo !== "" && cursoData.descripcion !== "") {
          setDisableButton(true);
          try {
            await academy.curso.updateCurso(
              {
                titulo: cursoData.titulo,
                url_imagen: cursoData.imageURL,
                descripcion: cursoData.descripcion,
                estado: cursoData.estado,
                onlyForRango: cursoData.onlyForRango,
                onlyForPaquete: cursoData.onlyForPaquete,
                onlyForTipo: cursoData.onlyForTipo,
              },
              Number(cursoData.idCurso)
            );

            swal({
              title: "EXITO!",
              text: `Se Actualizo el curso`,
              icon: "success",
            }).then(() => {
              dispatch(fetchCursos());
              handleReset();
              handleClose();
            });
          } catch (error) {
            swal({
              title: "Error",
              text: `Hubo un error al editar el curso`,
              icon: "error",
            });
          } finally {
            setDisableButton(false);
          }
        } else {
          swal({
            title: "Cuidado!",
            text: `Tienes que completar el formulario!`,
            icon: "warning",
          }).then(() => {setDisableButton(false)});
        }
        break;

      default:
        break;
    }
  };

  React.useEffect(() => {
    setCursoData({
      ...selectedCurso,
      idCurso: selectedCurso.idCurso,
    });
  }, [selectedCurso]);

  return (
    <Modal
      show={isOpen}
      size="lg"
      onHide={() => {
        handleClose();
        handleReset();
      }}
    >
      <Modal.Header>
        <Modal.Title>{typeCall} Modulo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Curso
          data={cursoData}
          edit={typeCall === "Crear" ? true : false}
          typeCall={typeCall}
          onChange={HandleOnChange}
          // upload photo
          onChangeFile={handleChangeFile}
          previewFile={previewFile}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleOnSubmit} disabled={disableButton}>
          {typeCall}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateEditCurso;
