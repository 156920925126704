import { createSlice } from "@reduxjs/toolkit";
import { academy } from "../../services";

const initState = {
  cursos: [],
  curso: {
    titulo: "",
    fechaCreacion: "",
    estado: "",
  },
  isLoading: false,
  error: false,
  errorMessage: "",
};

const cursosSlice = createSlice({
  name: "cursos",
  initialState: initState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setCursos: (state, action) => {
      state.cursos = action.payload;
    },
    setCurso: (state, action) => {
      state.curso = action.payload;
    },
  },
});

export const { setCursos, setCurso, setLoading, setError, setErrorMessage } =
  cursosSlice.actions;
export default cursosSlice.reducer;

export const fetchCursos: any = () => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    dispatch(setErrorMessage(""));
    try {
      const response = await academy.curso.getCursos();
      dispatch(setCursos(response.cursos));
    } catch (error: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchCurso: any = (idCurso: number) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    dispatch(setErrorMessage(""));
    try {
      const response = await academy.curso.getCurso(idCurso);
      dispatch(setCurso(response));
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
};
