import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { fetchCategorias } from "../../../redux/categorias";

// services
import { core } from "../../../services";

import { Modal, Button } from "react-bootstrap";

// util
import swal from "sweetalert";

// components
import CategoriaForm from "../forms/Categorias";

interface Iprops {
  isOpen: boolean;
  typeCreateEdit: string;
  handleClose: () => void;
}

const CreateEditCategoria: React.FC<Iprops> = ({
  isOpen,
  typeCreateEdit,
  handleClose,
}) => {
  const [nombre, setNombre] = React.useState("");
  const [slug, setSlug] = React.useState("");

  const dispatch = useDispatch();
  const { selectedCategoria } = useSelector((state: any) => state.categorias);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case "nombre":
        setNombre(value);
        // lower case no space slug
        setSlug(value.toLowerCase().replace(/\s/g, "_"));
        break;
      default:
        break;
    }
  };

  const resetValues = () => {
    setNombre("");
    setSlug("");
  };

  const handleSubmit = async () => {
    switch (typeCreateEdit) {
      case "Crear":
        try {
          const payload = {
            nombre: nombre,
            slug: slug,
          };
          await core.categorias.createCategoria(payload);

          swal("¡Listo!", "Concepto de pago creado con éxito.", "success").then(
            () => {
              dispatch(fetchCategorias());
              resetValues();
              handleClose();
            }
          );
        } catch (error) {
          swal("Error", "Hubo un error al crear categoria", "error");
        }

        break;
      case "Editar":
        try {
          const payload = {
            nombre: nombre,
            slug: slug,
          };
          const idCategoria = selectedCategoria.idcategoria;

          await core.categorias.updateCategoria(idCategoria, payload);

          swal(
            "¡Listo!",
            "Concepto de pago editado con éxito.",
            "success"
          ).then(() => {
            dispatch(fetchCategorias());
            resetValues();
            handleClose();
          });
        } catch (error) {
          swal("Error", "Hubo un error al crear categoria", "error");
        }

        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    if (typeCreateEdit === "Crear") {
      setNombre("");
      setSlug("");
    }
    if (typeCreateEdit === "Editar") {
      if (selectedCategoria) {
        setNombre(selectedCategoria.nombre);
        setSlug(selectedCategoria.slug);
      }
    }
  }, [selectedCategoria, typeCreateEdit]);

  return (
    <Modal show={isOpen} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Categoria</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CategoriaForm
          data={{
            nombre,
            slug,
          }}
          onChange={handleChange}
          typeCreateEdit={typeCreateEdit}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateEditCategoria;
