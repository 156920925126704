import React from "react";
import { Link } from "react-router-dom";
// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchCurso } from "../../../redux/academy/cursos";
import { fetchModulosByCursoID } from "../../../redux/academy/modulo";

import { KTCard, KTCardBody, KTSVG } from "../../../../_metronic/helpers";

// hook
import useGetSearchParams from "../../../hooks/useGetSearchParams";

// components
import AcademyMainHeader from "./AcademyMainHeader";
import AcademyCard from "./AcademyCard";
import LoadingState from "../../../components/LoadingState";
import CreateEditModulo from "./containers/modals/CreateEditModulo";

const AcademyCurso = () => {
  const [currentIdCurso, setCurrentIdCurso] = React.useState(0);
  const [isOpen, setOpen] = React.useState(false);
  const [editModulo, SetEditModulo] = React.useState(false);
  const [selectedModulo, setSelectedModulo] = React.useState({
    idModulo: "",
    idCurso: "",
    titulo: "",
    imageURL: "",
    descripcion: "",
    estado: false,
    onlyForRango: "",
    onlyForPaquete: "",
    onlyForTipo: "",
  });

  const dispatch = useDispatch();
  const moduloState = useSelector((state: any) => state.modulos);
  const cursosState = useSelector((state: any) => state.cursos);

  const datos: Array<any> = React.useMemo(
    () => moduloState.modulos,
    [moduloState.modulos]
  );

  // hooks
  const [searchParams]: any = useGetSearchParams();

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedModulo({
      idModulo: "",
      idCurso: "",
      titulo: "",
      imageURL: "",
      descripcion: "",
      estado: false,
      onlyForRango: "",
      onlyForPaquete: "",
      onlyForTipo: "",
    });
  };

  const handleUpdateModulos = () => {
    if (Object.keys(searchParams).length > 0) {
      dispatch(fetchModulosByCursoID(searchParams.cursoId));
    }
  };

  const handleCreateModulo = () => {
    setOpen(true);
  };

  const handleEditModulo = (moduloData: any) => {
    setOpen(true);
    SetEditModulo(true);
    setSelectedModulo({
      idModulo: moduloData.id,
      idCurso: moduloData.idcurso,
      titulo: moduloData.titulo,
      imageURL: moduloData.url_imagen,
      descripcion: moduloData.descripcion,
      estado: moduloData.estado,
      onlyForRango: moduloData.onlyForRango,
      onlyForPaquete: moduloData.onlyForPaquete,
      onlyForTipo: moduloData.onlyForTipo,
    });
  };

  React.useEffect(() => {
    if (Object.keys(searchParams).length > 0) {
      setCurrentIdCurso(Number(searchParams.cursoId));
      dispatch(fetchCurso(searchParams.cursoId));
      dispatch(fetchModulosByCursoID(searchParams.cursoId));
    }
  }, [searchParams]);

  return (
    <div>
      <CreateEditModulo
        isOpen={isOpen}
        selectedModulo={{
          ...selectedModulo,
          idModulo: Number(selectedModulo.idModulo),
          idCurso: currentIdCurso,
        }}
        handleClose={handleCloseModal}
        typeCall={editModulo === false ? "Crear" : "Editar"}
      />
      <KTCard className="shadow-lg ">
        <div className="border-0 pt-6 px-6 d-flex align-items-center justify-content-between">
          <div>
            <h1>Academia LHC</h1>
          </div>
          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-light-primary me-3"
              onClick={handleUpdateModulos}
            >
              <KTSVG
                path="/media/icons/duotune/general/gen031.svg"
                className="svg-icon-2"
              />
              Actualizar
            </button>
            <button
              type="button"
              className="btn btn-success me-3"
              onClick={handleCreateModulo}
            >
              <KTSVG
                path="/media/icons/duotune/files/fil011.svg"
                className="svg-icon-2"
              />
              Crear Tema
            </button>
          </div>
        </div>
        <div className="separator mx-1 my-4"></div>
        <AcademyMainHeader
          info={{
            id: cursosState.curso?.idcurso,
            titulo: cursosState.curso?.titulo,
            urlImage: cursosState.curso.url_imagen,
            fechaCreacion: cursosState.curso?.fecha_creacion,
            descripcion: cursosState.curso?.descripcion,
          }}
          loading={cursosState.isLoading}
          title="Modulo"
        />

        <KTCardBody className="position-relative">
          {moduloState.isLoading ? (
            <LoadingState />
          ) : (
            <>
              {datos.length > 0 ? (
                <>
                  <div className={`row g-2`}>
                    {datos.map((el: any) => (
                      <div key={el.idmodulo} className="col-6 p-2">
                        <AcademyCard
                          type="Modulo"
                          data={{
                            id: el.idmodulo,
                            idcurso: el.idcurso,
                            titulo: el.titulo,
                            url_imagen: el.url_imagen,
                            descripcion: el.descripcion,
                            fecha_creacion: el.fecha_creacion,
                            estado: el.estado,
                            onlyForRango: el.onlyForRango,
                            onlyForPaquete: el.onlyForPaquete,
                            onlyForTipo: el.onlyForTipo,
                          }}
                          handleEdit={handleEditModulo}
                          handleSee={() => {}}
                          academyURL={`/academy/modulo?moduloId=${el.idmodulo}`}
                        />
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>no hay temas</>
              )}
            </>
          )}
        </KTCardBody>
      </KTCard>
    </div>
  );
};

export default AcademyCurso;
