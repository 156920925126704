import React from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import App from "../App";

import Auth from "../module/auth/Auth";
import PrivateRoutes from "./PrivateRoutes";

// redux
import { useSelector } from "react-redux";

// base url of the website

const { PUBLIC_URL } = process.env;

const AppRoutes = () => {
  // const auth state
  const { isAuthenticated } = useSelector((state: any) => state.auth);
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          {isAuthenticated ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to="/dashboard" />} />
            </>
          ) : (
            <>
              <Route path="auth/*" element={<Auth />} />
              <Route path="/*" element={<Navigate to="/auth" />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
