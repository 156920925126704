import { createSlice } from "@reduxjs/toolkit";
import { payments } from "../../services";

const initialState = {
  billings: [],
  loading: false,
  error: null,
};

const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    getBillingsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    getBillingsSuccess: (state, action) => {
      state.billings = action.payload;
      state.loading = false;
      state.error = null;
    },
    getBillingsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getBillingsStart, getBillingsSuccess, getBillingsFailure } =
  billingSlice.actions;
export default billingSlice.reducer;

export const fetchBillings: any = () => {
  return async (dispatch: any) => {
    dispatch(getBillingsStart());
    try {
      const data = await payments.getBillings();
      dispatch(getBillingsSuccess(data));
    } catch (error: any) {
      dispatch(getBillingsFailure(error.message));
    }
  };
};
