import React from "react";

import { Modal, Button, Form, InputGroup } from "react-bootstrap";

interface IProps {
  open: boolean;
  onClose: () => void;
  // modificar
  tempContenido: {
    tipo: string;
    titulo: string;
    link: string;
  };
  handleAddContenido: () => void;
  handleUploadFileContenido: (e: any) => void;
  handleOnChangeContenido: (e: any) => void;
  files: Array<any>;
  setFiles: (file: any) => void;
  getDisabled: () => boolean;
}

const TemaContenidoModal: React.FC<IProps> = ({
  open,
  onClose,
  // modificar contenidos
  tempContenido,
  handleAddContenido,
  handleUploadFileContenido,
  handleOnChangeContenido,
  files,
  setFiles,
  getDisabled,
}) => {
  // states

  const contenidoType = [
    "Youtube",
    // "Vimeo",
    "Documento",
    "Audio",
  ];

  return (
    <Modal show={open} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>Contenido</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form.Select
            name="tipo"
            id="tipo"
            onChange={handleOnChangeContenido}
            value={tempContenido.tipo}
          >
            <option value="">Selecciona un tipo de contenido</option>
            {contenidoType.map((el: any) => (
              <option key={el} value={el}>
                {el}
              </option>
            ))}
          </Form.Select>
          {tempContenido.tipo !== "" && (
            <>
              <InputGroup>
                <InputGroup.Text>Titulo</InputGroup.Text>
                <Form.Control
                  type="text"
                  name="titulo"
                  id="titulo"
                  value={tempContenido.titulo}
                  onChange={handleOnChangeContenido}
                />
              </InputGroup>
              <InputGroup>
                {tempContenido.tipo === "Youtube" ? (
                  <>
                    <InputGroup.Text>Link</InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="link"
                      id="link"
                      value={tempContenido.link}
                      onChange={handleOnChangeContenido}
                    />
                  </>
                ) : (
                  <>
                    {tempContenido.link !== '""' &&
                    tempContenido.link !== "" ? (
                      <>
                        <InputGroup.Text>Link</InputGroup.Text>
                        <Form.Control
                          type="text"
                          name="link"
                          id="name"
                          value={tempContenido.link}
                          readOnly
                          disabled
                        />
                      </>
                    ) : (
                      <>
                        <Form.Control
                          type="file"
                          onChange={(e: any) => {
                            setFiles(e.target.files);
                          }}
                          accept={
                            tempContenido.tipo === "Audio" ? "audio/*" : ""
                          }
                        />
                        <Button
                          onClick={handleUploadFileContenido}
                          disabled={getDisabled()}
                        >
                          {getDisabled()
                            ? files.length > 0
                              ? "Subiendo archivo..."
                              : "Eliga un archivo"
                            : "Subir archivo"}
                        </Button>
                      </>
                    )}
                  </>
                )}
              </InputGroup>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            handleAddContenido();
            onClose();
          }}
        >
          Agregar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TemaContenidoModal;
