import React from "react";

import { Form } from "react-bootstrap";

// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchPaquetes } from "../redux/core";

interface Iprops {
  handleOnChange: (e: any) => void;
  value: string;
}

const SelectLHCPaquetes: React.FC<Iprops> = ({ handleOnChange, value }) => {
  const dispatch = useDispatch();
  const { paquetes } = useSelector((state: any) => state.core);
  const datos: Array<any> = React.useMemo(() => paquetes, [paquetes]);

  React.useEffect(() => {
    dispatch(fetchPaquetes());
  }, []);

  return (
    <>
      <Form.Select
        id="onlyForPaquete"
        name="onlyForPaquete"
        onChange={handleOnChange}
        value={value}
      >
        <option value="">Seleccione un paquete</option>
        {datos.map((el: any) => (
          <option key={el.idlhcpaquete} value={el.titulo}>
            {el.titulo}
          </option>
        ))}
      </Form.Select>
    </>
  );
};

export default SelectLHCPaquetes;
