import React from "react";
// utils
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Container,
  InputGroup,
} from "react-bootstrap";

interface Iprops {
  isOpen: boolean;
  onClose: () => void;
  onResetFilter: () => void;
  onRefreshTicket: () => void;
  filterData:
    | {
        idUser: string | undefined;
        idTicket: string | undefined;
        estado: string | undefined;
        referenciaTicket: string | undefined;
        importeTicket: string | undefined;
      }
    | Record<string, any>;
  filterDateRange: { start: string; end: string } | Record<string, string>;
  onChangeFilterDateRange: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  onChangeFilter: (e: any) => void;
}

const FilterTicketModal: React.FC<Iprops> = ({
  isOpen,
  onClose,
  onResetFilter,
  onChangeFilter,
  onRefreshTicket,
  filterData,
  filterDateRange,
  onChangeFilterDateRange,
}) => {
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Filtros</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form.Group>
            <Container>
              <Row>
                <Col xs={12}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>ID Usuario</InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Codigo de reserva"
                      name="idUser"
                      value={filterData.idUser}
                      onChange={(e) => onChangeFilter(e)}
                    />
                  </InputGroup>
                </Col>
                <Col xs={12}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>ID Ticket</InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="id ticket"
                      name="id ticket"
                      value={filterData.idTicket}
                      onChange={(e) => onChangeFilter(e)}
                    />
                  </InputGroup>
                </Col>
                <Col xs={12}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Referencia Ticket</InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Referencia ticket"
                      name="referenciaTicket"
                      value={filterData.referenciaTicket}
                      onChange={(e) => onChangeFilter(e)}
                    />
                  </InputGroup>
                </Col>
                <Col md={6} xs={12}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Importe Ticket</InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="importeTicket"
                      value={filterData.importeTicket}
                      onChange={(e) => onChangeFilter(e)}
                    />
                  </InputGroup>
                </Col>
                <Col md={6} xs={12}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Estado</InputGroup.Text>
                    <Form.Select
                      name="estado"
                      value={filterData.estado}
                      onChange={(e) => onChangeFilter(e)}
                    >
                      <option value="pendiente">Pendiente</option>
                      <option value="validada">validada</option>
                      <option value="rechazado">Rechazado</option>
                    </Form.Select>
                  </InputGroup>
                </Col>
                <Col md={6} xs={12}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Inicio</InputGroup.Text>
                    <Form.Control
                      type="date"
                      placeholder="Date Range"
                      value={filterDateRange.start}
                      onChange={(e) =>
                        onChangeFilterDateRange({
                          ...filterDateRange,
                          start: e.target.value,
                        })
                      }
                    />
                  </InputGroup>
                </Col>
                <Col md={6} xs={12}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Fin</InputGroup.Text>
                    <Form.Control
                      type="date"
                      placeholder="Date Range"
                      value={filterDateRange.end}
                      onChange={(e) =>
                        onChangeFilterDateRange({
                          ...filterDateRange,
                          end: e.target.value,
                        })
                      }
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Container>
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            onRefreshTicket();
            onClose();
          }}
        >
          Actualizar
        </Button>
        <Button
          onClick={(e) => {
            onResetFilter();
            onClose();
          }}
        >
          Reset
        </Button>
        <Button onClick={onClose}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterTicketModal;
