import React from "react";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import s3 from "../../../../utils/s3Helper";
import * as fns from "date-fns";

interface Iticket {
  fecha: string;
  imagenTicket: string;
  fechaCreacion: string;
  codigoMarca: number;
  nombreMarca: string;
  codigoPais: number;
  nombrePais: string;
  idUsuario: string;
  importeTicket: number;
  importeRecompensa: number;
  referenciaTicket: string;
  estado: string;
  poblacionEstablecimiento: string;
  direccionEstablecimiento: string;
  codigoPostal: string;
  nombreLegalEmpresa: string;
  numeroLegalEmpresa: string;
  porcentajeRecompensa: number;
  estadoBase?: string;
  fechaTicketBase?: string;
  comentarios?: string;
}

interface Iprops {
  data: Iticket;
  typeCall: string;
  onChange: (event: any) => void;
  onChangeSelect: (event: any, porcentaje?: number) => void;
  onChangeDateTicket: (value: string) => void;
}

const Tickets: React.FC<Iprops> = ({
  data,
  typeCall,
  onChange,
  onChangeSelect,
  onChangeDateTicket,
}) => {
  // states
  const [fileURL, setFileURL] = React.useState<string>("");

  const haveImage = (image: string) => {
    return image !== '""' && image !== "" ? true : false;
  };

  const handleReadOnly = (formName: string) => {
    if (typeCall === "Editar" && formName !== "estado") {
      return true;
    }
    if (typeCall === "Ver") {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    if (typeCall === "Ver" || typeCall === "Editar") {
      if (haveImage(data.imagenTicket)) {
        const getFileURL = async () => {
          const url = await s3.getFileURL(data.imagenTicket);
          setFileURL(url);
        };
        getFileURL();
      }
    }
  }, [data.imagenTicket, typeCall]);

  return (
    <>
      <h3>{typeCall} Ticket.</h3>
      <div className="separator border-3 mb-10" />
      <Container>
        <Row>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>ID del usuario</Form.Label>
              <Form.Control
                type="text"
                value={data?.idUsuario}
                name="idUsuario"
                onChange={onChange}
                disabled={handleReadOnly("idUsuario")}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            {haveImage(data.imagenTicket) ? (
              <>
                {/* TIENE IMAGEN MOSTRAR IMAGEN */}
                {/* <img src={fileURL} width={"100%"} alt="vista previa" /> */}
                <Button className="w-100" href={fileURL} target="_black">
                  Ver imagen
                </Button>
              </>
            ) : null}
          </Col>

          <div className="mt-5" />
          <h5>Marca id# {data.codigoMarca}</h5>
          <div className="separator border-3 mb-5" />

          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Nombre de la marca</Form.Label>
              <Form.Control type="text" value={data?.nombreMarca} disabled />
            </Form.Group>
          </Col>

          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Porcentaje de recompensa %</Form.Label>
              <Form.Control
                type="text"
                value={data?.porcentajeRecompensa}
                disabled
              />
            </Form.Group>
          </Col>

          <div className="mt-5" />
          <h5>Pais</h5>
          <div className="separator border-3 mb-5" />

          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Codigo del pais</Form.Label>
              <Form.Control
                type="text"
                value={data?.codigoPais}
                name="codigoPais"
                onChange={onChange}
                disabled={handleReadOnly("codigoPais")}
              />
            </Form.Group>
          </Col>

          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>nombre del pais</Form.Label>
              <Form.Control type="text" value={data?.nombrePais} disabled />
            </Form.Group>
          </Col>

          <Col xs={12}>
            <Form.Label>Fecha del ticket</Form.Label>
            <Form.Control
              type={typeCall === "Ver" ? "text" : "date"}
              name="fecha"
              value={
                typeCall === "Ver"
                  ? data?.fecha
                  : data?.fecha &&
                    fns.format(new Date(data?.fecha), "yyyy-MM-dd")
              }
              onChange={(e) => onChangeDateTicket(e.target.value)}
              disabled={typeCall === "Ver"}
            />
          </Col>

          <Col xs={12}>
            <Form.Label>Fecha de creacion</Form.Label>
            <Form.Control
              type="text"
              name="fechaCreacion"
              value={
                data?.fechaCreacion &&
                fns.format(new Date(data?.fechaCreacion), "dd-MM-yyyy")
              }
              disabled
            />
          </Col>

          <Col xs={12}>
            <Form.Label>Referencia Ticket</Form.Label>
            <Form.Control
              type="text"
              name="referenciaTicket"
              value={data?.referenciaTicket}
              onChange={onChange}
              disabled={typeCall === "Ver"}
            />
          </Col>

          <Col xs={12}>
            <Form.Label>Importe del ticket</Form.Label>
            <Form.Control
              type="text"
              name="importeTicket"
              value={data?.importeTicket}
              onChange={onChange}
              disabled={typeCall === "Ver"}
            />
          </Col>

          <Col xs={12}>
            <Form.Label>Importe de la recompensa</Form.Label>
            <Form.Control
              type="text"
              name="importeRecompensa"
              value={data?.importeRecompensa}
              disabled
            />
          </Col>

          <Col xs={12}>
            <Form.Label>Poblacion establecimiento</Form.Label>
            <Form.Control
              type="text"
              name="poblacionEstablecimiento"
              value={data?.poblacionEstablecimiento}
              disabled={typeCall === "Ver"}
              onChange={onChange}
            />
          </Col>

          <Col xs={12}>
            <Form.Label>Direccion establecimiento</Form.Label>
            <Form.Control
              type="text"
              name="direccionEstablecimiento"
              value={data?.direccionEstablecimiento}
              disabled={typeCall === "Ver"}
              onChange={onChange}
            />
          </Col>

          <Col xs={12}>
            <Form.Label>Codigo postal</Form.Label>
            <Form.Control
              type="text"
              name="codigoPostal"
              value={data?.codigoPostal}
              disabled={typeCall === "Ver"}
              onChange={onChange}
            />
          </Col>

          <Col xs={12}>
            <Form.Label>Nombre legal de la empresa</Form.Label>
            <Form.Control
              type="text"
              name="nombreLegalEmpresa"
              value={data?.nombreLegalEmpresa}
              disabled={typeCall === "Ver"}
              onChange={onChange}
            />
          </Col>

          <Col xs={12}>
            <Form.Label>numero legal de la empresa</Form.Label>
            <Form.Control
              type="text"
              name="numeroLegalEmpresa"
              value={data?.numeroLegalEmpresa}
              disabled={typeCall === "Ver"}
              onChange={onChange}
            />
          </Col>

          <Col xs={12}>
            <Form.Label>Estado</Form.Label>
            {typeCall === "Ver" ? (
              <Form.Control
                type="text"
                name="estado"
                value={data?.estado}
                disabled
              />
            ) : (
              <Form.Select
                value={data?.estado}
                name="estado"
                onChange={(e) => {
                  onChangeSelect(e, data?.porcentajeRecompensa);
                }}
              >
                <option value="pendiente">Pendiente</option>
                <option value="validada">validada</option>
                <option value="rechazado">Rechazado</option>
              </Form.Select>
            )}
          </Col>

          {data.estado === "rechazado" && (
            <Col xs={12}>
              <Form.Label>Razon de rechazado</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                required={data.estado === "rechazado"}
                name="comentarios"
                value={data?.comentarios}
                disabled={typeCall === "Ver"}
                onChange={onChange}
              />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Tickets;
