import { createSlice } from "@reduxjs/toolkit";
import { academy } from "../../services";

const initState = {
  temas: [],
  tema: {},
  isLoading: false,
  error: false,
  errorMessage: "",
};

const temaSlice = createSlice({
  name: "tema",
  initialState: initState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setTemas: (state, action) => {
      state.temas = action.payload;
    },
    setTema: (state, action) => {
      state.tema = action.payload;
    },
  },
});

export const { setLoading, setError, setErrorMessage, setTemas, setTema } =
  temaSlice.actions;
export default temaSlice.reducer;

// redux thunk
export const fetchTemas: any = () => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    dispatch(setErrorMessage(""));
    try {
      const response = await academy.temas.getTemas();
      dispatch(setTemas(response.temas));
    } catch (error: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchTema: any = (idTema: number) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    dispatch(setErrorMessage(""));
    try {
      const response = await academy.temas.getTema(idTema);
      dispatch(setTema(response));
    } catch (error: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const fetchTemasByModuloID: any = (moduloID: number) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    dispatch(setErrorMessage(""));
    try {
      const response = await academy.temas.getTemasByModuloID(moduloID);
      dispatch(setTemas(response.temas));
    } catch (error: any) {
      dispatch(setError(true));
      dispatch(setErrorMessage(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
};
