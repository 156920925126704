import config from "../../config";
import axios from "axios";

const backendURL = config.API.LHC_BACKEND_URL;

const endPoints = {
  // pagos
  setPaymentUrl: "/payment/setPaymentUrl",
  getBillings: "/payment/getBillings",
  // registro
  createPaymentRecord: "/payment/setPayment",
  updatePaymentRecord: "/payment/updatePayment",
  getPaymentRecordByCod: "/payment/getPaymentsByCod",
  getPaymentRecords: "/payment/getPayments",
  // conceptos
  getConcepts: "/payment/getConcepts",
  getConceptByID: "/payment/getConcept",
  createConcept: "/payment/setConcept",
  updateConcept: "/payment/updateConcept",
  deleteConcept: "/payment/deleteConcept",
};

// pagos
const getCoinPaymentUrl = async (body: any) => {
  const toSend = {
    item_name: body.item_name,
    amount: body.amount,
    ipn: body.ipn,
  };
  try {
    const { data } = await axios.post(
      `${backendURL}${endPoints.setPaymentUrl}`,
      toSend
    );
    return data;
  } catch (error) {
    throw new Error("Error al obtener la url");
  }
};

const getBillings = async () => {
  try {
    const { data } = await axios.get(`${backendURL}${endPoints.getBillings}`);
    return data;
  } catch (error) {
    throw new Error("Error al obtener las facturas");
  }
};

// registro
const createPaymentRecord = async (body: any) => {
  try {
    const { data } = await axios.post(
      `${backendURL}${endPoints.createPaymentRecord}`,
      body
    );
    return data;
  } catch (error) {
    throw new Error("Error al crear la reserva");
  }
};

const updatePaymentRecord = async (body: any) => {
  try {
    const { data } = await axios.put(
      `${backendURL}${endPoints.updatePaymentRecord}`,
      body
    );
    return data;
  } catch (error) {
    throw new Error("Error al editar la reserva");
  }
};

const getPaymentRecordByCod = async (codReserva: any) => {
  const params = {
    codReserva,
  };
  try {
    const { data } = await axios.get(
      `${backendURL}${endPoints.getPaymentRecordByCod}`,
      {
        params,
      }
    );
    return data;
  } catch (error) {
    throw new Error("Error al obtener los pagos");
  }
};

const getPaymentRecords = async () => {
  try {
    const { data } = await axios.get(
      `${backendURL}${endPoints.getPaymentRecords}`
    );
    return data;
  } catch (error) {
    throw new Error("Error al obtener los pagos");
  }
};

// conceptos
const getConcepts = async () => {
  try {
    const { data } = await axios.get(`${backendURL}${endPoints.getConcepts}`);
    return data;
  } catch (error) {
    throw new Error("Error al obtener los conceptos");
  }
};
const getConceptByID = async (id: number) => {
  const params = {
    idConcepto: id,
  };

  try {
    const { data } = await axios.get(
      `${backendURL}${endPoints.getConceptByID}`,
      {
        params,
      }
    );
    return data;
  } catch (error) {
    throw new Error("Error al obtener los conceptos");
  }
};
const createConcept = async (body: any) => {
  try {
    const { data } = await axios.post(
      `${backendURL}${endPoints.createConcept}`,
      body
    );
    return data;
  } catch (error) {
    throw new Error("Error al crear el concepto");
  }
};
const updateConcept = async (body: any) => {
  const params = {
    idConcepto: body.idConcepto,
  };
  try {
    const { data } = await axios.put(
      `${backendURL}${endPoints.updateConcept}`,
      body,
      {
        params,
      }
    );
    return data;
  } catch (error) {
    throw new Error("Error al actualizar el concepto");
  }
};
const deleteConcept = async (id: number) => {
  const params = {
    idConcepto: id,
  };
  try {
    const { data } = await axios.delete(
      `${backendURL}${endPoints.deleteConcept}`,
      {
        params,
      }
    );
    return data;
  } catch (error) {
    throw new Error("Error al eliminar el concepto");
  }
};

const payments = {
  getCoinPaymentUrl,
  getBillings,
  createPaymentRecord,
  updatePaymentRecord,
  getPaymentRecordByCod,
  getPaymentRecords,
  getConcepts,
  getConceptByID,
  createConcept,
  updateConcept,
  deleteConcept,
};

export default payments;
