import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    user: {
      googleId: "",
      imageUrl: "",
      email: "",
      name: "",
      givenName: "",
      familyName: "",
    },
    token: {
      accessToken: "",
      tokenId: "",
    },
  },
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      const { profileObj, token } = action.payload;
      state.user = profileObj;
      state.token = token;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = {
        googleId: "",
        imageUrl: "",
        email: "",
        name: "",
        givenName: "",
        familyName: "",
      };
      state.token = {
        accessToken: "",
        tokenId: "",
      };
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
