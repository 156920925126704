import { createSlice } from "@reduxjs/toolkit";
import { core } from "../services";

const initState = {
  paises: [],
  isLoading: false,
  error: false,
  errorMessage: "",
};

const paisesSlice = createSlice({
  name: "paises",
  initialState: initState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setPaises: (state, action) => {
      state.paises = action.payload;
    },
  },
});

export const { setLoading, setError, setErrorMessage, setPaises } =
  paisesSlice.actions;
export default paisesSlice.reducer;

export const fetchPaises: any = () => async (dispatch: any) => {
  dispatch(setLoading(true));
  dispatch(setError(false));
  dispatch(setErrorMessage(""));
  try {
    const response = await core.paises.getPaises();
    dispatch(setPaises(response));
  } catch (error: any) {
    dispatch(setError(true));
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};
