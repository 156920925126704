import { createSlice } from "@reduxjs/toolkit";
import { core } from "../services";

const initState = {
  categorias: [],
  selectedCategoria: {},
  isLoading: false,
  error: false,
  errorMessage: "",
};

const categoriaSlice = createSlice({
  name: "categorias",
  initialState: initState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setCategorias: (state, action) => {
      state.categorias = action.payload;
    },
    setSelectedCategoria: (state, action) => {
      state.selectedCategoria = action.payload;
    },
  },
});

export const {
  setLoading,
  setError,
  setErrorMessage,
  setCategorias,
  setSelectedCategoria,
} = categoriaSlice.actions;
export default categoriaSlice.reducer;

export const fetchCategorias: any = () => async (dispatch: any) => {
  dispatch(setError(false));
  dispatch(setErrorMessage(""));
  try {
    const response = await core.categorias.getCategorias();
    dispatch(setCategorias(response));
  } catch (error: any) {
    dispatch(setError(true));
    dispatch(setErrorMessage(error.message));
  }
};
