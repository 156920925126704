import React, { useEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

// metronic
import { toAbsoluteUrl } from "../../../_metronic/helpers";

// components
import Login from "./components/Login";

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  return (
    <div
      className="d-flex flex-column flex-column-fluid position-x-center vh-100"
      style={{
        backgroundColor: "#0b3738",
      }}
    >
      {/* begin::Content */}
      <div className="d-flex flex-center flex-column flex-column-fluid p-20">
        {/* begin::Logo */}
        <a href="#" className="mb-12">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/logo-white.png")}
            className="h-120px"
          />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className="w-lg-500px bg-white bg-opacity-10 rounded shadow-sm p-10 p-lg-15 mx-auto">
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  );
};

const Auth = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route index element={<Login />} />
      </Route>
    </Routes>
  );
};

export default Auth;
