import React from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import { KTCard, KTCardBody } from "../../../_metronic/helpers";
import { fetchTickets } from "../../redux/core";

// utils
import { Button } from "react-bootstrap";

// components
import LoadingState from "../../components/LoadingState";
import CreateEditTickets from "../../components/containers/modals/CreateEditTickets";
import TicketsTable from "./components/tables/TicketsTable";
import ModalTicketFilter from "./components/FilterModal";

type T_Ticket = {
  idticket?: number;
  idUsuario: string;
  imagenTicket: string;
  codigoMarca: number;
  nombreMarca: string;
  codigoPais: number;
  nombrePais: string;
  fechaCreacion: string;
  fecha: string;
  importeTicket: number;
  importeRecompensa: number;
  estado: string;
  referenciaTicket: string;
  poblacionEstablecimiento: string;
  direccionEstablecimiento: string;
  codigoPostal: string;
  nombreLegalEmpresa: string;
  numeroLegalEmpresa: string;
  porcentajeRecompensa: number;
  comentarios?: string;
};

const Tickets = () => {
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [typeCall, setTypeCall] = React.useState<string>("Crear");
  const [selectedTicket, setSelectedTicket] = React.useState<T_Ticket>({
    idticket: 0,
    idUsuario: "",
    imagenTicket: "",
    codigoMarca: 0,
    nombreMarca: "",
    codigoPais: 0,
    nombrePais: "",
    fechaCreacion: "",
    fecha: "",
    importeTicket: 0,
    importeRecompensa: 0,
    estado: "",
    referenciaTicket: "",
    poblacionEstablecimiento: "",
    direccionEstablecimiento: "",
    codigoPostal: "",
    nombreLegalEmpresa: "",
    numeroLegalEmpresa: "",
    porcentajeRecompensa: 0,
    comentarios: "",
  });

  // filters
  const [showFilterModal, setShowFilterModal] = React.useState<boolean>(false);
  const [filters, setFilters] = React.useState<Record<string, any>>({
    idUser: undefined,
    idTicket: undefined,
    estado: undefined,
    referenciaTicket: undefined,
    importeTicket: undefined,
  });
  const [dateRange, setDateRange] = React.useState<Record<string, string>>({
    start: "",
    end: "",
  });

  // redux
  const dispatch = useDispatch();
  const { tickets, isLoading } = useSelector((state: any) => state.core);

  const handleRefreshTicket = (page: number = 1, pageSize: number = 10) => {
    dispatch(
      fetchTickets(page, pageSize, {
        idUser: filters?.idUser,
        idticket: filters?.idTicket,
        estado: filters?.estado,
        referenciaTicket: filters?.referenciaTicket,
        importeTicket: filters?.importeTicket,
        fechaInicio: dateRange.start,
        fechaFinal: dateRange.end,
      })
    );
  };

  const handleOnChangeFilterTickets = (e: any) => {
    const { name, value } = e.target;
    let filteredValue = value;
    if (
      name === "idUser" ||
      name === "idTicket" ||
      name === "referenciaTicket"
    ) {
      filteredValue = value.replace(/\s+/g, "");
    }
    setFilters({ ...filters, [name]: filteredValue });
  };

  const handleOpenFilters = () => {
    setShowFilterModal(true);
  };

  const handleResetFilters = () => {
    setFilters({
      idUser: undefined,
      idTicket: undefined,
      estado: undefined,
      referenciaTicket: undefined,
      importeTicket: undefined,
    });
    setDateRange({ start: "", end: "" });
  };

  const handleUpdateTicket = (ticketData: T_Ticket) => {
    setShowModal(true);
    setTypeCall("Editar");
    setSelectedTicket(ticketData);
  };

  const handleVerTicket = (ticketData: T_Ticket) => {
    setShowModal(true);
    setTypeCall("Ver");
    setSelectedTicket(ticketData);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseFilterModal = () => {
    setShowFilterModal(false);
  };

  React.useEffect(() => {
    dispatch(fetchTickets(currentPage));
  }, []);

  return (
    <div>
      <CreateEditTickets
        currentPage={currentPage}
        isOpen={showModal}
        handleClose={handleCloseModal}
        typeCall={typeCall}
        selectedTicket={selectedTicket}
        handleRefresh={handleRefreshTicket}
      />
      <ModalTicketFilter
        isOpen={showFilterModal}
        onClose={handleCloseFilterModal}
        onRefreshTicket={handleRefreshTicket}
        filterData={filters}
        filterDateRange={dateRange}
        onChangeFilterDateRange={setDateRange}
        onChangeFilter={handleOnChangeFilterTickets}
        onResetFilter={handleResetFilters}
      />

      <KTCard>
        <div className="card-header">
          <div className="card-title">Tickets</div>
          <div className="card-toolbar">
            <Button onClick={handleOpenFilters}>Filtros</Button>
          </div>
        </div>
        <KTCardBody className="py-4 position-relative">
          {isLoading ? (
            <LoadingState />
          ) : (
            <TicketsTable
              tableData={tickets}
              handleView={handleVerTicket}
              handleEdit={handleUpdateTicket}
              handleRefresh={handleRefreshTicket}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </KTCardBody>
      </KTCard>
    </div>
  );
};

export default Tickets;
