// CRUD Paises
import React from "react";

// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchPaises } from "../../redux/paises";

import { KTCard, KTCardBody } from "../../../_metronic/helpers";

// components
import LoadingState from "../../components/LoadingState";

const Paises = () => {
  // redux
  const dispatch = useDispatch();
  const { paises, isLoading } = useSelector((state: any) => state.paises);

  const paisesData: Array<any> = React.useMemo(() => paises, [paises]);

  React.useEffect(() => {
    dispatch(fetchPaises());
  }, [dispatch]);

  return (
    <div>
      <KTCard>
        <div className="card-header">
          <div className="card-title">Paises</div>
        </div>
        <KTCardBody className="py-4 position-relative">
          {isLoading ? (
            <LoadingState />
          ) : (
            <div className="table-responsive">
              <table className="table table-hover table-row-dashed fs-6 gy-5 table-sm dataTable align-middle">
                <thead>
                  <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                    <th>ID pais</th>
                    <th>Pais</th>
                    <th>Codigo</th>
                  </tr>
                </thead>

                <tbody className="text-gray-600 fw-bold">
                  {paisesData.length > 0 &&
                    paisesData.map((pais: any) => (
                      <tr key={pais.idpaises}>
                        <td>{pais.idpaises}</td>
                        <td>{pais.nombrePais}</td>
                        <td>{pais.codigoPais}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </KTCardBody>
      </KTCard>
    </div>
  );
};

export default Paises;
