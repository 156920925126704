import React from "react";

// redux
import { useDispatch, useSelector } from "react-redux";
import { getFetchListRegistroDePagos } from "../../redux/payments/registroDePagos";

// utils

// components
import RegistroDePagosTable from "./components/tables/RegistroDePagosTable";

const PaymentRecords = () => {
  // redux
  const dispatch = useDispatch();
  const { registroDePagos, loading } = useSelector(
    (state: any) => state.registroDePagos
  );

  const tableData = React.useMemo(() => registroDePagos, [registroDePagos]);

  React.useEffect(() => {
    dispatch(getFetchListRegistroDePagos());
  }, []);

  return (
    <>
      <RegistroDePagosTable tableData={tableData} isLoading={loading} />
    </>
  );
};

export default PaymentRecords;
