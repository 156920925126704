import React from "react";
import { Form, Container, Row, Col } from "react-bootstrap";

interface IProps {
  data: {
    nombre: string;
    descripcion: string;
    slug: string;
  };
  typeCreateEdit?: string;
  onChange?: (e: any) => void;
}

const ConceptoDePago: React.FC<IProps> = ({
  data,
  typeCreateEdit,
  onChange,
}) => {
  return (
    <>
      <h3>{typeCreateEdit} Concepto de pago.</h3>
      <div className="separator border-3 mb-10"></div>
      <Container>
        <Row>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre"
                name="nombre"
                value={data?.nombre}
                onChange={onChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Slug</Form.Label>
              <Form.Control
                type="text"
                placeholder="Slug"
                name="slug"
                disabled
                value={data?.slug}
              />
            </Form.Group>
          </Col>

          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Descripcion</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                type="text"
                placeholder="Descripcion"
                name="descripcion"
                value={data?.descripcion}
                onChange={onChange}
              />
            </Form.Group>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ConceptoDePago;
