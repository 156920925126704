import React from "react";

// react bootstrap
import { Button } from "react-bootstrap";

// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchReservas } from "../../../../redux/reservas/reservas";

// react table
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import { CruceroColumns, ReservasColumns } from "./columns/_columns";
import DefaultColumnFilter from "./columns/DefaultColumnFilter";
import CustomColumnHeader from "./columns/CustomColumnHeader";
import TablePagination from "../../../../components/table/TablePagination";

// utils
import { createData } from "./helpers/records";

interface Iprops {
  handleSelect: (crucero: any) => void;
  handleClose: () => void;
  tipoReserva: string;
}

const SearchCruceros: React.FC<Iprops> = ({
  handleSelect,
  handleClose,
  tipoReserva,
}) => {
  const dispatch = useDispatch();
  const { isLoading, reservas } = useSelector((state: any) => state.reservas);
  const { fetchMethod } = useSelector((state: any) => state.options);

  const dataTable = React.useMemo(() => createData(reservas), [reservas]);

  // table hooks
  const columns = React.useMemo(
    () => (tipoReserva !== "Crucero" ? ReservasColumns : CruceroColumns),
    [tipoReserva]
  );
  const defaultColumn: any = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const tablehooks = (hooks: any) => {
    hooks.visibleColumns.push((columns: any) => [
      ...columns,
      {
        id: "actions",
        Header: "Acciones",
        Cell: ({ row }: any) => (
          <Button
            onClick={() => {
              handleSelect(row.original);
              handleClose();
            }}
          >
            Seleciónar <i className="fa fa-check"></i>
          </Button>
        ),
      },
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // handlers
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: dataTable,
      defaultColumn,
      initialState: { pageIndex: 0 },
    },

    tablehooks,
    useFilters,
    useSortBy,
    usePagination
  );

  React.useEffect(() => {
    dispatch(
      fetchReservas(
        fetchMethod,
        {
          start: "",
          end: "",
        },
        tipoReserva,
        "",
        ""
      )
    );
  }, []);

  return (
    <div>
      <div className="table-responsive">
        <table
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column: any) => (
                  <CustomColumnHeader
                    {...column.getHeaderProps()}
                    column={column}
                  />
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
            {page.length > 0 && !isLoading ? (
              page.map((row: any, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className="d-flex text-center w-100 align-content-center justify-content-center">
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>

          {pageOptions.length > 0 && (
            <TablePagination
              pageSize={pageSize}
              setPageSize={setPageSize}
              gotoPage={gotoPage}
              previousPage={previousPage}
              canPreviousPage={canPreviousPage}
              pageOptions={pageOptions}
              pageIndex={pageIndex}
              nextPage={nextPage}
              canNextPage={canNextPage}
              pageCount={pageCount}
            />
          )}
        </table>
      </div>
    </div>
  );
};

export default SearchCruceros;
