import { createSlice } from "@reduxjs/toolkit";

import { payments } from "../../services";

const initState = {
  conceptosDePago: [],
  selectedConcept: {},
  loading: false,
  error: null,
};

const conceptoDePagoSlice = createSlice({
  name: "conceptoDePago",
  initialState: initState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setFetchSuccess: (state, action) => {
      state.conceptosDePago = action.payload;
      state.loading = false;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setSelectedConcept: (state, action) => {
      state.selectedConcept = action.payload;
    },
  },
});

export const { setLoading, setFetchSuccess, setError, setSelectedConcept } =
  conceptoDePagoSlice.actions;
export default conceptoDePagoSlice.reducer;

export const fetchGetConceptos: any = () => async (dispatch: any) => {
  dispatch(setLoading(true));
  try {
    const response = await payments.getConcepts();
    dispatch(setFetchSuccess(response));
  } catch (error: any) {
    dispatch(setError(error.message));
  }
};

export const fetchGetConceptoByID: any =
  (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
      const response = await payments.getConceptByID(id);
      dispatch(setFetchSuccess(response));
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const postConcepto: any = (body: any) => async (dispatch: any) => {
  dispatch(setLoading(true));
  try {
    const response = await payments.createConcept(body);
    console.log(response);
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setError(error));
  }
};

export const putConceptoDePago: any = (body: any) => async (dispatch: any) => {
  dispatch(setLoading(true));
  try {
    const response = await payments.updateConcept(body);
    console.log(response);
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setError(error));
  }
};

export const deleteByID: any = (id: number) => async (dispatch: any) => {
  dispatch(setLoading(true));
  try {
    const response = await payments.deleteConcept(id);
    console.log(response);
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setError(error));
  }
};
