import React from "react";

import { Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap";

// components
import SelectRango from "../../../../../components/SelectLHCRangos";
import SelectPaquete from "../../../../../components/SelectLHCPaquetes";
import SelectTipo from "../../../../../components/SelectLHCTipos";
import UploadFile from "../../../../../components/UploadFile";

// helpers
import s3 from "../../../../../../utils/s3Helper";
import TemaContenidoModal from "./components/TemaContenidoModal";

interface ITema {
  titulo: string;
  imageURL: string;
  descripcion: string;
  estado: boolean;
  onlyForRango: string;
  onlyForPaquete: string;
  onlyForTipo: string;
}

interface Iprops {
  data: ITema;
  typeCall: string;
  onChange: (e: any) => void;
  // upload photo
  onChangePhoto: (e: any) => void;
  previewFile: string;
  // contenido
  contenidoArray: Array<any>;
  tempContenido: {
    tipo: string;
    titulo: string;
    link: string;
  };
  setContenidoArray: (data: any) => void;
  handleResetContenidoTemp: () => void;
  handleAddContenido: () => void;
  handleOnChangeContenido: (e: any) => void;
  handleDeleteContenido: (id: number) => void;
  handleUploadFileContenido: (e: any) => void;
  files: any;
  setFiles: (file: any) => void;
  uploadLoading: boolean;
}

const Tema: React.FC<Iprops> = ({
  data,
  typeCall,
  onChange,
  // upload photo
  previewFile,
  onChangePhoto,
  // contenido
  contenidoArray,
  tempContenido,
  setContenidoArray,
  handleResetContenidoTemp,
  handleOnChangeContenido,
  handleAddContenido,
  handleDeleteContenido,
  handleUploadFileContenido,
  setFiles,
  files,
  uploadLoading,
}) => {
  const [fileURL, setFileURL] = React.useState<string>("");
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const contenidoData = React.useMemo(() => contenidoArray, [contenidoArray]);

  const openContenidoModal = () => {
    setOpenModal(true);
  };

  const closeContenidoModal = () => {
    setOpenModal(false);
    handleResetContenidoTemp();
  };

  const getDisabled = () => {
    if (files.length > 0 && uploadLoading !== true) {
      return false;
    } else {
      return true;
    }
  };

  const haveImage = (image: string) =>
    image !== '""' && image !== "" ? true : false;

  React.useEffect(() => {
    if (typeCall !== "Crear" && haveImage(data.imageURL)) {
      const getFileURL = async () => {
        const url = await s3.getFileURL(data.imageURL);
        setFileURL(url);
      };
      getFileURL();
    }
  }, [typeCall, data.imageURL]);

  return (
    <>
      <TemaContenidoModal
        open={openModal}
        onClose={closeContenidoModal}
        tempContenido={tempContenido}
        handleAddContenido={handleAddContenido}
        handleUploadFileContenido={handleUploadFileContenido}
        handleOnChangeContenido={handleOnChangeContenido}
        files={files}
        setFiles={setFiles}
        getDisabled={getDisabled}
      />
      <Container>
        <Row>
          <Col xs={12}>
            <InputGroup className="mb-3">
              <InputGroup.Text>Esta activo?</InputGroup.Text>
              <Form.Check
                className="my-auto mx-2"
                type="switch"
                id="estado"
                name="estado"
                label={`${data.estado ? "Si" : "No"}`}
                checked={data.estado}
                onChange={onChange}
              />
            </InputGroup>
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Titulo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Titulo del modulo"
                name="titulo"
                value={data.titulo}
                required
                onChange={onChange}
              />
            </Form.Group>
          </Col>

          {/* UPLOAD FILE */}
          <Col xs={12}>
            {haveImage(data.imageURL) ? (
              <>
                {/* TIENE IMAGEN MOSTRAR IMAGEN */}
                <img src={fileURL} width={"100%"} alt="vista previa" />
              </>
            ) : (
              <>
                <UploadFile
                  onChangeFile={onChangePhoto}
                  previewFile={previewFile}
                />
              </>
            )}
          </Col>

          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Descripcion</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Descripcion del modulo"
                name="descripcion"
                value={data.descripcion}
                required
                onChange={onChange}
              />
            </Form.Group>
          </Col>
          <h3>Restricciones</h3>
          <div className="separator mx-1 my-4"></div>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Restricciones por rango</Form.Label>
              <SelectRango
                handleOnChange={onChange}
                value={data.onlyForRango}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Restricciones por paquete</Form.Label>
              <SelectPaquete
                handleOnChange={onChange}
                value={data.onlyForPaquete}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Restricciones por tipo</Form.Label>
              <SelectTipo handleOnChange={onChange} value={data.onlyForTipo} />
            </Form.Group>
          </Col>
          <h3>Contenido del tema.</h3>
          <div className="separator mx-1 my-4"></div>
          <Col xs={12}>
            <InputGroup className="mb-3">
              <Button onClick={openContenidoModal}>Agregar un contenido</Button>
            </InputGroup>
          </Col>

          {contenidoData.length > 0 &&
            contenidoData.map((el: any) => (
              <Col xs={12} key={el.id}>
                <div className="p-3 border">
                  <InputGroup>
                    <InputGroup.Text>tipo de contenido</InputGroup.Text>
                    <Form.Control value={el.tipo} readOnly disabled />
                  </InputGroup>
                  <InputGroup className="mt-3">
                    <InputGroup.Text>Titulo</InputGroup.Text>
                    <Form.Control value={el.titulo} readOnly disabled />
                  </InputGroup>
                  <InputGroup className="mt-3">
                    <InputGroup.Text>Link</InputGroup.Text>
                    <Form.Control value={el.link} readOnly disabled />
                  </InputGroup>
                  <Button
                    className="mt-3"
                    onClick={() => {
                      handleDeleteContenido(el.id);
                    }}
                  >
                    Borrar
                  </Button>
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </>
  );
};

export default Tema;
