import React from "react";

import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";

// components
import s3 from "../../../../../../utils/s3Helper";

interface ITema {
  titulo: string;
  imageURL: string;
  descripcion: string;
  estado: boolean;
  contenido: any;
  onlyForRango: string;
  onlyForPaquete: string;
  onlyForTipo: string;
}

interface Iprops {
  data: ITema;
}

const TemaRead: React.FC<Iprops> = ({ data }) => {
  const [fileURL, setFileURL] = React.useState<string>("");

  const haveImage = (image: string) =>
    image !== '""' && image !== "" ? true : false;

  React.useEffect(() => {
    // have image?
    if (haveImage(data.imageURL)) {
      const getFileURL = async () => {
        const url = await s3.getFileURL(data.imageURL);
        setFileURL(url);
      };
      getFileURL();
    }
  }, [data.imageURL]);

  return (
    <>
      <Container>
        <Row>
          <Col xs={12}>
            <InputGroup className="mb-3">
              <InputGroup.Text>Esta activo?</InputGroup.Text>
              <Form.Check
                className="my-auto mx-2"
                type="switch"
                id="estado"
                name="estado"
                label={`${data.estado ? "Si" : "No"}`}
                checked={data.estado}
                disabled
              />
            </InputGroup>
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Titulo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Titulo del modulo"
                name="titulo"
                value={data.titulo}
                required
                disabled
              />
            </Form.Group>
          </Col>

          {/* IMAGE */}
          {haveImage(data.imageURL) ? (
            <>
              {/* TIENE IMAGEN MOSTRAR IMAGEN */}
              <img src={fileURL} width={"100%"} alt="vista previa" />
            </>
          ) : null}

          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Descripcion</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Descripcion del modulo"
                name="descripcion"
                value={data.descripcion}
                required
                disabled
              />
            </Form.Group>
          </Col>
          <h3>Restricciones</h3>
          <div className="separator mx-1 my-4"></div>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Restricciones por rango</Form.Label>
              <Form.Control value={data.onlyForRango} readOnly disabled />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Restricciones por paquete</Form.Label>
              <Form.Control value={data.onlyForPaquete} readOnly disabled />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Restricciones por tipo</Form.Label>
              <Form.Control value={data.onlyForTipo} readOnly disabled />
            </Form.Group>
          </Col>
          <h3>Contenido del tema.</h3>
          <div className="separator mx-1 my-4"></div>
          {Object.keys(data.contenido).length > 0 ? (
            <>
              {data.contenido?.data.map((el: any) => (
                <Col xs={12} key={el.id}>
                  <div className="p-3 border">
                    <InputGroup>
                      <InputGroup.Text>tipo de contenido</InputGroup.Text>
                      <Form.Control value={el.tipo} readOnly disabled />
                    </InputGroup>
                    <InputGroup className="mt-3">
                      <InputGroup.Text>Titulo</InputGroup.Text>
                      <Form.Control value={el.titulo} readOnly disabled />
                    </InputGroup>
                    <InputGroup className="mt-3">
                      <InputGroup.Text>Link</InputGroup.Text>
                      <Form.Control value={el.link} readOnly disabled />
                    </InputGroup>
                  </div>
                </Col>
              ))}
            </>
          ) : null}
        </Row>
      </Container>
    </>
  );
};

export default TemaRead;
