import { createRoot } from "react-dom/client";

import { Chart, registerables } from "chart.js";

// Apps
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/style.react.scss";
import { MetronicI18nProvider } from "./_metronic/i18n/Metronici18n";

import AppRoutes from "./app/routes/AppRoutes";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import store from "./app/redux/store";

Chart.register(...registerables);

let persistor = persistStore(store);

// react 18
const container: any = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MetronicI18nProvider>
        <AppRoutes />
      </MetronicI18nProvider>
    </PersistGate>
  </Provider>
);
