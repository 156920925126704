import React, { useState } from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { core } from "../../../services";
import ModalLoadingState from "../../../components/ModalLoadingState";
import { startLoading, endLoading } from "../../../redux/app";
import { useDispatch } from "react-redux";
import swal from "sweetalert";

interface Iwallet {
  label: string;
  value: number;
}

const walletType: Array<Iwallet> = [
  {
    label: "Comisiones",
    value: 1,
  },
  {
    label: "Puntos de Fidelizacion",
    value: 2,
  },
  {
    label: "Creditos de Balance",
    value: 3,
  },
  {
    label: "Puntos de fidelidad retenido",
    value: 4,
  },
  {
    label: "Puntos de Compra",
    value: 5,
  },
];

const AddPoints = () => {
  const [correo, setCorreo] = useState("");
  const [cantidad, setCantidad] = useState("");
  const [tipo, setTipo] = useState("");
  const [concepto, setConcepto] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!correo || !cantidad || !tipo || !concepto) {
      swal("Alerta!", "Todos los campos son obligatorios", "warning");
      return;
    }
    dispatch(startLoading());
    try {
      const { userID } = await core.getCliente({
        type: "correo",
        value: correo,
      });
      const result = await core.addPuntos({
        idCliente: userID,
        cantidad: +cantidad,
        tipo: +tipo,
        concepto: concepto,
      });
      console.log(result);

      if (+result.error === 1) {
        throw new Error(result.response.message);
      }

      swal("Listo!", "Puntos agregados correctamente", "success");
    } catch (error: any) {
      swal("Error!", error.message, "error");
    } finally {
      dispatch(endLoading());
    }
  };
  return (
    <div>
      <ModalLoadingState />
      <Container>
        <Row>
          <Col xs={6}>
            <h3>Agregar Puntos</h3>
            <div className="separator border-3 mb-10"></div>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="correo">
                <Form.Label>Correo electrónico</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Ingresa tu correo"
                  value={correo}
                  onChange={(e) => setCorreo(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="cantidad">
                <Form.Label>Cantidad</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Ingresa la cantidad"
                  value={cantidad}
                  onChange={(e) => setCantidad(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="tipo">
                <Form.Label>Tipo</Form.Label>
                <Form.Control
                  as="select"
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                >
                  <option value="">Selecciona un tipo</option>
                  {walletType.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="concepto">
                <Form.Label>Concepto</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Ingresa el concepto"
                  value={concepto}
                  onChange={(e) => setConcepto(e.target.value)}
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Enviar
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddPoints;
