import axios from "axios";
import { IBooking } from "./../interfaces/interfaces";
import config from "../../config";

const backendURL = config.API.LHC_BACKEND_URL;

const endPoints = {
  getBookingAPI: "/api/booking",
  getBookingDB: "/booking",
  editBooking: "/api/booking/edit?updatedb=true",
};

const getBookingAPI = async (
  start?: string,
  end?: string,
  tipoReserva?: string,
  codReserva?: string,
  idUser?: string
): Promise<IBooking[] | any> => {
  const params = {
    fechaInicioReserva: start,
    fechaFinReserva: end,
    tipoReserva: tipoReserva,
    codReserva: codReserva,
    idUser: idUser,
  };

  try {
    const { data } = await axios.get(
      `${backendURL}${endPoints.getBookingAPI}`,
      {
        params,
      }
    );
    return data;
  } catch (error: any) {
    throw error;
  }
};

const getBookingDB = async (
  start?: string,
  end?: string,
  tipoReserva?: string,
  codReserva?: string,
  idUser?: string
): Promise<IBooking[] | any> => {
  const params = {
    fechaInicioReserva: start,
    fechaFinReserva: end,
    tipoReserva: tipoReserva,
    codReserva: codReserva,
    idUser: idUser,
  };

  try {
    const { data } = await axios.get(`${backendURL}${endPoints.getBookingDB}`, {
      params,
    });
    return data;
  } catch (error: any) {
    throw error;
  }
};

const editBooking = async (payload: any) => {
  try {
    const { data } = await axios.post(
      `${backendURL}${endPoints.editBooking}`,
      payload
    );
    return data;
  } catch (error: any) {
    throw error;
  }
};

const booking = {
  getBookingAPI,
  getBookingDB,
  editBooking,
};

export default booking;
