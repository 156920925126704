import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

// app
import app from "./app";

// core
import auth from "./auth";
import options from "./options";
import core from "./core";
import categorias from "./categorias";
import paises from "./paises";

// reservas
import travelExperiences from "./reservas/travelExperiences";
import reservas from "./reservas/reservas";
import filters from "./filters";

// payments
import registroDePagos from "./payments/registroDePagos";
import billing from "./payments/billing";
import conceptoDePago from "./payments/conceptoDePago";

// academy
import cursos from "./academy/cursos";
import modulos from "./academy/modulo";
import temas from "./academy/temas";

// push notification
import pushNotification from "./pushNotification";

const reducers = combineReducers({
  app,
  core,
  categorias,
  paises,
  reservas,
  travelExperiences,
  auth,
  filters,
  registroDePagos,
  options,
  billing,
  conceptoDePago,
  cursos,
  modulos,
  temas,
  pushNotification,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
