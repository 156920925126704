import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl, KTSVG } from "../../../_metronic/helpers";

const Dashboard = () => {
  return (
    <div>
      <div
        className="d-flex flex-column flex-center bgi-no-repeat bgi-size-cover bgi-position-center rounded-top px-9 pt-20 pb-10"
        style={{
          backgroundImage: `url('${toAbsoluteUrl(
            "/media/misc/pattern-1.jpg"
          )}')`,
        }}
      >
        <h1
          className="text-white fw-bold mb-3"
          style={{
            fontSize: "3.5rem",
          }}
        >
          Dashboard
        </h1>
      </div>

      <div className="row g-0">
        <div className="col-6">
          <Link
            to="/app/reservas/main"
            className="d-flex flex-column flex-center h-100 p-6 btn bg-hover-light-dark border-end border-bottom w-100"
          >
            <KTSVG
              path="/media/icons/duotune/communication/com005.svg"
              className="svg-icon-3x svg-icon-primary mb-2"
            />
            <span className="fs-5 fw-bold text-gray-800 mb-0">Booking</span>
            <span className="fs-7 text-gray-600">Reservas</span>
          </Link>
        </div>
        <div className="col-6">
          <Link
            to="/billing"
            className="d-flex flex-column flex-center h-100 p-6 btn bg-hover-light-dark border-end border-bottom w-100"
          >
            <KTSVG
              path="/media/icons/duotune/finance/fin010.svg"
              className="svg-icon-3x svg-icon-primary mb-2"
            />
            <span className="fs-5 fw-bold text-gray-800 mb-0">
              Transacciones
            </span>
            <span className="fs-7 text-gray-600">Pagos</span>
          </Link>
        </div>
        <div className="col-6">
          <Link
            to="/option"
            className="d-flex flex-column flex-center h-100 p-6 btn bg-hover-light-dark border-end border-bottom w-100"
          >
            <KTSVG
              path="/media/icons/duotune/coding/cod001.svg"
              className="svg-icon-3x svg-icon-primary mb-2"
            />
            <span className="fs-5 fw-bold text-gray-800 mb-0">Datos</span>
            <span className="fs-7 text-gray-600">Configuracion</span>
          </Link>
        </div>
        <div className="col-6">
          <Link
            to="/academy"
            className="d-flex flex-column flex-center h-100 p-6 btn bg-hover-light-dark border-end border-bottom w-100"
          >
            <KTSVG
              path="/media/icons/duotune/ecommerce/ecm004.svg"
              className="svg-icon-3x svg-icon-primary mb-2"
            />
            <span className="fs-5 fw-bold text-gray-800 mb-0">
              Academia LHC
            </span>
            <span className="fs-7 text-gray-600">Cursos</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
