import React from "react";

import { Form } from "react-bootstrap";

// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchRangos } from "../redux/core";

interface Iprops {
  handleOnChange: (e: any) => void;
  value: string;
}

const SelectLHCRangos: React.FC<Iprops> = ({ handleOnChange, value }) => {
  const dispatch = useDispatch();
  const { rangos } = useSelector((state: any) => state.core);
  const datos: Array<any> = React.useMemo(() => rangos, [rangos]);


  React.useEffect(() => {
    dispatch(fetchRangos());
  }, []);

  return (
    <>
      <Form.Select
        id="onlyForRango"
        name="onlyForRango"
        onChange={handleOnChange}
        value={value}
      >
        <option value="">Seleccione un rango</option>
        {datos.map((el: any) => (
          <option key={el.idlhcrango} value={el.titulo}>
            {el.titulo}
          </option>
        ))}
      </Form.Select>
    </>
  );
};

export default SelectLHCRangos;
