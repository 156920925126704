import React from "react";

import { Button } from "react-bootstrap";

interface Iprops {
  data: Array<any>;
  handleSelectRow: (row: any) => void;
}

const BillingTable: React.FC<Iprops> = ({ data, handleSelectRow }) => {
  // react-table

  return (
    <div className="table-responsive">
      <table className="table table-hover table-sm align-middle">
        <thead>
          <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
            <th>#</th>
            <th>Codigo de Transaccion</th>
            <th>Cantidad de transacciones</th>
            <th>Acciones</th>
          </tr>
        </thead>

        <tbody className="text-gray-600 fw-bold">
          {data.map((el: any, index: number) => (
            <tr key={el.itemName}>
              <td>{index + 1}</td>
              <td>{el.itemName}</td>
              <td>{el.total}</td>
              <td>
                <Button
                  variant="primary"
                  onClick={() => {
                    handleSelectRow(el.record);
                  }}
                >
                  Ver
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BillingTable;
